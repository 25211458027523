.jackpotMessage{
    z-index:1;
    position:absolute;
    background:white;
    top:30%;
    width:100%;
    display:flex;
    justify-content:center;
}
.jackpotMessage .message{
    
}

.jackpotMessageBottom{
    z-index:1;
    background: #0000007a;
    bottom: 0;
    height: 35px;
    line-height: 35px;
    position: absolute;
    right: 0;
    width: 100%;
    color: white;
    text-align: right;
}