/* AgencyFB Bold Font */
@font-face {
    font-family: 'agencyfb';
    font-style: normal;
    font-weight: 700;
    src: local('AgencyFB'), url('./Assets/fonts/AgencyFB-Bold.woff') format('woff');
}

/* Lobster latin Font */
@font-face {
  font-family: 'Lobster';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./Assets/fonts/Lobster.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: 'Paperlogy-8ExtraBold';
    src: url('./Assets/fonts/Paperlogy-8ExtraBold.woff2') format('woff2');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Cafe24ClassicType-Regular';
    src: url('./Assets/fonts/Cafe24ClassicType-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Avigea';
    src: url('./Assets/fonts/Avigea.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Avigea-Italic';
    src: url('./Assets/fonts/AvigeaItalic.woff2') format('woff2');
    font-weight: normal;
    font-style: italic;
}

@keyframes anim-pop {
  0% {
    color: black;
    transform: scale(0);
    opacity: 0;
  }
  25% {
    color: #fff;
    transform: scale(1.5);
    opacity: 1;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    /* animate nothing to add pause at the end of animation */
    transform: scale(1);
    opacity: 1;
  }
}
/* sparkling stars */
/*******************/
@keyframes comeInOut {
    0% { transform: scale(0); }
    50% { transform: scale(3); }
    100% { transform: scale(0); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

:root {
    --talk-yellow: #ffd050;
    --r-player-bar-w: 9em;
    --r-player-bar-h: 3em;
}

@keyframes countdown-wrapper-animation {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes countdown-animation {
    0% {
        width: var(--r-player-bar-w);
        height: var(--r-player-bar-h);
        opacity: 1;
    }

    20% {
        width: 0px;
        height: var(--r-player-bar-h);
        opacity: 1;
    }

    40%, 50% {
        width: 0px;
        height: 0px;
        opacity: 1;
    }
    56% {
        opacity: 0;
        visibility: hidden;
        border-top: 0;
    }

    98%, 100% {
        width: 0px;
        height: 0px;
        opacity: 0;
        border-top: 0;
    }
}

@keyframes countdown-animation-inner {

    0%,39% {
        width: var(--r-player-bar-w);
        height: var(--r-player-bar-h);
        opacity: 1;
    }

    40% {
        width: var(--r-player-bar-w);
        height: var(--r-player-bar-h);
        opacity: 1;
    }

    60% {
        width: 0px;
        height: var(--r-player-bar-h);
        opacity: 1;
    }
    99% {
        width: 0px;
        height: 0px;
        opacity: 1;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}

.p-game-wrapper {
    position: absolute;
    width: 100%;
    height: auto;
    left: 0;
    top: 4em;
    bottom: 4em;
}

.p-scene-game-table {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

.p-scene-game-table-cell {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.p-game-area {
    position: relative;
    margin: 0 auto;
    opacity: 1;
}

.background-table-felt {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: 50% 51%;
    /* background-size: 51.75em 27em; */
    background-size: 100% 100%;
}

/*.game-page.background-default {*/
/*    background-color: #19191D;*/
/*}*/

/* Default is RED background */
.game-page.background-default {
    /* background: #3C2027 radial-gradient(75.89% 100% at 50% 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%) */
    background: url('../Images/Table/Desktop/bg-pc-default.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.game-page.mobile.portrait.background-default {
    /* background: #3C2027 radial-gradient(75.89% 100% at 50% 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%) */
    background: url('../Images/Table/Mobile/bg-m-default.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.game-page.background-gold {
    /* background: #3C2E1A radial-gradient(75.89% 100% at 50% 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%) */
    background: url('../Images/Table/Desktop/bg-pc-brown.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}
.game-page.mobile.portrait.background-gold {
    /* background: #3C2E1A radial-gradient(75.89% 100% at 50% 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%) */
    background: url('../Images/Table/Mobile/bg-m-brown.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

.game-page.background-blue {
    /* background: #27304A radial-gradient(75.89% 100% at 50% 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%) */
    background: url('../Images/Table/Desktop/bg-pc-blue.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}
.game-page.mobile.portrait.background-blue {
    /* background: #27304A radial-gradient(75.89% 100% at 50% 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%) */
    background: url('../Images/Table/Mobile/bg-m-blue.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}
.game-page.background-gray {
    /* background: #27304A radial-gradient(75.89% 100% at 50% 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%) */
    background: url('../Images/Table/Desktop/bg-pc-grey.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}
.game-page.mobile.portrait.background-gray {
    /* background: #27304A radial-gradient(75.89% 100% at 50% 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%) */
    background: url('../Images/Table/Mobile/bg-m-grey.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

.game-page.background-green {
    background: #1E3629 radial-gradient(75.89% 100% at 50% 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%)
}

.game-page.background-purpal {
    /* background: #36253E radial-gradient(75.89% 100% at 50% 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%) */
    background: url('../Images/Table/Desktop/bg-pc-violet.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}
.game-page.mobile.portrait.background-purpal {
    /* background: #36253E radial-gradient(75.89% 100% at 50% 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%) */
    background: url('../Images/Table/Mobile/bg-m-violet.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

.background-table-felt.felt-default {
    /* background-image: url('../Images/Table/Desktop/table-screen-green.png'); */
    background-image: url('../Images/Table/Desktop/table-screen-default.png');
}

.background-table-felt.felt-gold {
    /* background-image: url('../Images/Table/Desktop/table-screen-gold.png'); */
    background-image: url('../Images/Table/Desktop/table-screen-darkred.png');
}

.background-table-felt.felt-blue {
    background-image: url('../Images/Table/Desktop/table-screen-blue.png');
}

.background-table-felt.felt-red {
    /* background-image: url('../Images/Table/Desktop/table-screen-red.png'); */
    background-image: url('../Images/Table/Desktop/table-screen-red.png');
}

.background-table-felt.felt-gray {
    background-image: url('../Images/Table/Desktop/table-screen-gray.png');
}

.background-table-felt.felt-purpal {
    /* background-image: url('../Images/Table/Desktop/table-screen-purpal.png'); */
    background-image: url('../Images/Table/Desktop/table-screen-violet.png');
}

.background-table-border {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    /* background-image: url('../Images/Table/Desktop/table-border.png'); */
    /* url('https://poker.evenbetpoker.com/html5-evenbetpoker/d/desktop/assets/big-table-border-b01e3c2f4b04eb854559.png'); */
    /* background-repeat: no-repeat;
    background-position: 50% 51%;
    background-size: 51.75em 27em; */
}

.poker-root .poker-alpha.desktop .p-game-area .background-table .background-table-logo, .poker-root .poker-alpha.tablet .p-game-area .background-table .background-table-logo {
    background-position: 50% 35%;
    /*background-position: 50% 43.8%;*/
    background-size: 41%;
}

.poker-root .poker-alpha.mobile .p-game-area .background-table .background-table-logo, .poker-root .poker-alpha.tablet .p-game-area .background-table .background-table-logo {
    background-position: 50% 55%;
    background-size: 53%;
}

.poker-root .p-game-area .background-table .background-table-logo, 
.poker-root .tablet .p-game-area .background-table .background-table-logo {
    background-image: url('../Images/Table/Desktop/pokerhill-logo.png');
}
.poker-root .mobile.portrait .p-game-area .background-table .background-table-logo, 
.poker-root .mobile.portrait .tablet .p-game-area .background-table .background-table-logo {
    background-image: url('../Images/Table/Mobile/pokerhill-m-logo.png');
}

.poker-root .p-game-area .background-table .background-table-logo {
    left: 0;
    top: -7%;
    width: 100%;
    height: 100%;
    position: absolute;
    background-repeat: no-repeat;
    opacity: 1;
}
.log-container{
    align-items: center;
    display: flex;
    font-size: 20px;
    height: 1.4375em;
    justify-content: center;
    left: 31%;
    position: absolute;
    right: 67%;
    top: 35.5%;
    width: 6em;
}
.gameid-container{
    align-items: center;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 13.5%;
    left: 47%;
    right: 67%;
    width: 6em;
    height: 1.4375em; 
    font-size:20px;
}

.poker-root .poker-alpha.desktop .poker-game .r-scene-container .bank-container,
.poker-root .poker-alpha.tablet .poker-game .r-scene-container .bank-container {
    top: 27.5%;
    left: 47%;
    right: 67%;
    width: 6em;
    height: 1.4375em;
}

.poker-root .bank-container::before {
    content: "";
    align-items: center;
    display: flex;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 50% !important;
    transform: translateX(-50%) translateY(-50%) !important;
    height: 115%;
    width: 200%;
    z-index: -1;
    background: url('../Images/potbg.png');
    background-size: 100% 100%;
}

.poker-root .bank-container {
    align-items: center;
    display: flex;
    justify-content: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    /*border-width: 0 3px 3px 3px;*/
    /*border-style: solid;*/
    /*border-color: #ddcc71;*/
    /*border-bottom-left-radius: 8px;*/
    /*border-bottom-right-radius: 8px;*/
    width: 15%;
    padding: 10px;
    height: 3rem;
    /*background: linear-gradient(180deg, #6a603e, #0e0d07);*/
    
}



.poker-root .poker-alpha .bank-container .bank-container-content {
    min-width: 4.25em;
    min-height: 100%;
    height: auto;
    font-size: 1.2rem;
    text-shadow: 1px 1px 2px #453b3b;
}

.poker-root .bank-container .bank-container-content {
    width: auto;
    border-radius: 1em;
    /*background: rgba(0, 0, 0, 0.3);*/
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    /*padding-left: 0.5em;*/
    /*padding-right: 0.5em;*/
}

.poker-root .p-game-area .background-table .table-blind {
    position: absolute;
    top: 20%;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    transform: translateX(-50%);
}

.poker-root .p-game-area .background-table .table-blind .blindtitle {
    background: #000000f2;
    border-radius: 14px;
    padding: 3px 15px;
    text-align: center;
    border: 1px solid #b7b7b7;
    z-index: 2;
    font-size: 12px;
    font-style: italic;
}
.poker-root .p-game-area .background-table .table-blind .blindval {
    text-align: center;
    background: #4949496b;
    padding: 3px 16px;
    transform: translateY(-6px);
    border-radius: 14px;
    box-shadow: inset 1px 2px 5px #393939;
    width: 20%;
    font-style: italic;
    text-shadow: 1px 1px 2px #000;
    padding-top: 7px;
}

.poker-root .p-game-area .background-table .table-name {
    color: #efefef8c;
    font-size: 1.2em;
    height: 1em;
    position: absolute;
    text-align: center;
    top: 53%;
    width: 100%;
    text-shadow: 1px 1px 6px #5151518f;
}
.poker-root .p-game-area .background-table .table-name .tbl-type {
    text-transform: uppercase;
}

.poker-root .skin-section {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top center;
}

.poker-root .table-shadow {
    background-position: 50% 51%;
    background-size: 56em 32em;
}

.poker-root .table-shadow {
    /*background-image: url('../Images/Table/Desktop/table-shadow.png');*/
}

.table-footer-container {
    height: 7em;
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    pointer-events: none;
    z-index: 6;
}

.poker-root .desktop .permanent-auto-actions-container {
    bottom: 5em;
    width: 10.5em;
    height: auto;
    left: 0.5em;
    position: absolute;
}

.poker-root .desktop .permanent-auto-actions-container .permanent-auto-actions-container-content, .poker-root .tablet .permanent-auto-actions-container .permanent-auto-actions-container-content {
    width: 100%;
    left: 0;
    right: 0;
    border-radius: 0.25em;
    position: absolute;
}

.poker-root .desktop .permanent-auto-actions-container .permanent-auto-actions-container-content .expand-button {
    width: 2.5em;
    height: 2.5em;
    position: relative;
    background: transparent;
    justify-content: left;
    padding: 0 0.55em;
}



.poker-root .table-actions-container {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    z-index: 4;
}

.poker-root .desktop .table-footer-container .table-footer-container-content {
    position: relative;
    height: 100%;
    pointer-events: all;
    width: 100%;
}

.poker-root .table-actions-container .table-actions-container-content {
    width: 100%;
    height: 100%;
    position: absolute;
}


.poker-root .table-actions-container .table-actions-container-content .actions {
    pointer-events: all;
}

.poker-root .poker-alpha.desktop .table-actions-container .table-actions-container-content:not(.with-raise) .actions, 
.poker-root .poker-alpha.tablet .table-actions-container .table-actions-container-content:not(.with-raise) .actions {
    /* top: 2.375em; */
    bottom: 0.5em;
}

.poker-root .desktop .table-actions-container .table-actions-container-content:not(.with-raise) .player-actions .actions > .actionsWrapper, 
.poker-root .tablet .table-actions-container .table-actions-container-content:not(.with-raise) .player-actions .actions > .actionsWrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.poker-root .desktop .table-actions-container .table-actions-container-content .player-actions .actions, 
.poker-root .tablet .table-actions-container .table-actions-container-content .player-actions .actions {
    position: absolute;
    height: auto;
    width: 530px;
    box-sizing: border-box;
    padding-bottom: 0.3em;
    display: flex;
    justify-content: flex-end;
    margin-right: 0;
}
.poker-root .desktop .table-actions-container .table-actions-container-content .player-actions .actions:has(.shw-wrap) {
   width: 420px;
   padding-bottom: 20px;
}
.poker-root .mobile .table-actions-container .table-actions-container-content .player-actions .actions:has(.shw-wrap) {
   justify-content: flex-start;
}
.poker-root .table-actions-container .table-actions-container-content .player-actions .actions .s-wrapper {
    flex-wrap: wrap;
}
.poker-root .desktop .table-actions-container .table-actions-container-content .player-actions .actions .s-wrapper {
    justify-content: flex-end;
}
.poker-root .mobile .table-actions-container .table-actions-container-content .player-actions .actions .s-wrapper {
    justify-content: flex-start;
}
.poker-root .desktop .table-actions-container .table-actions-container-content .player-actions .actions .s-wrapper .shw-wrap-title {
    width: 100%;
    text-align: right;
    margin-right: 95px;
}
.poker-root .mobile .table-actions-container .table-actions-container-content .player-actions .actions .s-wrapper .shw-wrap-title {
    width: 100%;
    text-align: left;
    padding-bottom: 2px;
    font-size: 14px;
}
.poker-root .table-actions-container .table-actions-container-content .player-actions .actions .s-wrapper .shw-wrap {
    width: 78px;
    text-align: center;
    margin: 0 5px;
    padding: 3px;
    background: linear-gradient(180deg, black, #232323);
    border: 1px solid gray;
    border-radius: 10px;
    cursor: pointer;
}
.poker-root .desktop .table-actions-container .table-actions-container-content .player-actions .action .s-wrapper .shw-wrap:hover {
    box-shadow: 2px 2px 8px #ffd050, -2px -2px 8px #ffd050;
    border: 1px solid #ffd050;
}

.poker-root .table-actions-container .table-actions-container-content .player-actions {
    left: 0;
    width: 100%;
    top: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 1;
    align-items: center;
}
.poker-root .desktop .table-actions-container .table-actions-container-content .player-actions {
    justify-content: center;
}

.poker-root .desktop .am-back-button-wrapper {
    background: #1e1e1e;
    right: 0.5em;
    width: 6.75em;
    height: 3.75em;
    position: absolute;
    top: auto;
    pointer-events: all;
    bottom: 0.5em;
    z-index: 5;
}

.poker-root .desktop .am-back-button-wrapper .chat-open-button.SimpleButton .SimpleButton__icon, .poker-root .tablet .am-back-button-wrapper .chat-open-button.SimpleButton .SimpleButton__icon {
    width: 100%;
    height: 100%;
    background: transparent;
}

.poker-root .poker-alpha.desktop .Icon .Icon__content button {
    background: transparent;
    border: none;
    color: #fff;
}

.poker-root .desktop .am-back-button-wrapper:hover, .poker-root .tablet .am-back-button-wrapper:hover {
    background: #404040;
}

.poker-root .am-back-button-wrapper .chat-open-button {
    width: 6.75em;
    height: 3.75em;
    /*background: transparent !important;*/
    background: linear-gradient(180deg, #000,  #212121) !important;
    box-shadow: none !important;
    position: relative;
    --border-width: 2px;
    border-radius: 8px;
}
.poker-root .am-back-button-wrapper .chat-open-button::after {
    position: absolute;
    content: "";
    top: calc(-1* var(--border-width));
    left: calc(-1* var(--border-width));
    z-index: -1;
    width: calc(100% + var(--border-width)* 2);
    height: calc(100% + var(--border-width)* 2);
    background: linear-gradient(60deg, hsl(33.23deg 100% 54.31%), hsl(47.08deg 100% 60.99%), hsl(30.46deg 76.43% 42.04%), hsl(33.23deg 100% 55.39%), hsl(44, 85%, 66%), hsl(38.77deg 67.61% 51.84%), hsl(30.46deg 85% 66%), hsl(52.62deg 80.43% 84.66%));
    background-size: 300% 300%;
    background-position: 0 50%;
    border-radius: 8px;
    animation: moveGradient 4s alternate infinite;
    box-shadow: 2px 2px 10px #ffd70061, -2px -2px 10px #ffd70085;
}

@keyframes moveGradient {
    50% {
        background-position: 100% 50%;
    }
}

.poker-root .desktop .chat-open-button-wrapper {
    background: #1e1e1e;
    left: 0.5em;
    width: 3.75em;
    height: 3.75em;
    position: absolute;
    top: auto;
    pointer-events: all;
    bottom: 0.5em;
    z-index: 5;
}

.poker-root .desktop .chat-open-button-wrapper .chat-open-button {
    width: 3.75em;
    height: 3.75em;
    background: transparent !important;
    box-shadow: none !important;
}

.poker-root .desktop .chat-open-button-wrapper .chat-open-button.SimpleButton .SimpleButton__icon,
.poker-root .tablet .chat-open-button-wrapper .chat-open-button.SimpleButton .SimpleButton__icon {
    width: 2em;
    height: 2em;
}

.poker-root .poker-alpha .Icon.Icon_val_open-table-chat .Icon__content {
    background-image: url(../Images/chat.svg);
}

.r-seat {
    position: absolute;
}

.seat-template-32 {
    left: 45.25%;
    top: 66%;
}

.r-player-avatar {
    width: 100%;
    height: 100%;
    /*border-radius: 50%;*/
    position: absolute;
    background: no-repeat center / contain;
    background-size: 100% 100%;
    background-position: 50% 50%;
    box-sizing: border-box;
    padding: 0.25em;
}

.r-player-avatar {
    width: 4.872em;
    height: 4.872em;
}

.r-player-avatar .player-gift-img {
    position: absolute;
    width: 2.5em;
    height: 2.5em;
    top: -1em;
    right: -1em;
}

.gifting-msg {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    animation: fadeinout 1s ease backwards;
    /* background-color: #893BF7; */
}

.playergame-sender-gift {
    animation: blinker 1s linear infinite;
}

.player-send-gift-button {
    border-radius: 50%;
    border-radius: 50%;
    background: linear-gradient(180deg, #29292B 0%, #1E1F21 100%);
    padding: 0.6em;
    position: absolute;
    width: 2.5em;
    height: 2.5em;
    top: -1em;
    right: -1em;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    pointer-events: all;
}

.player-send-gift-button img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
}

@keyframes blinker {
    50% {
        opacity: 0.1;
    }
}


@keyframes fadeinout {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


.gifting-msg img {
    width: 1.5em;
}

.gifting-msg h4 {
    font-size: 0.8em;
    background-image: linear-gradient(to right, rgba(255, 0, 0, 0), #893BF7, #893BF7, rgba(255, 0, 0, 0));
    padding: 1em;
}

.desktop .r-seat.default .r-player-avatar {
    left: 0;
    /* top: 0.65em; */
    /*top: 15%;*/
    bottom: 10px;
    z-index: 2;
    padding: 0;
}
.desktop .r-seat.right-direction:not(.seat-template-5) .r-player-avatar {
    left: 0%;
}

.r-seat.default {
    width: 7em;
}

.poker-root .r-seat.default {
    height: 8em;
}

.poker-root .r-player {
    position: relative;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.poker-root .r-seat.default .r-player-active-area {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
}

.poker-root .poker-alpha .r-seat.default .r-player-bar {
    /*min-height: 3.3em;*/
    z-index: 11;
}

.poker-root .r-seat.left-direction .r-player-bar .r-player-bar-content,
.poker-root .r-seat.right-direction .r-player-bar .r-player-bar-content{
    justify-content: center;
    background: linear-gradient(180deg, #000, #2f2d2d);
    box-shadow: inset 3px 2px 6px black, inset -4px -4px 7px black;
    padding: 5px 0;
    border-radius: 6px;
    position: relative;
}
.poker-root .r-seat.left-direction .r-player-bar .r-player-bar-content::before,
.poker-root .r-seat.right-direction .r-player-bar .r-player-bar-content::before{
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: linear-gradient(#434141, #000000) border-box;
    mask: linear-gradient(#000 0 0) padding-box, linear-gradient(#000 0 0);
    mask-composite: exclude;
    border: 2px solid transparent;
    left: 0;
    top: 0;
    border-radius: 6px;
}

/*.r-seat.winner .panel.r-player .r-player-bar {*/
/*    box-shadow: 5px 0px 8px #ffff2c;*/
/*}*/

.poker-root .r-seat .r-player-bar .r-player-bar-content {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    /*position: absolute;*/
    display: flex;
}
.poker-root .r-seat.right-direction .r-player-bar .r-player-bar-content {
    justify-content: flex-start;
}

.poker-root .r-seat.left-direction .r-player-bar .r-player-bar-content {
    justify-content: flex-end;
}

.poker-root .r-seat.seat-template-5.right-direction .r-player-bar .r-player-bar-content {
    justify-content: center;
}

.poker-root .r-seat .r-player-bar .r-player-bar-content .bar-text.top-line {
    /*border: 3px solid gray;*/
    border: none;
    /*background-color: #000;*/
    background-color: transparent;
    /*border-radius: 3rem;*/
    border-radius: 5px;
    /*padding-top: 0.2rem;*/
    /*padding-bottom: 0.2rem;*/
    padding: 0;
}


.poker-root .r-seat .r-player-bar .r-player-bar-content .bar-text.top-line .pname-txt {
    position: relative;
    z-index: 1;
}
.poker-root .r-seat .r-player-bar .r-player-bar-content .bar-text.top-line .timer {
    position: absolute;
    top: 0;
    left: 0;
}

.poker-root .r-seat  .r-player-bar .r-player-bar-content .bar-text.bottom-line {
     /*border: 3px solid gray;*/
    border: none;
    /*background-color: #000;*/
    background-color: transparent;
    /*border-radius: 3rem;*/
    border-radius: 5px;
    /*padding-top: 0.2rem;*/
    /*padding-bottom: 0.2rem;*/
    padding: 0;
    /*max-width: 80%;*/
    /*border-top: 0;*/
    /*padding: 0.2rem 0 0.2rem 1.8rem;*/
}

.poker-root .r-seat.right-direction.r-seat:not(.seat-template-5) .r-player-bar .r-player-bar-content .bar-text.bottom-line {
    text-align: center;
    /*padding-left: 1.2vw;*/
}
.poker-root .r-seat.right-direction.r-seat:not(.seat-template-5) .r-player-bar .r-player-bar-content .bar-text.top-line {
    text-align: center;
    /*padding-left: 2.1vw;*/
}
.poker-root .r-seat.left-direction .r-player-bar .r-player-bar-content .bar-text.bottom-line {
    text-align: center;
    /*padding-right: 1.2vw;*/
}
.poker-root .r-seat.left-direction .r-player-bar .r-player-bar-content .bar-text.top-line {
    text-align: center;
    /*padding-right: 2vw;*/
}

/* .round-time-bar {
    margin: 1rem;
    overflow: hidden;
  } */
.poker-root .r-seat .r-player-bar .countdown {
    width: 100%;
    height: 100%;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    background-color: #000000c7;
    position: absolute;
    font-size: 2.5em;
    color: #CD8800;
    animation: zoom-in-zoom-out 1s ease-out infinite;
}

@keyframes zoom-in-zoom-out {
    0% {
        font-size: 2.5em;
        /* transform: scale(1, 1); */
    }

    50% {
        font-size: 3em;
        /* transform: scale(1.2, 1.2); */
    }

    100% {
        font-size: 2.5em;
        /* transform: scale(1, 1); */
    }
}

.poker-root .r-seat .r-player-bar .timer {
    width: 100%;
    height: 100%;
    border-radius: 0.5em;
    transform-origin: left center;
    background: linear-gradient(to bottom, #ff748b, #ff5370, #ff3254);
    animation: roundtime calc(var(--duration) * 1s) linear forwards
}


/*.poker-root .mobile .r-seat.seat-template-2 .r-player-bar .timer,*/
/*.poker-root .mobile .r-seat.seat-template-3 .r-player-bar .timer,*/
/*.poker-root .mobile .r-seat.seat-template-4 .r-player-bar .timer,*/
/*.poker-root .r-seat.seat-template-2 .r-player-bar .timer,*/
/*.poker-root .r-seat.seat-template-3 .r-player-bar .timer {*/
/*    transform-origin: right center !important;*/
/*}*/

.poker-root .r-seat.left-direction .r-player-bar .timer {
    transform-origin: right center !important;
}
.poker-root .r-seat.right-direction .r-player-bar .timer {
    transform-origin: left center !important;
}

.poker-root .mobile .r-seat .r-player-bar .timer {
    transform-origin: left center !important;
}

@keyframes roundtime {
    to {
        transform: scaleX(0);
    }
    
}

.poker-root .r-seat.default .r-player-bar {
    /*width: 9vw;*/
    width: var(--r-player-bar-w);
    height: var(--r-player-bar-h);
    /*top: auto;*/
    bottom: -28%;
    position: absolute;
    left: -15%;
}

.poker-root .r-seat.left-direction .r-player-bar {
    /*left: auto !important;*/
    right: -8%;
}

/*.poker-root .r-seat.right-direction .bar-text.player-action {*/
/*  left: 26%;*/
/*  justify-content: flex-end;*/
/*}*/
/*.poker-root .r-seat.left-direction .bar-text.player-action {*/
/*  left: -224%;*/
/*  justify-content: flex-start;*/
/*}*/

/*.poker-root .r-seat.seat-template-1.default .r-player-cards .card-holder.open-card-holder {*/
/*    left: 160% !important;*/
/*}*/

/*.poker-root .r-seat.seat-template-2.default .r-player-cards .card-holder.open-card-holder,*/
/*.poker-root .r-seat.seat-template-3.default .r-player-cards .card-holder.open-card-holder {*/
/*    left: -67% !important;*/
/*}*/


.poker-root .r-player-cards {
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.poker-root .mobile .r-player-cards {
    top: -50px;
}

.poker-root .mobile .r-seat.seat-template-5 .r-player-cards { left: -190% }

.poker-root .poker-alpha .r-seat.default .r-player-cards .card-holder.close-card-holder {
    /*bottom: 30px;*/
    bottom: -23%;
    z-index: 1;
}

.poker-root .poker-alpha .r-seat.default .r-player-cards .card-holder {
    /*transform: translateX(-50%);*/
    bottom: 1.57em;
}

.poker-root .r-seat.left-direction .r-player-cards .card-holder.open-card-holder {
    /*left: -108% !important;*/
    left: -25% !important;
    bottom: -4%;
}


.poker-root .r-seat.right-direction .r-player-cards .card-holder.open-card-holder {
    /*left: 65% !important;*/
    left: -22% !important;
    bottom: -4%;
}

/*.poker-root .poker-alpha .r-seat.seat-template-2 .r-player-cards .card-holder.close-card-holder,*/
/*.poker-root .poker-alpha .r-seat.seat-template-3 .r-player-cards .card-holder.close-card-holder {*/
/*   left: 31% !important;*/
/*}*/

.poker-root .poker-alpha .r-seat .card-holder.close-card-holder {
    justify-content: flex-start;
    display: flex;
}
.poker-root .poker-alpha .r-seat.right-direction .card-holder.close-card-holder {
    /*left: 50%;*/
    left: -43%;
}
.poker-root .poker-alpha .r-seat.seat-template-5 .r-player-cards .card-holder.close-card-holder {
   /*left: 15% !important;*/
   left: -63% !important;
   bottom: -43%;
}
.poker-root .poker-alpha .r-seat.seat-template-5 .r-player-cards .card-holder.open-card-holder {
   left: 75% !important;
   text-align: right;
   bottom: 44%;
}

.poker-root .poker-alpha .r-seat.seat-template-6 .r-player-cards .card-holder.close-card-holder {
    /*left: 5% !important;*/
}
.poker-root .poker-alpha .r-seat.seat-template-7 .r-player-cards .card-holder.close-card-holder,
.poker-root .poker-alpha .r-seat.seat-template-9 .r-player-cards .card-holder.close-card-holder {
   /*text-align: left;*/
}
.poker-root .poker-alpha .r-seat.seat-template-8 .r-player-cards .card-holder.close-card-holder {
    /*left: 12% !important;*/
}

@media (max-width: 1024px) {
    .poker-root .poker-alpha .r-seat.seat-template-5 .r-player-cards .card-holder.open-card-holder {
        text-align: left;
    }
    .player_rank:not(:empty) {
        bottom: 108% !important;
        right: 60px !important;
    }
    .poker-root .poker-alpha .r-seat.seat-template-7 .r-player-cards .card-holder.close-card-holder,
    .poker-root .poker-alpha .r-seat.seat-template-9 .r-player-cards .card-holder.close-card-holder {
       text-align: center;
    }
}

.poker-root .poker-alpha .r-player-bar .bar-text.top-line {
    /*top: 0.275em;*/
}
/*
.poker-root .poker-alpha .r-player-bar .bar-text.top-line,
.poker-root .poker-alpha .r-player-bar .bar-text.bottom-line {
    padding-left: 2.3rem;
}
*/
.poker-root .poker-alpha.mobile .r-player-bar .bar-text.top-line,
.poker-root .poker-alpha.mobile .r-player-bar .bar-text.bottom-line {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

.poker-root .poker-alpha .r-player-cards .card-holder .r-card {
    left: -0.55em;
}

.poker-root .r-player-cards .card-holder .r-card {
    position: absolute;
}

.poker-root .r-player-bar .bar-text.top-line {
    bottom: 50%;
}

.poker-root .r-player-bar .player-name {
    color: #ffffff;
}

.poker-root .r-player-bar .bar-text {
    text-align: center;
    position: absolute;
    /*height: auto;*/
    width: 100%;
    font-size: 105%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    box-sizing: border-box;
    padding-left: 0.8em;
    padding-right: 0.8em;
    white-space: nowrap;
    /*line-height: 1.4em;*/
}

.poker-root .r-player-bar .bar-text.bottom-line {
    top: 50%;
    /*bottom: 0.3em;*/
    border: 1px solid gray;
    background-color: #000;
    /*max-width: 80%;*/
    border-top: 0;
}

.poker-root .r-seat.right-direction .r-player-bar .bar-text.bottom-line {
    /*padding: 0.2rem 0 0.2rem 1.8rem;*/
    /*border-bottom-right-radius: 0.5rem;*/
}
.poker-root .r-seat.left-direction .r-player-bar .bar-text.bottom-line {
    /*padding: 0.2rem 1.8rem 0.2rem 0;*/
    /*border-bottom-left-radius: 0.5rem;*/
}

.poker-root .r-seat.seat-template-5.right-direction .r-player-bar .bar-text.bottom-line {
    border-bottom-left-radius: 0.5rem;
}
/* .poker-root .r-player-bar .bar-text.bottom-line::before {
    content: "";
    display: block;
    height: 1px;
    width: 95%;
    margin: 0 auto;
    background: gray;
} */

.poker-root .poker-alpha .r-player-bar .bar-text {
    font-size: 1em;
}

.poker-root .r-player-bar .player-cash {
    color: var(--talk-yellow);
}

.poker-root .r-scene-container .r-player-bar .bar-text.player-cash.noCash {
    font-size: 95%;
}

.poker-root .r-seats {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

.poker-root .poker-alpha .r-player-bar .bar-text.player-name {
    font-size: 0.875em;
}

.poker-root .poker-alpha .r-seat.seat-template-1 {
    left: 68.4%;
    /*top: -10.5%;*/
	top: -11%;
}

.poker-root .poker-alpha.mobile .r-seat.seat-template-2 {
    left: 90.5%;
    top: 13.5%;
}
.poker-root .poker-alpha .r-seat.seat-template-2 {
    left: 92.3%;
    /*top: 13%;*/
	top: 12%;
}

.poker-root .poker-alpha .r-seat.seat-template-3 {
    left: 92.3%;
    /*top: 56.5%;*/
	top: 45.5%;
}

/* .poker-root .poker-alpha.mobile .r-seat.seat-template-3 {
    left: 90%;
    top: 56.5%;
} */

.poker-root .poker-alpha .r-seat.seat-template-4 {
    left: 81.3%;
    top: 74.5%;
}

.poker-root .poker-alpha .r-seat.seat-template-5 {
    left: 38.5%;
    top: 75.5%;
}

.poker-root .poker-alpha .r-seat.seat-template-6 {
    left: 12%;
    top: 74.5%;
}

.poker-root .poker-alpha .r-seat.seat-template-7 {
    left: 1%;
    /*top: 56.5%;*/
	top: 45.5%;
}

.poker-root .poker-alpha .r-seat.seat-template-8 {
    left: 1%;
    /*top: 13.5%;*/
	top: 12%;
}

.poker-root .poker-alpha .r-seat.seat-template-9 {
    left: 25.5%;
    /*top: -10.5%;*/
	top: -11%;
}

.poker-root .r-seat.seat-template-5 .r-player-avatar {
    width: 8vw;
    height: 8vw;
    left: -28% !important;
    /*top: 15%;*/
    bottom: -16px;
    max-width: 150px;
    max-height: 150px;
    padding: 0;
}

.poker-root .r-seat.seat-template-5 .r-player-bar {
    /*min-height: 3.6em !important;*/
    /*bottom: -12px !important;*/
    /*left: 62% !important;*/
    bottom: -47% !important;
    left: -37% !important;
    min-height: 3em !important;
    /*width: 12em;*/
}


.card-face {
	background-size: 100%;
	width: 30px;
	height: 20px;
	display:inline-block;
}

.card-face.showcard {
    background-repeat: no-repeat;
    margin-top: 10px;
}
.mobile .card-face.showcard {
    width: 23px;
    height: 20px;
}

.game-page.poker-alpha.desktop .game-container .playcards-prv .card-face {
    height: 1.875rem;
    width: 2.2rem;
}


.poker-root .r-seat.seat-template-5 .r-player-bar .bar-text.player-name,
.poker-root .r-seat.seat-template-5 .r-player-bar .bar-text.player-cash {
    font-size: 1em;
    padding: 0 0.8em !important;
}

.poker-root .poker-alpha .r-seat.seat-template-4:has(>.panel.button.free-seat),
.poker-root .poker-alpha .r-seat.seat-template-5:has(>.panel.button.free-seat),
.poker-root .poker-alpha .r-seat.seat-template-6:has(>.panel.button.free-seat) {
    /*top: 79.5%;*/
	top: 88.5%;
}
.poker-root .poker-alpha.mobile .r-seat.seat-template-4:has(>.panel.button.free-seat),
.poker-root .poker-alpha.mobile .r-seat.seat-template-6:has(>.panel.button.free-seat) {
    top: 81.5%;
}

.poker-root .poker-alpha .r-seat.seat-template-5:has(>.panel.button.free-seat) {
    left: 46.5%;
    /*top: 79.5%;*/
	top: 88.5%;
}

/* .poker-root .mobile.poker-alpha .r-seat.seat-template-5:has(>.panel.r-player) {
    top: 94.5% !important;
}*/

.poker-root .mobile.poker-alpha .r-seat.seat-template-5 .panel.r-player .image.r-player-bar {
    left: -60% !important;
    top: 66%;
}

.poker-root .poker-alpha .r-seat.seat-template-1:has(>.panel.free-seat),
.poker-root .poker-alpha .r-seat.seat-template-9:has(>.panel.free-seat) {
    top: 0;
}

.poker-root .poker-alpha .r-seat.seat-template-2:has(>.panel.free-seat),
.poker-root .poker-alpha .r-seat.seat-template-8:has(>.panel.free-seat) {
   top: 23.5%;
}

.poker-root .poker-alpha .r-seat.seat-template-3:has(>.panel.free-seat),
.poker-root .poker-alpha .r-seat.seat-template-7:has(>.panel.free-seat) {
    top: 66.5%;
}

/* 
.poker-root .poker-alpha .r-seat.seat-template-3 {
    left: 76%;
    top: 59%;
}

.poker-root .poker-alpha .r-seat.seat-template-6 {
    left: 14.5%;
    top: 59%;
} */


/* 
.poker-root .poker-alpha .r-seat.seat-template-8 {
    left: 14.5%;
    top: 8.5%;
}

.poker-root .poker-alpha .r-seat.seat-template-31 {
    left: 45.25%;
    top: 5%;
} */

.poker-root .r-table-chips-layer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.poker-root .r-player-bet {
    width: 2em;
    height: 2em;
    position: absolute;
}

.poker-root .poker-alpha .r-table-chips-layer .r-player-bet.seat-template-1 {
    left: 69.75%;
    top: 21.75%;
}

.poker-root .poker-alpha .r-table-chips-layer .r-player-bet.seat-template-2 {
    left: 80.75%;
    top: 32.75%;
}

.poker-root .poker-alpha .r-table-chips-layer .r-player-bet.seat-template-3 {
    left: 80.75%;
    top: 61.75%;
}

.poker-root .poker-alpha .r-table-chips-layer .r-player-bet.seat-template-4 {
    left: 73.75%;
    top: 71.75%;
}

.poker-root .poker-alpha .r-table-chips-layer .r-player-bet.seat-template-5 {
    left: 45.75%;
    top: 67.75%;
}

.poker-root .poker-alpha .r-table-chips-layer .r-player-bet.seat-template-6 {
    left: 24.75%;
    top: 71.75%;
}

.poker-root .poker-alpha .r-table-chips-layer .r-player-bet.seat-template-7 {
    left: 17.75%;
    top: 61.75%;
}

.poker-root .poker-alpha .r-table-chips-layer .r-player-bet.seat-template-8 {
    left: 17.75%;
    top: 32.75%;
}

.poker-root .poker-alpha .r-table-chips-layer .r-player-bet.seat-template-9 {
    left: 28.75%;
    top: 21.75%;
}

.poker-root .game-container.poker-game .r-player-bet .r-player-bet-content {
    width: 100%;
    height: 100%;
    position: absolute;
}

.poker-root .r-chip {
    width: 2em;
    height: 2em;
    position: absolute;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
}

.poker-root .r-table-chips-layer.with-pot-component .ReactPokerPotsContainer__chips .ReactPokerPotsContainer__chip .r-chip-stack {
    display: none;
}
.poker-root .r-chip.direction-right .r-chip-stack {
    align-items: center;
    justify-content: center;
    width: 4em;
    height: 4em;
    display: flex;
    flex-wrap: wrap;
}
.poker-root .poker-alpha.mobile .r-chip.direction-right .r-chip-stack {
    height: 2em;
    width: 5em;
}

.poker-root .poker-alpha .r-chip-stack .columns {
    display: flex;
    max-width: 10em;
    font-size: 70%;
    transition: opacity 0.2s;
    margin: 0 auto;
    max-width: 6em;
    flex-shrink: 0;
    /*display: block;*/
    align-items: center;
    text-align: center;
    line-height: 1em;
}
.poker-root .poker-alpha.mobile .r-chip-stack .columns {
    display: flex;
    flex-direction: row;
}

.poker-root .r-chip-stack .columns .column {
    border-radius: 50%;
    width: 2em;
    height: 1.5em;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column-reverse;
    flex-shrink: 0;
    margin: 0 4px;
}

.poker-root .r-chip-stack .columns .column .chip {
    width: 2em;
    height: 0.4em;
    flex-shrink: 0;
    position: relative;
}

.poker-root .r-chip-stack .columns .column .chip .chip-image {
    width: 3em;
    height: 3em;
    position: absolute;
    top: initial;
    bottom: 0;
    flex-shrink: 0;
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
}

.poker-root .r-chip-stack .columns .column > div > div.chip:first-child .chip-image {
    margin-bottom: 13px;
}
.poker-root .r-chip-stack .columns .column:nth-child(2) > div > div.chip:first-child .chip-image {
    margin-bottom: 11px;
}

.poker-root .poker-alpha .r-chip .text {
    bottom: 0.2em;
    justify-content: flex-start;
    display: flex;
    align-items: center;
    right: initial;
    left: 160%;
    z-index: 3;
    width: max-content;
    padding: 2px 8px;
    transition: 0.5s opacity;
    opacity: 1;
    /*position: absolute;*/
    border-radius: 20px;
    /*border: 2px solid #ffffff69;*/
    box-shadow: inset 0 2px 4px #2b2b2b87;
    background: #00000069;
}

/* .poker-root .r-chip.direction-right .r-chip-stack {
    align-items: center;
    justify-content: flex-end;
}



.poker-root .r-chip.direction-right .text {
    bottom: 0;
    right: initial;
    left: 100%;
    height: 2em;
    line-height: 2em;
    justify-content: flex-start;
}

.poker-root .r-chip .text {
    width: 4em;
    height: 1em;
    left: -1em;
    bottom: -1em;
    position: absolute;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s opacity;
    opacity: 1;
    z-index: 3;
}

.poker-root .r-chip-stack {
    width: 2em;
    height: 2em;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.poker-root .r-chip.chip-columns.direction-right .text {
    justify-content: flex-start;
}

.poker-root .r-chip.direction-right .r-chip-stack {
    align-items: center;
    justify-content: flex-end;
}

.poker-root .r-chip .r-chip-stack .columns {
    font-size: 70%;
    transition: opacity 0.2s;
    margin: 0 auto;
}

.poker-root .poker-alpha .r-chip-stack .columns {
    display: flex;
    max-width: 10em;
}

.poker-root .r-chip-stack .columns .column .chip .chip-image {
    width: 2em;
    height: 2em;
    position: absolute;
    top: initial;
    bottom: 0;
    flex-shrink: 0;
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
}

.poker-root .r-chip.chip-columns.direction-right .text {
    justify-content: flex-start;
}

.poker-root .r-chip.chip-columns .text {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
}

.poker-root .poker-alpha .r-chip.chip-columns .text .value {
    font-family: CondensedRegularFont, verdana, tahoma, sans-serif, arial;
    font-size: 0.875em;
    line-height: 1.1;
    border-radius: 0.25em;
}

.poker-root .r-chip-stack {
    width: 2em;
    height: 2em;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.poker-root .r-chip.chip-columns .text {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
} */

.poker-root .r-table-cards {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.poker-root .r-card {
    width: 4em;
    height: 5.5em;
    border-radius: 0.3em;
    box-sizing: border-box;
}



.poker-root .r-table-cards {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.poker-root .r-table-cards .card-holder {
    width: 16em;
    height: 5em;
    font-size: 130%;
    position: relative;
    margin: 12em auto;
}

.poker-root .r-table-cards .card-holder {
    font-size: 100%;
}

.poker-root .r-table-cards .card-holder .r-card {
    position: absolute;
    display: inline-block;
}

.poker-root .r-table-cards .card-holder {
    width: 25.25em;
    margin-top: 23vh;
}

.poker-root .r-card .face {
    width: 100%;
    height: 100%;
    position: absolute;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform-style: preserve-3d;
    will-change: transform;
}

.poker-root .poker-alpha .r-card {
    width: 4.5em;
    height: 6.5em;
    border-radius: 0.3em;
    box-sizing: border-box;
}

.poker-root .r-card {
    will-change: transform, opacity;
    -webkit-perspective: 10em;
    transition: opacity 0.2s;
    pointer-events: all;
}

/*.poker-root .poker-alpha .r-seat.seat-template-5 .r-player-bar {*/
/*    background: linear-gradient(180deg, #121215 38.34%, #2e2f32 100%) !important;*/
/*    box-shadow: 0 0.125em 0.25em rgba(0, 0, 0, 0.5), inset 0 0.0625em 0 rgba(255, 255, 255, 0.2);*/
/*    border: 1px solid gray;*/
/*    border-radius: 0.5em;*/
/*}*/

.poker-root .r-card .back {
    width: 100%;
    height: 100%;
    position: absolute;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform-style: preserve-3d;
    will-change: transform;
}

.poker-root .poker-alpha .r-seat.default.right-direction .r-player-cards .card-holder:not(.opened-cards-after-show-down) .wrapper-close-card {
    left: 20%;
}

.poker-root .poker-alpha .game-container.poker-game.scale-cards .r-seat.default .card-holder:not(.opened-cards-after-show-down) .wrapper-close-card,
.poker-root .poker-alpha .game-container.poker-game.scale-cards .r-seat.flat .card-holder:not(.opened-cards-after-show-down) .wrapper-close-card {
    font-size: 50%;
}

.poker-root .poker-alpha .game-container.poker-game.scale-cards.max-cards-5 .r-player-cards .card-holder:not(.opened-cards-after-show-down) .wrapper-close-card.r-card-wrapper {
    width: 2.5em;
    transform: unset !important;
}

.poker-root .game-container.poker-game.scale-cards .r-seat.default.right-direction .r-player-cards .card-holder .wrapper-close-card {
    right: 35%;
}

.poker-root .r-player-cards .card-holder .r-card-wrapper {
    width: 1.2em;
    height: 100%;
    position: relative;
    display: inline-block;
}

.poker-root .poker-alpha .game-container.poker-game.scale-cards.max-cards-5 .r-player-cards .card-holder .r-card-wrapper:not(.wrapper-close-card) {
    /*width: 1.2em;*/
    /*left: -4%;*/
    width: 4.2em;
    left: 0;
}

.poker-root .r-player-cards .card-holder .r-card-wrapper:not(.wrapper-close-card) {
    z-index: 11;
}

.poker-root .poker-alpha .r-seat.default .r-player-cards .card-holder .r-card {
    font-size: 100%;
    /*width: 3.25em;*/
    /*height: 4.5em;*/
    height: 5.5rem;
    width: 4rem;
}
.poker-root .poker-alpha .r-seat.default .r-player-cards .card-holder.open-card-holder .r-card {
    transform: unset !important;
}
.poker-root .poker-alpha .r-seat.default .r-player-cards .card-holder.close-card-holder .r-card {
    width: 35px;
    height: 50px;
}
.poker-root .poker-alpha.mobile .r-seat.default .r-player-cards .card-holder.close-card-holder .r-card {
    width: 25px;
    height: 35px;
}

.poker-root .r-card {
    transition: transform 0.2s ease-in-out;
}

.poker-root .poker-alpha .r-seat.default.left-direction .r-player-cards.max-cards-5 .card-holder:not(.opened-cards-after-show-down) .r-card.close-card.card-0,
.poker-root .poker-alpha .r-seat.default.right-direction .r-player-cards.max-cards-5 .card-holder:not(.opened-cards-after-show-down) .r-card.close-card.card-0 {
    /*top: 33%;*/
    top: -40%;
    box-shadow: -3px 2px 8px #474646;
}

.poker-root .poker-alpha .r-seat.default.left-direction .r-player-cards.max-cards-5 .card-holder:not(.opened-cards-after-show-down) .r-card.close-card.card-1,
.poker-root .poker-alpha .r-seat.default.right-direction .r-player-cards.max-cards-5 .card-holder:not(.opened-cards-after-show-down) .r-card.close-card.card-1 {
    top: -25%;
}

.poker-root .poker-alpha .r-seat.default.left-direction .r-player-cards.max-cards-5 .card-holder:not(.opened-cards-after-show-down) .r-card.close-card.card-2,
.poker-root .poker-alpha .r-seat.default.right-direction .r-player-cards.max-cards-5 .card-holder:not(.opened-cards-after-show-down) .r-card.close-card.card-2 {
    /*top: 31%;*/
    top: -25%;
}

.poker-root .poker-alpha .r-seat.default.left-direction .r-player-cards.max-cards-5 .card-holder:not(.opened-cards-after-show-down) .r-card.close-card.card-3,
.poker-root .poker-alpha .r-seat.default.right-direction .r-player-cards.max-cards-5 .card-holder:not(.opened-cards-after-show-down) .r-card.close-card.card-3 {
    top: -30%;
}

.poker-root .poker-alpha .r-seat.default.left-direction .r-player-cards.max-cards-5 .card-holder:not(.opened-cards-after-show-down) .r-card.close-card.card-4,
.poker-root .poker-alpha .r-seat.default.right-direction .r-player-cards.max-cards-5 .card-holder:not(.opened-cards-after-show-down) .r-card.close-card.card-4 {
    top: -3%;
}

.poker-root .r-seat.default .r-player-cards .card-holder {
    left: -1.4em;
    top: auto;
    bottom: 1.8em;
    width: 11em;
    height: 4em;
    position: absolute;
    text-align: center;
    white-space: nowrap;
}


.poker-root .poker-alpha.desktop .game-container.poker-game .r-seat.default.left-direction .r-player-cards .card-holder:not(.opened-cards-after-show-down) .wrapper-close-card {
    /*left: -25%;*/
    left: 4%;
}

.poker-root .r-dealer-layer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.poker-root .r-dealer-layer .dealer-target {
    position: absolute;
    width: 1.25em;
    height: 1.125em;
}

.poker-root .poker-alpha.mobile .r-dealer-layer .dealer-target {
    z-index: 5;
}

.poker-root .poker-alpha.desktop .r-dealer-layer .dealer-target {
    z-index: 12;
}

.poker-root .poker-alpha.desktop .poker-game .r-dealer-layer .dealer-target.seat-template-1 {
    left: 64%;
    top: 15%;
    /*
    left: 76%;
    top: 11%;
    */
}

.poker-root .poker-alpha.desktop .poker-game .r-dealer-layer .dealer-target.seat-template-2 {
    left: 86%;
    top: 33%;
    /*
    left: 90%;
    top: 34%;
    */
}

.poker-root .poker-alpha.desktop .poker-game .r-dealer-layer .dealer-target.seat-template-3 {
    left: 86%;
    top: 57%;
    /*
    left: 90%;
    top: 67%;
    */
}

.poker-root .poker-alpha.desktop .poker-game .r-dealer-layer .dealer-target.seat-template-4 {
    left: 79%;
    top: 70%;
    /*
    left: 79%;
    top: 97%;
    */
}

.poker-root .poker-alpha.desktop .poker-game .r-dealer-layer .dealer-target.seat-template-5 {
    left: 50%;
    top: 62%;
    /*
    left: 44%;
    top: 95%;
    */
}

.poker-root .poker-alpha.desktop .poker-game .r-dealer-layer .dealer-target.seat-template-6 {
    left: 20%;
    top: 70%;
    /*
    left: 20%;
    top: 90%;
    */
}

.poker-root .poker-alpha.desktop .poker-game .r-dealer-layer .dealer-target.seat-template-7 {
    left: 12%;
    top: 57%;
    /*
    left: 9%;
    top: 61%;
    */
}

.poker-root .poker-alpha.desktop .poker-game .r-dealer-layer .dealer-target.seat-template-8 {
    left: 12%;
    top: 33%;
    /*
    left: 9%;
    top: 28%;
    */
}

.poker-root .poker-alpha.desktop .poker-game .r-dealer-layer .dealer-target.seat-template-9 {
    left: 36%;
    top: 15%;
    /*
    left: 33%;
    top: 11%;
    */
}

/*
.poker-root .poker-alpha .mobile .poker-game .r-dealer-layer .dealer-target.seat-template-1 {
    left: 73.9%;
    top: 28.25%;
}

.poker-root .poker-alpha .poker-game .r-dealer-layer .dealer-target.seat-template-3,
.poker-root .poker-alpha .poker-game .r-dealer-layer .dealer-target.seat-template-3 {
    left: 76.5%;
    top: 58.75%;
}

.poker-root .poker-alpha .poker-game .r-dealer-layer .dealer-target.seat-template-32,
.poker-root .poker-alpha .poker-game .r-dealer-layer .dealer-target.seat-template-32 {
    left: 44%;
    top: 63%;
}

.poker-root .poker-alpha .poker-game .r-dealer-layer .dealer-target.seat-template-6,
.poker-root .poker-alpha .poker-game .r-dealer-layer .dealer-target.seat-template-6 {
    left: 21.6%;
    top: 58.75%;
}

.poker-root .poker-alpha .poker-game .r-dealer-layer .dealer-target.seat-template-8,
.poker-root .poker-alpha .poker-game .r-dealer-layer .dealer-target.seat-template-8 {
    left: 24.15%;
    top: 28.25%;
}

.poker-root .poker-alpha .poker-game .r-dealer-layer .dealer-target.seat-template-31,
.poker-root .poker-alpha .poker-game .r-dealer-layer .dealer-target.seat-template-31 {
    left: 54.2%;
    top: 26%;
}

.poker-root .poker-alpha .poker-game .r-dealer-layer .dealer-target.seat-template-255,
.poker-root .poker-alpha .poker-game .r-dealer-layer .dealer-target.seat-template-255 {
    left: 49.5%;
    top: 35%;
}
*/

.poker-root .poker-alpha .r-dealer-layer .dealer-button,
.poker-root .poker-alpha .r-dealer-layer .dealer-target {
    width: 2vw;
    height: 2vw;
}

.poker-root .r-dealer-layer .dealer-button {
    position: absolute;
    width: 1.4em;
    height: 1.4em;
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    will-change: transform;
}

.poker-root {
    color: #fff;
}

.poker-root .r-seat.default .free-seat,
.poker-root .r-seat.default .free-seat {
    left: 24%;
    top: auto;
    bottom: 6%;
}

.poker-root .poker-alpha .r-seat.default .free-seat {
    /*width: 3.125em;*/
    /*height: 3.125em;*/
    width: 70px;
    height: 70px;
    left: 23%;
}

.poker-root .poker-alpha .r-seat .free-seat {
    background-image: url(../Images/free-seat.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
    /*box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5), inset 0 0 4px 1px rgba(255, 255, 255, 0.15);*/
    box-shadow: 0 0 8px #ffc113, inset 0 0 8px #ffeb5d;
    animation: bounce 1s infinite alternate;
}

.poker-root .r-seat .free-seat {
    position: absolute;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    cursor: pointer;
    display: table;
    table-layout: fixed;
    z-index: 7;
}

.poker-root .r-seat .free-seat .button-content {
    display: none;
    vertical-align: middle;
    text-align: center;
    text-transform: uppercase;
    white-space: pre-wrap;
    font-size: 110%;
}

.poker-root .poker-alpha .r-seat .player-action {
    color: #fff;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    /*top: -20%;*/
    top: unset;
    bottom: -12px;
    left: 50%;
    padding: 0;
    z-index: 5;
    transform: translateX(-50%) translateY(-50%);
    /*border-radius: 30px;*/
    /*border: 3px solid #fff;*/
    text-shadow: 2px 2px 3px #000;
    min-width: 70px;
}
.poker-root .poker-alpha.mobile .r-seat .player-action {
    /*border: 2px solid #fff;*/
    border: none;
}

.poker-root .poker-alpha .r-seat .player-action.Fold-action-group.wait,
.poker-root .poker-alpha .r-seat .player-action.Fold-action-group.away {
    top: unset;
}

/*.poker-root .poker-alpha .r-seat.seat-template-2 .player-action {*/
/*    transform: translateX(-90%) translateY(-10px);*/
/*    top: 52px;*/
/*}*/
/*.poker-root .poker-alpha .r-seat.seat-template-3 .player-action {*/
/*    transform: unset;*/
/*    top: 36px;*/
/*    left: -90%;*/
/*}*/

.poker-root .poker-alpha .r-seat.seat-template-5.right-direction .player-action {
    /*transform: translateX(90%) translateY(-18px);*/
    /*transform: translateX(-10%) translateY(-18px);*/
    /*justify-content: flex-start;*/
    min-width: 100px;
}
.poker-root .poker-alpha.mobile .r-seat.seat-template-5.right-direction .player-action {
    min-width: 60px;
}

.poker-root .poker-alpha.mobile .r-seat.seat-template-5.right-direction .player-action {
    /*transform: translateX(25%) translateY(-25px);*/
}

.poker-root .poker-alpha .r-seat .player-action .text {
    /*background: linear-gradient(180deg, #FFCA61 0%, #CD8800 100%);*/
    height: 100%;
    /*display: flex;*/
    justify-content: center;
    align-items: center;
    
    opacity: 0;
    visibility: hidden;
    
    /*font-weight: bold;*/
    font-weight: 800;
    /*font-size: 1.6em;*/
    font-size: 1.2em;
    font-style: italic;
    z-index: 1111;
    position: relative;
    color: #fff;
    /*font-family: "Lobster", system-ui;*/
    font-family: 'Paperlogy-8ExtraBold';
    /*text-shadow: 0 2px 0 #000, -1px 3px 0 #fff, -1px -1px 0 #fff, 2px 1px 0 #fff;*/
    white-space: nowrap;
}

.poker-root .poker-alpha .r-seat.seat-template-5 .player-action .text {
    font-size: 1.6em;
    padding-right: 2px;
}
/*
.poker-root .poker-alpha .r-seat.seat-template-5 .player-action.Fold-action-group.wait .text {
    font-size: 1.8em;
}*/

.poker-root .poker-alpha .r-seat .player-action.Fold-action-group {
    /*background: #9a9a9a;*/
    background: url('../Images/actionbtn/fold-bg.png?v=002');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.poker-root .poker-alpha .r-seat .player-action.Fold-action-group.away,
.poker-root .poker-alpha .r-seat .player-action.Fold-action-group.wait {
    /*background: #9a9a9a;*/
    background: url('../Images/actionbtn/wait-bg.png?v=001');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.poker-root .poker-alpha .r-seat .player-action.Call-action-group {
    /*background: #2496ff;*/
    background: url('../Images/actionbtn/call-bg.png?v=001');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.poker-root .poker-alpha .r-seat .player-action.Check-action-group {
    /*background: #ff2d59;*/
    background: url('../Images/actionbtn/check-bg.png?v=001');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.poker-root .poker-alpha .r-seat .player-action.Bet-action-group {
    /*background: #ffa500;*/
    background: url('../Images/actionbtn/bet-bg.png?v=001');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.poker-root .poker-alpha .r-seat .player-action.Raise-action-group {
    /*background: #0e757c;*/
    background: url('../Images/actionbtn/raise-bg.png?v=002');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.poker-root .poker-alpha .r-seat .player-action.Allin-action-group {
    /*background: #8e2aff;*/
    background: url('../Images/actionbtn/allin-bg.png?v=001');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

/* Korean Text Status */
.poker-root .poker-alpha .r-seat .player-action.Fold-action-group.wait .text::after {
    content: "대기";
}
.poker-root .poker-alpha .r-seat .player-action.Fold-action-group.away .text::after {
    content: "대기";
}
.poker-root .poker-alpha .r-seat .player-action.Fold-action-group.fold .text::after {
    content: "다이";
}
.poker-root .poker-alpha .r-seat .player-action.Call-action-group .text::after {
    content: "콜";
}
.poker-root .poker-alpha .r-seat .player-action.Check-action-group .text::after {
    content: "체크";
}
.poker-root .poker-alpha .r-seat .player-action.Bet-action-group .text::after {
    content: "벳";
}
.poker-root .poker-alpha .r-seat .player-action.Raise-action-group .text::after {
    content: "레이스";
}
.poker-root .poker-alpha .r-seat .player-action.Allin-action-group .text::after {
    content: "올인";
}
.poker-root .poker-alpha .r-seat .player-action .text {
    visibility: hidden;
}
.poker-root .poker-alpha .r-seat .player-action .text::after {
    visibility: visible;
    position: absolute;
}

.poker-root .poker-alpha .r-seat-timer.SeatTimerContainer {
    border-radius: 0.5em;
    overflow: hidden;
}

.poker-root .poker-alpha .r-seat .r-seat-timer {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.poker-root .poker-alpha .r-seat-timer .bar {
    background: transparent;
    border-radius: 0.5em;
    width: 100%;
    height: 100%;
}

.poker-root .r-seat-timer .fill {
    width: 100%;
    height: 100%;
    background: rgba(225, 101, 255, 0.5);
    border-radius: 0.15em;
}

.poker-root .poker-alpha .r-seat-timer .fill {
    border-radius: 0.5em 0 0 0.5em;
}

/* 

.poker-root .poker-game .r-scene-container .scene-background-table .background-table-border {
    background-size: auto 62em;
    background-position: 50% 45%;
}

.poker-root .r-scene-container .scene-container-content .scene-background-table .skin-section {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
}



.poker-root .poker-game .r-scene-container .scene-background-table {
    bottom: 2em;
}

.poker-root .r-scene-container .scene-container-content .scene-background-table {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.poker-root .r-scene-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.poker-root .r-scene-container .scene-container-content {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

.poker-root .r-scene-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.poker-root .poker-game .r-scene-container .scene-background-table {
    bottom: 2em;
}

.poker-root .poker-game .r-scene-container .scene-background-table .background-table-felt {
    background-size: auto 65.9em;
    background-position: 50% 44%;
}

.poker-root .poker-game .r-scene-container .scene-background-table .background-table-border {
    background-size: auto 62em;
    background-position: 50% 45%;
}

.poker-root .r-scene-container .scene-container-content .scene-background-table .skin-section {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
}

.poker-root .game-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.poker-root .game-container .game-container-content {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.poker-root .r-scene-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.poker-root .game-container-elements {
    position: relative;
    width: 100%;
    height: 100%;
} */


.poker-root .poker-alpha .r-seat.winner .r-player-avatar:before {
    content: "";
    position: absolute;
    top: -30%;
    left: -30%;
    width: 10vw;
    height: 10vw;
    background: url('../Images/winner-cc0b62f4816026a5f9cc.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    animation: winner 1.35s 0s linear infinite;
    animation-fill-mode: forwards;
}

.poker-root .poker-alpha .r-seat.winner.seat-template-5 .r-player-avatar:before {
    width: 13vw;
    height: 13vw;
}

 @keyframes bounce {
    0% { top: 0em; } /* 초기 위치 */
    100% { top: 0.4em; } /* 최종 위치 */
}

@keyframes winner {
    0% {
        opacity: 0.7;
        transform: rotate(0deg) scale(1);
    }

    40% {
        opacity: 0.85;
        transform: rotate(90deg) scale(1.1);
    }

    65% {
        opacity: 0.9;
        transform: rotate(40deg) scale(1.15);
    }

    100% {
        opacity: 0.7;
        transform: rotate(-10deg) scale(1);
    }
}

.poker-root .r-player-avatar {
    width: 100%;
    height: 100%;
    /*border-radius: 50%;*/
    position: absolute;
    /*background: url(../Images/avatar-back.svg) no-repeat center / contain;*/
    /*background-size: 100% 100%;*/
    /*background-position: 50% 50%;*/
    box-sizing: border-box;
    padding: 0.25em;
}

.poker-root .r-player-avatar .avatar {
    width: 100%;
    height: 100%;
    /*border-radius: 50%;*/
    position: relative;
    background-size: 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

.poker-root .winner .r-player-avatar .avatar {
    filter: brightness(0.5);
}

.poker-root .r-seat .r-player-avatar, .poker-root .jp .r-seat.my-default .r-player-avatar {
    /*width: 4.872em;*/
    /*height: 4.872em;*/
    width: 7vw;
    height: 7vw;
    max-width: 120px;
    max-height: 120px;
}

.poker-root .poker-alpha .r-seat.winner .r-player-avatar {
    background: url(../Images/winner-avatar.svg) no-repeat center / contain;
}

.poker-popup-body {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
    touch-action: none;
}

.poker-popup-body-inner-first {
    transform: translate(0px, 0px) translateZ(0px);
}

.poker-popup-body-inner-sec {
    box-sizing: border-box;
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    border: 1px solid rgba(255, 255, 255, 0.9);
    border-radius: 3px;
    width: 100%;
    transition-duration: 0ms;
    display: none;
    height: 114px;
    transform: translate(0px, 0px) translateZ(0px);
}

.poker-popup {
    position: absolute;
    bottom: 2px;
    top: 2px;
    right: 1px;
    overflow: hidden;
    pointer-events: none;
    transform: translateZ(0px);
    transition-duration: 0ms;
    opacity: 0;
}

.poker-root .Popup {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

.poker-root .Popup .Popup__overlay {

    background: #000000b8;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

.poker-root .Popup .Popup__wrapper {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    /* pointer-events: none; */
}

.poker-root .Popup.Popup_c_blue.Popup_v_default.Popup_modal .Popup__content {
    box-shadow: 0 0.4em 0.4em -0.2em rgba(0, 0, 0, 0.2), 0 0.5em 0.7em 0.1em rgba(0, 0, 0, 0.14), 0 0.2em 0.9em 0.2em rgba(0, 0, 0, 0.12);
}

.poker-root .Dialog.Dialog_c_blue.Dialog_v_default .Dialog__popup_content.Popup__content {
    background: #271e19;
    color: #fff;
    border-radius: 0.8rem;
    border: 1px solid #ffd0506e;
    overflow: hidden;
    box-shadow: 2px 2px 15px #ffd05075, -2px -2px 15px #ffd05075;
}

.poker-root .OutOfMoneyContainer.Dialog .Dialog__popup_content,
.poker-root .OutOfMoneyContainer.Dialog .Dialog__popup_content_Warning {
    width: 30em;
    max-width: 80%;
    /* height: 15em; */
}

.poker-root .Dialog.Dialog_c_blue.Dialog_v_default .Dialog__popup_content.Popup__content:before {
    height: 3.9em;
    border-radius: 0.1em;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    content: ' ';
    background: transparent;
    display: block;
    position: absolute;
}

.poker-root .Dialog.Dialog_v_default .Dialog__header:not(.addChipPanel) {
    margin-left: auto;
    margin-right: auto;
}

.poker-root .Dialog.Dialog_v_default .Dialog__header {
    /*height: 3.13em;*/
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    /*width: 100%;*/
    /*height: 3.5em;*/
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    position: relative;
    border-top-right-radius: 0.4em;
    border-top-left-radius: 0.4em;
}
.Popup.Dialog.OutOfMoneyContainer.pop_oom .Popup__wrapper .Dialog__header {
    width: 100%;
}

.poker-root .Dialog.Dialog_v_default .Dialog__title {
    padding-left: 1em;
    /*padding-right: 1em;*/
    white-space: nowrap;
    overflow: hidden;
    flex-grow: 1;
    text-align: center;
    font-size: 1.5rem;
    color: #000;
}
.poker-root .Dialog.Dialog_v_default .Dialog__title .Dialog__title_content {
    font-weight: 800;
    font-size: 25px;
    color: #271e1e;
}

.poker-root .SimpleButton {
    cursor: pointer;
    box-sizing: border-box;
    position: relative;
    width: 8em;
    height: 2.4em;
    border-radius: 0.2em;
}

.poker-root .desktop .chat-open-button-wrapper:hover, .poker-root .tablet .chat-open-button-wrapper:hover {
    background: #404040;
}

.poker-root .chat-open-button-wrapper .chat-open-button {
    position: static;
}

.poker-root .table-footer-container .chat-open-button {
    pointer-events: all;
}

.poker-root .Dialog.Dialog_v_default .Dialog__top_actions {
    padding-right: 0.5em;
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    justify-content: space-between;
    background: var(--talk-yellow);
}

.poker-root .SimpleButton_v_transparent.SimpleButton_use_icon:not(.SimpleButton_use_text) {
    height: 2em;
    border: none;
    width: 2em;
    border-radius: 0.3em;
    background: transparent;
}

.poker-root .SimpleButton {
    cursor: pointer;
    box-sizing: border-box;
    position: relative;
}

.poker-root .SimpleButton.close_button {
    float: right;
    margin: 0.5rem 0.2rem;
}

.poker-root .SimpleButton .SimpleButton__content {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    pointer-events: none;
    justify-content: center;
}

.poker-root .Dialog.Dialog_v_default .Dialog__top_action_icon {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    color: #fff;
    width: 2em;
    height: 2em;
    flex-shrink: 0;
    flex-grow: 0;
    margin-left: 0.2em;
    margin-right: 0.2em;
    pointer-events: none;
    position: relative;
    display: inline-block;
    vertical-align: middle;
}

.poker-root .Icon.Icon_val_close_popup .Icon__content {
    background-image: url(../Images/close-icon.svg);
    font-size: 2em;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    font-family: Icons;
    font-weight: normal;
    font-style: normal;
    font-size: 1.1em;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
}

.poker-root .Dialog__top_actions .Icon.Icon_val_close_popup .Icon__content {
    filter: invert(1);
}

.poker-root .Dialog.Dialog_v_default .Dialog__content {
    position: relative;
    flex-grow: 1;
    height: 100%;
    overflow: hidden;
    padding: 15px 0;
}
.poker-root .Dialog.Dialog_v_default.addChipPopup .Dialog__content {
    padding: 10px 0;   
}

.poker-root .OutOfMoneyContainer .OutOfMoneyContainer__content {
    /* position: absolute; */
    display: block;
    left: 1em;
    right: 1em;
    top: 0;
    bottom: 0;
    width: auto;
    height: auto;
    white-space: pre-line;
}

.poker-root .OutOfMoneyContainer .OutOfMoneyContainer__content .scroll-wrapper {
    /* display: flex; */
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 100%;
    position: relative;
}

.poker-root .OutOfMoneyContainer .OutOfMoneyContainer__text {
    font-size: 18px;
    text-align: center;
    padding: 1rem 0;
    color: #cfcfcfdb;
}

.poker-root .OutOfMoneyContainer.addChipPopup .OutOfMoneyContainer__text {
    padding: 0;
}

.poker-root .Dialog.Dialog_v_default .Dialog__actions {
    flex-direction: row-reverse;
    justify-content: center;
}

.poker-root .Dialog.Dialog_v_default .Dialog__actions {
    flex-direction: row-reverse;
    justify-content: center;
    position: relative;
    height: 4em;
    display: flex;
    align-items: center;
    padding-left: 1em;
    padding-right: 1em;
    flex-shrink: 0;
}

.poker-root .SimpleButton_c_secondary_blue.SimpleButton_v_flat {
    background: #5C5F73 !important;
    border: none !important;
    box-shadow: none !important;
}

.poker-root .Dialog.Dialog_v_default .Dialog__action {
    width: 6.875em;
    box-shadow: none;
    background: #893BF7;
    border-radius: 0.25em;
    border: none;
    cursor: pointer;
    box-sizing: border-box;
    position: relative;
    height: 2.4em;
}

.poker-root .Dialog.Dialog_v_default .Dialog__action:not(:first-child) {
    margin-left: 0;
    margin-right: 1em;
}

.poker-root .poker-alpha .Dialog.Dialog_v_default .Dialog__action {
    width: 6.875em;
}

.poker-root .poker-alpha .iScrollVerticalScrollbar {
    width: 0.125em !important;
}


/* Header CSS */
.poker-root .poker-alpha .TableHeaderContainer {
    /*height: 3rem;*/
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 112;
    /*margin-top: 0.8rem;*/
    /*background: #000;*/
    /*border-bottom: 2px solid #cfbc7c;*/
}

.poker-root .TableHeaderContainer .TableHeaderContainer__content {
    height: 100%;
    /*padding: 0 0.5em;*/

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.poker-root .poker-alpha.mobile .TableHeaderContainer .TableHeaderContainer__content.max-multitbl {
    flex-wrap: wrap;
}

.poker-root .poker-alpha.desktop .TableHeaderContainer .TableHeaderContainer__content,
.poker-root .poker-alpha.tablet .TableHeaderContainer .TableHeaderContainer__content {
    /*padding-left: 1em;*/
}

.poker-root .TableHeaderContainer .TableHeaderContainer__header_buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.poker-root .table-container .screen-wrapper .screen-content {
    font-size: 100%;
}

.poker-root .poker-alpha .TableHeaderContainer .TableHeaderContainer__button.SimpleButton.SimpleButton_use_icon:not(.SimpleButton_use_text) {
    /*background: linear-gradient(180deg, #29292B 0%, #1E1F21 100%);*/
    box-sizing: border-box;
    /*box-shadow: 0 0.125em 0.25em rgba(0, 0, 0, 0.5), inset 0 0 0.25em rgba(255, 255, 255, 0.15);*/
    border-radius: 50%;
}

.poker-root .TableHeaderContainer .TableHeaderContainer__button.SimpleButton.SimpleButton_use_icon:not(.SimpleButton_use_text) {
    width: 2.5em;
    height: 2.5em;
}

.poker-root .TableHeaderContainer .TableHeaderContainer__table_router_holder {
    height: 100%;
    width: auto;
    position: relative;
    /*padding-left: 0.2em;*/
    /*padding-right: 0.2em;*/
    box-sizing: border-box;
    flex-grow: 1;
    /*overflow: hidden;*/
}

.poker-root .poker-alpha.desktop .TableHeaderContainer .TableHeaderContainer__right_buttons, .poker-root .poker-alpha.tablet .TableHeaderContainer .TableHeaderContainer__right_buttons {
    position: relative;
}

.poker-root .TableHeaderContainer .TableHeaderContainer__right_buttons {
    margin-left: auto;
    padding-right: 0.5rem;
    position: relative;
    z-index: 3;
}

.poker-root .poker-alpha .TableHeaderContainer .TableHeaderContainer__button.SimpleButton.SimpleButton_use_icon:not(.SimpleButton_use_text) {
    /* background: linear-gradient(180deg, #29292B 0%, #1E1F21 100%); */
    /*background: #000;*/
    box-sizing: border-box;
    /*box-shadow: 0 0.125em 0.25em rgba(0, 0, 0, 0.5), inset 0 0 0.25em rgba(255, 255, 255, 0.15);*/
    border-radius: 50%;
}

.poker-root .TableHeaderContainer .TableHeaderContainer__button.SimpleButton.SimpleButton_use_icon:not(.SimpleButton_use_text) {
    width: 80px;
    height: 75px;
}

.poker-root .TableHeaderContainer .TableHeaderContainer__header_buttons .TableHeaderContainer__button {
    /*margin-right: 0.5rem;*/
}

.poker-root .SimpleButton.SimpleButton_use_icon .SimpleButton__content {
    justify-content: center;
}

.poker-root .SimpleButton .SimpleButton__content {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    pointer-events: none;
}

.poker-root .SimpleButton .SimpleButton__icon {
    flex-shrink: 0;
    flex-grow: 0;
    margin-left: 0.2em;
    margin-right: 0.2em;
    pointer-events: none;
    position: relative;
    display: inline-block;
    vertical-align: middle;
}

.poker-root .poker-alpha .TableHeaderContainer .TableHeaderContainer__button.SimpleButton.SimpleButton_use_icon:not(.SimpleButton_use_text) .SimpleButton__icon {
    width: 100%;
    height: 100%;
}

.poker-root .Icon.Icon_val_add_money .Icon__content {
    background-image: url(../Images/add-money.svg);
}

.poker-root .poker-alpha .Icon.Icon_val_table_settings .Icon__content {
    background-image: url(../Images/table-settings.svg);
}

.poker-root .poker-alpha .Icon.Icon_val_table_info .Icon__content {
    background-image: url(../Images/table-info-btn.svg);
}

.poker-root .poker-alpha .Icon.Icon_c_white.Icon_val_table-menu-leave .Icon__content {
    background-image: url(../Images/leave-game-icon.svg);
}

.poker-root .poker-alpha .TableHeaderContainer__menu_button.ddOpened {
    background-image: url(../Images/menu-btn-close.svg) !important;
}
.poker-root .poker-alpha .TableHeaderContainer__menu_button.ddClosed {
    /*background-image: url(../Images/menu-btn.png) !important;*/
    background-image: url(../Images/menubtn/menubtn-blackbg.png) !important;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    border-radius: 0 !important;
}

.poker-root .poker-alpha .menuItem:hover .Icon.Icon_c_white .Icon__content

.poker-root .poker-alpha .TableHeaderContainer__menu_button {
    /*background-color: #000 !important;*/
    background-size: 80% !important;
    background-position-y: center !important;
    background-position-x: center !important;
}

.poker-root .SimpleButton_v_transparent .SimpleButton__icon .Icon__content {
    font-size: 2em;
    background-size: 85%;
}

.poker-root .Icon_v_default .Icon__content {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
}

.poker-root .poker-alpha .TableHeaderContainer .TableHeaderContainer__button.SimpleButton.SimpleButton_use_icon:not(.SimpleButton_use_text):hover {
    /*background: linear-gradient(180deg, #5A5B5D 0%, #343538 71.87%);*/
}

.poker-root .poker-alpha .TableHeaderContainer .TableHeaderContainer__button.SimpleButton.SimpleButton_use_icon:not(.SimpleButton_use_text) {
    box-sizing: border-box;
    /*box-shadow: 0 0.125em 0.25em rgba(0, 0, 0, 0.5), inset 0 0 0.25em rgba(255, 255, 255, 0.15);*/
    border-radius: 50%;
    /*border: 3px solid var(--talk-yellow);*/
}

.poker-root .orientation-error-container {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: transparent;
    z-index: 100000000;
}

.poker-root .orientation-error-container .orientation-error-container-content {
    background: url(../Images/orientation-error-background.svg);
    -webkit-backdrop-filter: blur(0.25em) brightness(0.3);
    backdrop-filter: blur(0.25em) brightness(0.3);
    background-repeat: no-repeat;
    background-size: auto 5em;
    background-position: 50% 70%;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    font-size: 150%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.poker-root .orientation-error-container .orientation-error-container-content .orientation-error-message {
    font-family: CondensedBoldFont, verdana, tahoma, sans-serif, arial;
    color: #ffffff;
    background-color: transparent;
    text-transform: uppercase;
    font-size: 1.667em;
    top: -1.5em;
    line-height: normal;
    width: auto;
}

.poker-root .orientation-error-container .orientation-error-container-content .orientation-error-message {
    height: auto;
    position: relative;
    text-align: center;
}

.poker-root .poker-alpha.desktop .poker-game .r-scene-container .pots, .poker-root .poker-alpha.tablet .poker-game .r-scene-container .pots {
    /*top: 41.3%;*/
    /*top: 36.3%;*/
    top: 45%;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    /*left: 67%;*/
}

.poker-root .ReactPokerPotsContainer {
    position: absolute;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
}

.poker-root .ReactPokerPotsContainer.ReactPokerPotsContainer_horizontal .ReactPokerPotsContainer__content {
    flex-direction: row;
}

.poker-root .ReactPokerPotsContainer .ReactPokerPotsContainer__content {
    display: flex;
}

.poker-root .poker-alpha.desktop .ReactPokerPotsContainer.ReactPokerPotsContainer_c_dark.ReactPokerPotsContainer_v_default .ReactPokerPotsContainer__chips, .poker-root .poker-alpha.tablet .ReactPokerPotsContainer.ReactPokerPotsContainer_c_dark.ReactPokerPotsContainer_v_default .ReactPokerPotsContainer__chips {
    background: transparent;
}

.poker-root .ReactPokerPotsContainer .ReactPokerPotsContainer__chips {
    padding: 0;
    border-radius: 2em;
    display: inline-flex;
    max-width: 40em;
    position: relative;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.poker-root .ReactPokerPotsContainer .ReactPokerPotsContainer__chip {
    position: relative !important;
    display: inline-flex;
    width: auto !important;
    margin-left: 0.5em;
    margin-right: 0.5em;
    height: 1.9em !important;
    align-items: center;
}

.poker-root .poker-alpha.desktop .ReactPokerPotsContainer .ReactPokerPotsContainer__chip, .poker-root .poker-alpha.tablet .ReactPokerPotsContainer .ReactPokerPotsContainer__chip {
    flex-direction: column;
}

.poker-root .poker-alpha.desktop .r-chip.direction-right .text, .poker-root .poker-alpha.tablet .r-chip.direction-right .text, 
.poker-root .poker-alpha.desktop .r-chip.direction-left .text, .poker-root .poker-alpha.tablet .r-chip.direction-left .text {
    bottom: 0.5em;
}

.poker-root .poker-alpha.desktop .ReactPokerPotsContainer .ReactPokerPotsContainer__chip .text, 
.poker-root .poker-alpha.tablet .ReactPokerPotsContainer .ReactPokerPotsContainer__chip .text {
    /*margin-top: 0.25em;*/
    margin-top: 0.7em;
    background: #3b3b3bdb;
    border-radius: 1em;
    /*min-width: 3em;*/
    width: 22vw;
    height: 2em !important;
    justify-content: space-between;
    padding: 0 0.5em;
}
.poker-root .poker-alpha.desktop .ReactPokerPotsContainer .ReactPokerPotsContainer__chip .text::before, 
.poker-root .poker-alpha.tablet .ReactPokerPotsContainer .ReactPokerPotsContainer__chip .text::before {
   position: absolute;
   content: "";
   width: 100%;
   height: 100%;
   left: 0;
   background: url('../Images/chip_total.png');
   background-repeat: no-repeat;
   background-size: contain;
   background-position-x: left;
}

.poker-root .ReactPokerPotsContainer .ReactPokerPotsContainer__chip .text {
    position: relative !important;
    display: inline-flex !important;
    /*width: auto !important;*/
    left: 0 !important;
    order: 100;
}
.poker-root .poker-alpha .ReactPokerPotsContainer__chips .ReactPokerPotsContainer__chip .text .val-lbl,
.poker-root .poker-alpha .ReactPokerPotsContainer__chips .ReactPokerPotsContainer__chip .text .value {
    font-size: 1.7em;
    text-shadow: 1px 1px 3px #000;
    font-style: italic;
}
.poker-root .poker-alpha .ReactPokerPotsContainer__chips .ReactPokerPotsContainer__chip .text .val-lbl {
    color: #fff;
    padding-left: 1.5em;
}
.poker-root .poker-alpha .ReactPokerPotsContainer__chips .ReactPokerPotsContainer__chip .text .value {
    color: #ffce46;
    padding-right: 10px;
}
.poker-root .poker-alpha .r-player-bet-content .r-chip.chip-columns .text .value {
    font-size: 1em;
    padding: 2px 4px;
}

.poker-root .r-player.player-obscured, .poker-root .jp.tablet .r-player.player-obscured {
    opacity: 0.5;
}

.poker-root .poker-alpha .r-card.win,
.poker-root .poker-alpha .flip-card-inner.win {
    box-shadow: 0 0 0.25em 0.125em #FBFF38;
    border-radius: 0.33em;
}

.poker-root .flip-card-inner.lose img,
.poker-root .r-card.lose .face {
    filter: brightness(0.3);
}

.poker-root .poker-alpha.desktop .r-seat.default.right-direction .r-seat__won_message, .poker-root .poker-alpha.tablet .r-seat.default.right-direction .r-seat__won_message {
    left: auto;
    right: 0;
    animation: won_message_right 0.85s 0s linear;
    animation-fill-mode: forwards;
}

.poker-root .poker-alpha.desktop .r-seat.default.left-direction .r-seat__won_message, .poker-root .poker-alpha.tablet .r-seat.default.left-direction .r-seat__won_message {
    right: auto;
    left: 2em;
    animation: won_message_left 0.85s 0s linear;
    animation-fill-mode: forwards;
}

@keyframes won_message_left {
    0% {
        opacity: 0.01;
        transform: translate(-2.5em, 5em);
    }

    100% {
        opacity: 1;
        transform: translate(0, 0);
    }
}

@keyframes won_message_right {
    0% {
        opacity: 0.01;
        transform: translate(2.5em, 5em);
    }

    100% {
        opacity: 1;
        transform: translate(0, 0);
    }
}

.poker-root .r-seat__won_message {
    /*top: -2em;*/
    top: 5em;
    width: 100%;
    height: 100%;
    position: absolute;
    pointer-events: none;
    z-index: 11;
}

.poker-root .mobile .r-seat__won_message {
    /* top: -2.5em; */
    top: 38px;
    left: 12px;
}

.poker-root .PlayerWonContainer__content {
    position: absolute;
    height: 100%;
    width: 100%;
}

.poker-root .r-seat.seat-template-5 .r-seat__won_message {
    top: 6em;
}
.poker-root .mobile .r-seat.seat-template-5 .r-seat__won_message {
    top: 38px;
    left: -28px;
}

.poker-root .mobile .PlayerWonContainer__content
.poker-root .mobile .r-seat__won_message {
    top: -14%;
}

.poker-root .poker-alpha.desktop .r-seat.default.right-direction .r-seat__won_message .PlayerWonContainer__message, 
.poker-root .poker-alpha.tablet .r-seat.default.right-direction .r-seat__won_message .PlayerWonContainer__message {
    align-items: center;
}

.poker-root .PlayerWonContainer__message {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.poker-root .PlayerWonContainer_c_dark.PlayerWonContainer_v_default .PlayerWonContainer__funds {
    background: transparent;
}

.poker-root .PlayerWonContainer_v_default .PlayerWonContainer__funds {
    padding: 0.2em;
    border-radius: 0.2em;
}

.poker-root .poker-alpha .PlayerWonContainer_v_default .PlayerWonContainer__funds__text {
    font-size: 1.46em;
    text-shadow: -2px -2px 3px black,2px -2px 3px black,2px 2px 3px black,2px 2px 3px black;
    font-weight: 600;
}

.poker-root .PlayerWonContainer_c_dark.PlayerWonContainer_v_default .PlayerWonContainer__funds__text {
    /*color: #FBFF38;*/
    color: #90ffdb;
    white-space: nowrap;
}

.poker-root .game-container.poker-game .r-seat .seat-combination-names {
    /*position: absolute;*/
    position: fixed;
    width: 100%;
    left: 50%;
    right: 50%;
    transform: translateX(-50%);
    top: 26%;
    pointer-events: none;
    z-index: 2222;
    display: flex;
    justify-content: center;
}
.poker-root .mobile .game-container.poker-game .r-seat .seat-combination-names {
    top: 26%;
}
/*
.winner.left-direction .seat-combination-names {
    left: 0 !important;
    right: 0 !important;
}


.poker-root .mobile .game-container.poker-game .r-seat.seat-template-1 .seat-combination-names,
.poker-root .mobile .game-container.poker-game .r-seat.seat-template-2 .seat-combination-names,
.poker-root .mobile .game-container.poker-game .r-seat.seat-template-3 .seat-combination-names {
    right: 41% !important;
    bottom: 51%;
    left: unset !important;
}

.poker-root .mobile .game-container.poker-game .r-seat.seat-template-4 .seat-combination-names {
    right: 64% !important;
    bottom: 36%;
    left: unset !important;
}
.poker-root .mobile .game-container.poker-game .r-seat.seat-template-5 .seat-combination-names {
    right: 10% !important;
    bottom: 5%;
}
.poker-root .mobile .game-container.poker-game .r-seat.right-direction .seat-combination-names {
    right: 40%;
    bottom: 50%;
}
*/


.poker-root .game-container.poker-game .r-seat .seat-combination-names .seat-combination-names-content {
    width: 30%;
    display: flex;
}
.poker-root .mobile .game-container.poker-game .r-seat .seat-combination-names .seat-combination-names-content {
    width: 65%;
}
/*
.winner.left-direction .seat-combination-names-content {
    left: -50% !important;
    right: 0;
}

.poker-root .mobile .game-container.poker-game .r-seat.seat-template-4 .seat-combination-names .seat-combination-names-content {
    bottom: 3.5em;
    left: 2%;
    right: unset;
}

.poker-root .game-container.poker-game .r-seat .seat-combination-names.winner .seat-combination-names-content .names {
    background: #000;
}


.poker-root .game-container.poker-game .r-seat .seat-combination-names .seat-combination-names-content .names {
    border-radius: 1.5em;
    transform: translateX(-50%);
    flex-grow: 0;
    flex-shrink: 0;
}


.poker-root .game-container.poker-game .r-seat .seat-combination-names .seat-combination-names-content .names .name {
    font-family: BoldFont, verdana, tahoma, sans-serif, arial;
    color: #ffffff;
    padding: 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    margin: 0.1em 0;
    font-size: 1rem;
    text-transform: uppercase;
}

.poker-root .mobile .game-container.poker-game .r-seat .seat-combination-names .seat-combination-names-content .names .name {
    font-size: 0.6rem;
}
*/
.seat-combination-names .seat-combination-names-content .names {
    width: 100%;
}
.seat-combination-names .seat-combination-names-content .names .name {
    position: relative;
}
.seat-combination-names .seat-combination-names-content .names .name>img {
    position: absolute;
    /*
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    */
    inset: 0;
    margin: auto;
    width: 100%;
    height: auto;
    
}
.mobile .seat-combination-names .seat-combination-names-content .names .name>img {
    margin: 0 auto;
}

.poker-root .poker-alpha.desktop .table-router-container {
    /*height: 3em;*/
}

.poker-root .poker-alpha.mobile .table-router-container {
    height: 100%;
}

.poker-root .table-router-container .scroll-panel {
    display: flex;
    padding-top: 5px;
}

.poker-root .mobile .table-router-container .scroll-panel,
.poker-root .mobile .table-router-container .scroll-panel > .scroll-wrapper {
    display: flex;
    align-items: center;
    gap: 4px;
}
.poker-root .mobile .max-multitbl .table-router-container .scroll-panel,
.poker-root .mobile .max-multitbl .table-router-container .scroll-panel > .scroll-wrapper {
    justify-content: center;
}

.poker-root .table-router-container .scroll-panel .panel.button.add-table-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    margin-left: 0.5rem;
}

.poker-root .mobile .table-router-container .scroll-panel .panel.button.add-table-button {
    width: 3em;
    height: 3em;
    margin-left: 1.5rem;
}

.poker-root .table-router-container .scroll-panel .header-btns {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.poker-root .table-router-container .scroll-panel .header-btns > .hbtn {
    min-width: 5rem;
    text-align: center;
    border: 1px solid gray;
    border-radius: 4px;
    margin: 5px;
    padding: 4px 10px;
    background: linear-gradient(180deg, #616161, #000000);
    cursor: pointer;
    display: flex;
    align-items: center;
}

.poker-root .table-router-container .scroll-panel .header-btns > .hbtn > img.hbtn-icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 6px;
}

/* .content {
        width: 140px;
        height: 140px;
        margin: 10px;
      
      } */
.poker-root .table-router-container .scroll-panel .scroll-wrapper {
    display: flex;
    align-items: center;
    /* flex-direction: column; */
    /*height: 100%;*/
    white-space: nowrap;
    /* overflow-x: scroll; */
    -ms-overflow-style: none;
    scrollbar-width: none;
    /* position: absolute; */
    top: 100%;
    /*background: #0000008f;*/
    z-index: 4;
    min-width: 13rem;
    gap: 10px;
}

.poker-root .table-router-container .scroll-panel .scroll-wrapper .add-table-button {
    /*background: #ffd331cf;*/
    background: url('../Images/add-tbl-btn.png?v=001');
    /*color: #000;
    font-weight: 600;
    text-align: center;
    padding: 4px;
    cursor: pointer;
    */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 55px;
    height: 55px;
    margin: 4px;
}
.poker-root .poker-alpha.mobile .table-router-container .scroll-panel .scroll-wrapper .add-table-button {
    width: 30px;
    height: 30px;
}

/* .poker-root .table-router-container .scroll-panel .scroll-wrapper > .panel.active {
    background: #a7a6a6b5;
} */

.poker-root .table-router-container .scroll-panel .scroll-wrapper::-webkit-scrollbar {
    width: 0;
    background: transparent;
}

.poker-root .table-router-container .scroll-panel .scroll-wrapper::-webkit-scrollbar-thumb {
    background: transparent;
}

.poker-root .table-router-container .scroll-panel .scroll-wrapper .prv-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    margin-top: 5px;
}
.poker-root .poker-alpha.mobile .table-router-container .scroll-panel .scroll-wrapper .prv-wrapper {
    width: calc(100vw / 4);
}

.poker-root .table-router-container .scroll-panel .scroll-wrapper .prv-wrapper .tbl-total-user {
    background: url(../Images/table-total-bg.png) no-repeat;
    position: absolute;
    background-size: contain;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -10%;
    font-size: 16px;
    top: 5%;
    z-index: 5;
}
.poker-root .poker-alpha.mobile .table-router-container .scroll-panel .scroll-wrapper .prv-wrapper .tbl-total-user {
    width: 20px;
    height: 20px;
    right: -8%;
    top: 4px;
    font-size: 12px;
}

.poker-root .poker-alpha.desktop .TableHeaderContainer .TableIndicator,
.poker-root .poker-alpha.tablet .TableHeaderContainer .TableIndicator {
    cursor: pointer;
    /* width: 6.8125em; */
    width: unset;
}

.poker-root .poker-alpha.mobile .TableHeaderContainer .TableIndicator {
    cursor: pointer;
    /* width: 100%; */
    /*margin: 0 0.8em;*/
    width: calc((100vw / 4) - 7px);
}

.poker-root .poker-alpha .table-router-container .table-indicator {
    margin-right: 0;
    /* padding: 8px 12px; */
}

.poker-root .table-router-container .table-indicator {
    /* display: inline-block; */
    display: flex;
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    font-size: 100%;
    gap: 10px;
    /* height: 2.8em !important; */
}
.poker-root .mobile .table-router-container .table-indicator {
    gap: 2px;
}

.poker-root .TableIndicator.TableIndicator_v_transparent.TableIndicator_c_gray .TableIndicator__content {
    /*background-image: linear-gradient(180deg, #29292B 0%, #1E1F21 100%) !important;*/
}
.poker-root .TableIndicator.TableIndicator_v_transparent.TableIndicator_c_gray.active .TableIndicator__content {
    /*background-image: linear-gradient(130deg, #014145 0%, #109098 100%) !important;*/
    /*border: 3px solid var(--talk-yellow);*/
}
.poker-root .TableIndicator.TableIndicator_v_transparent.TableIndicator_c_gray .TableIndicator__content,
.poker-root .TableIndicator.TableIndicator_v_transparent.TableIndicator_c_gray.active .TableIndicator__content {
    /*background-image: linear-gradient(0deg, #141414, #060606) !important;*/
    background: #141212;
    border: 2px solid #3f3f3f;
    color: #fff;
}
    

.TableIndicator__content{
    overflow:hidden;
}
.TableIndicator__content .playerTurn{
    background:initial;
    animation: playerTurnBlink 1s infinite;
}

@keyframes playerTurnBlink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .4;
  }
}


.poker-root .poker-alpha .table-indicator.inactive {
    /*opacity: 0.4;*/
}

.poker-root .poker-alpha .TableIndicator .TableIndicator__content {
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0.125em 0.25em rgba(0, 0, 0, 0.5), inset 0 0 0.25em rgba(255, 255, 255, 1);
    border-radius: 6.25em;
    box-sizing: border-box;
}

.poker-root .poker-alpha.desktop .TableIndicator.TableIndicator_v_transparent .TableIndicator__content {
    height: unset;
    text-align: center;
    position: unset;
    width: 9rem;
}
.poker-root .poker-alpha.desktop .TableIndicator.TableIndicator_v_transparent .TableIndicator__content .player-status {
    font-size: 22px;
    color: #fff;
}

.poker-root .poker-alpha.mobile .TableIndicator.TableIndicator_v_transparent .TableIndicator__content {
    height: max-content;
    text-align: center;
    font-size: 1em;
    position: unset;
    width: 8em;
}
.poker-root .poker-alpha.mobile .TableIndicator.TableIndicator_v_transparent .TableIndicator__content .player-status {
    padding: 4px 0;
}

.poker-root .TableIndicator .TableIndicator__content {
    left: 0;
    width: 100%;
    position: absolute;
    background-size: 100% 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

.poker-root .table-router-container .add-table-button {
    left: 0;
    top: 0;
    height: 100%;
    display: inline-block;
    margin-left: 0;
    cursor: pointer;
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
}

.poker-root .table-router-container .add-table-button .button-content {
    left: 0;
    top: 0;
    height: 100%;
    position: absolute;
    background: url(../Images/add-table.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
}

.poker-root .poker-alpha .table-router-container .add-table-button .button-content {
    background-image: url(../Images/add-table-phone.svg);
    width: 100%;
    height: 100%;
    /*background-color: #000;*/
    border-radius: 50%;
    /*border: 3px solid var(--talk-yellow);*/
    background-size: 65%;
}

.poker-root .table-router-container .add-table-button {
    cursor: pointer;
}

.poker-root .OutOfMoneyContainer__content .OutOfMoneyContainer__text .buyInModal.gameModel .btn.selectBuyInBtn {
    background: var(--talk-yellow);
    margin-bottom: 0.5em;
}
.poker-root .popmobile .OutOfMoneyContainer__content .OutOfMoneyContainer__text .buyInModal.gameModel .btn.selectBuyInBtn {
    padding: 8px 25px !important;
    font-size: 18px !important;
}

.winning-sign {
    display: none;
}

.winner .winning-sign {
    display: flex;
    width: 100%;   
    /*transform: translateX(-50%) translateY(-50%);*/
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 11;
    /*bottom: -15px;*/
    bottom: 90px;
}

.right-direction.winner .winning-sign {
    left: 0;
    top: 0;
    /*transform: rotate(-10deg);*/
}
.right-direction.seat-template-5.winner .winning-sign {
    left: -25%;
    top: -50%;
    bottom: 0;
}

.mobile .right-direction.winner .winning-sign {
    left: 0;
    /*top: 0;*/
    transform: unset;
}

.mobile .right-direction.seat-template-5.winner .winning-sign {
    left: -60%;
    top: -20px;
}

.left-direction.winner .winning-sign {
    left: 0;
    top: 0;
    /*transform: rotate(10deg);*/
}
.mobile .left-direction.winner .winning-sign {
    /*left: 10%;*/
    left: 0;
    /*top: 0;*/
    transform: unset;
}

.wn.winner {
  /*animation: 1s anim-flipX ease infinite;*/
}

@keyframes anim-flipX {
  0% {
    opacity: 0;
    transform: rotateX(90def);
  }
  50% {
    opacity: 1;
    transform: rotateX(720deg);
  }
  100% {
    /* animate nothing to pause animation at the end */
    opacity: 1;
    transform: rotateX(720deg);
  }
}

.winner .winning-sign .wn.winner {
    color: #ffd94f;
    font-family: 'AgencyFB', sans-serif;
    font-style: italic;
    text-shadow: 3px 4px 6px #000000d4 !important;
    font-weight: bold;
    font-size: 2.3em;
    z-index: 1111;
    width: 100%;
    text-align: center;
    background: url(../Images/winner-sign.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 120px;
    height: 55px;
}
.mobile .winner .winning-sign .wn.winner {
    text-shadow: 2px 0px 3px #000000 !important;
}

.winner.seat-template-5 .winning-sign .wn.winner {
    /*font-size: 2vw;*/
}

.mobile .winner.seat-template-5 .winning-sign .wn.winner {
    font-size: 5vw;
}

/*.winner.left-direction .winning-sign {*/
/*    left: 0;*/
/*    right: 0;*/
/*}*/

/*
.r-seat.seat-template-2.winner .winning-sign,
.r-seat.seat-template-3.winner .winning-sign {
    left: 95%;
    bottom: -18%;
}
*/
/*
.r-seat.seat-template-5.winner .winning-sign {
    left: 74%;
    bottom: -14%;
}
*/
/*
.mobile .r-seat.seat-template-1.winner .winning-sign {
    left: -25% !important;
    right: unset !important;
}

.mobile .r-seat.seat-template-2.winner .winning-sign,
.mobile .r-seat.seat-template-3.winner .winning-sign {
    left: -45% !important;
    right: unset !important;
}

.mobile .r-seat.seat-template-4.winner .winning-sign {
    right: 158% !important;
    left: unset !important;
}

.mobile .r-seat.seat-template-5.winner .winning-sign {
    left: 50% !important;
    right: unset !important;
    top: 50%;
}
.mobile .r-seat.seat-template-6.winner .winning-sign {
    right: 83%;
}
*/

.mobile .winner .winning-sign {
    /*top: 50%;*/
    top: -20px;
    z-index: 5;
    align-items: flex-start;
}
.mobile .winner .winning-sign .wn.winner {
    font-size: 5vw;
}

/*
.winner .winning-sign > img {
    position: absolute;
    left: -50%;
    width: 200px;
    top: 12px;
}

.mobile .winner .winning-sign > img {
    width: 135px;
}
.mobile .winner.seat-template-7 .winning-sign > img {
    left: -10%;
}
*/

.switchloader .loading {
    display: flex;
    align-items: center;
    justify-content: center;
}

.switchloader .loading p {
    color: #ffd200;
    text-align: center;
    margin-top: 1rem;
}

.chk-btn{
    margin-right: 4px;
    /*
    background: #252018;
    border: 2px solid #fff;
    box-shadow: inset 1px 1px 5px #1e1e1e;
    */
    background: url('../Images/old_actionbtn/fold-bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 20px;
    /*border: 1px solid var(--talk-yellow);*/
    overflow: hidden;
    float: left;
    flex: 1;
    height: 50px;
    max-width: 102px;
    cursor: pointer;
}
.chk-btn:hover {
    outline: 2px solid #ffd358 !important;
}

.mobile .chk-btn:hover {
    outline: none !important;
}
.mobile .chk-btn:hover label.form-control span, 
.mobile .footerBtn:not(:disabled):focus, 
.mobile .footerBtn:not(:disabled):hover, 
.mobile .footerBtn:not(:disabled):hover .small-val {
    color: #fff;
    text-shadow: none;
}

.chk-btn label {
    float: left; 
    line-height: 3.0em;
    width: 100%; 
    /*height: 3.0em;*/
    height: 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding: 5px;
}

.chk-btn label span {
    text-align: center;
    padding: 3px 0;
    display: block;
    text-shadow: 1px 1px 2px #000;
    font-size: 20px;
    white-space: nowrap;
    font-style: italic;
}

.chk-btn label input {
    /*position: absolute;*/
    /*display: none !important;*/
    color: var(--talk-yellow) !important;
    /*margin-left: auto !important;*/
    display: none !important;
}
/* selects all of the text within the input element and changes the color of the text */
.chk-btn label input + span {
    color: #fff;
    text-shadow: 1px 1px 2px #000;
    font-weight: 600;
    text-transform: uppercase;
    margin-right: 3px;
}
.chk-btn label input:checked + span { 
    /*color: #ffd358;*/
    color: #ffa950;
    text-shadow: 1px 1px 2px #000;
}

.chk-btn:has(input:checked) {
    color: #ffd358 !important;
    text-shadow: 0 0  6px rgba(0, 0, 0, 0.8);
    /*border: 3px solid var(--talk-yellow);*/
    border: 2px solid #ffd358;
}

.chk-btn:has(input:checked):hover > label > span,
.chk-btn:has(input:checked) > label > span{
    color: #ffd358 !important;
}

@media (max-width: 1024px) {
    .mobile .chk-btn {
        flex: unset;
        width: calc(33.333% - 4px);
        max-width: 130px;
    }
    .mobile .chk-btn label {
        font-size: 1em;
    }
    .mobile .table-actions-container-content .player-actions .actions,
    .mobile .table-actions-container-content .player-actions .actions .actionsWrapper {
        display: flex;
        justify-content: center;
    }
    .mobile .chk-btn label span {
        font-size: 16px;
        white-space: nowrap;
        font-style: italic;
    }
}
@media (max-width: 321px) {
    .mobile .chk-btn label span {
        font-size: 13px;
        white-space: nowrap;
        font-style: italic;
    }
}

.sideDropdownMenu {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 7;
    top: 120%;
    right: 0;
    /* width: 10vw; */
    width: 200px;
    background: #403934;
    border: 4px solid var(--talk-yellow);
}

.sideDropdownMenu::before {
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 10px solid var(--talk-yellow);
    position: absolute;
    top: -14px;
    right: 14px;
}

.sideBtn-text {
    font-size: 1.3em;
    font-weight: 600;
}
@media screen and (max-width: 480px) {
    .sideDropdownMenu {
        /* width: 30vw; */
        width: 150px;
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    .sideDropdownMenu {
        /* width: 30vw; */
        width: 220px;
    }

    .sideBtn-text {
        font-size: 1.2em;
    }
    .sideDropdownMenu::before {
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 8px solid var(--talk-yellow);
        top: -10px;
        right: 4px;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .sideDropdownMenu {
        /* width: 30vw; */
        width: 270px;
        font-size: 1.3rem;
    }
}

.sideDropdownMenu .menuItem {
    display: flex;
    align-items: center;
    padding: 0.5rem;
}

.sideDropdownMenu .menuItem:not(:last-child) {
    border-bottom: 1px solid #000;
}

.sideDropdownMenu .menuItem:hover {
    background: var(--talk-yellow);
    color: #000;
}

.sideDropdownMenu .menuItem:hover .panel.SimpleButton .SimpleButton__content .Icon__content {
    filter: invert(1);
}

.playcards-prv {
  display: flex;
  position: sticky;
  right: -5.5rem;
  top: 6px;
  /*margin-left: 0.5rem;*/
}

.playcards-prv .card-face {
  width: 2rem;
  /*height: 3.5rem;*/
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: center;
  margin: 0.15rem;
}

@media (max-width: 520px) {
    .playcards-prv .card-face {
        width: 2em !important;
        height: 2em;
    }
}
@media (min-width: 521px) and (max-width: 670px) {
    .playcards-prv .card-face {
        width: 1.5rem !important;
    }
}

.playcards-prv .cardsprv-wrap {
    display: flex;
}

.panel.add-table-button + .scroll-wrapper > .panel.TableIndicator,
.scroll-wrapper > .panel.TableIndicator.table-indicator:not(:first-child) {
  /*margin-left: 5rem;*/
}

.prv-wrapper .prv-tblname {
    text-align: center;
    /*margin-left: 7px;*/
    text-shadow: 1px 1px 2px #000;
    font-size: 18px;
    color: #fff;
}
.mobile .prv-wrapper .prv-tblname {
    font-size: 12px;
}

.player_rank:not(:empty) {
    position: absolute;
    /*left: 100%;*/
    /*right: 36px;*/
    right: -100%;
    z-index: 6;
    /*bottom: 72px;*/
    bottom: 0;
    white-space: nowrap;
    background: #bf912a;
    text-shadow: 1px 1px 3px #000;
    box-shadow: inset 2px 2px 10px #8f6633, inset -2px -2px 10px #573e1f;
    padding: 0.2rem 0.75rem;
    border-radius: 0.5rem;
    border: 1px solid #7d5a1c;
}

.mobile .player_rank:not(:empty) {
    bottom: -5% !important;
    right: 185% !important;
    transform: translateX(25%);
    text-align: center;
}

.winner.player_rank:not(:empty) {
    display: none;
}

.sitdown-pls {
    display: none;
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translateX(-50%) translateY(-45%);
    width: 30%;
    text-align: center;
    /*
    background: #000000b8;
    color: #e9e8e6;
    font-size: 1.5rem;
    padding: 0.7rem 3rem;
    border-radius: 10rem;
    */
}
.sitdown-pls > img {
    width: 100%;
    height: auto;
}

.sitdown-txt {
    color: #000;
    -webkit-text-stroke: 1px #fff;
    text-stroke: 2px #fff;
    font-size: 25px;
    font-weight: 900;
    font-style: italic;
    white-space: nowrap;
}

@media (max-width: 768px) {
    .sitdown-pls {
        padding: 0;
        width: 50%;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
    .sitdown-txt {
        font-size: 5vw;
        -webkit-text-stroke: 0.5px #fff;
        text-stroke: 0.5px #fff;
    }
}
.TableIndicatorContainer .prv-wrapper .TableIndicator__content:empty {
    background: none;
    border: none;
}
.TableIndicatorContainer .prv-wrapper .TableIndicator__content:empty + .prv-tblname {
  position: absolute;
  top: 35%;
  /*left: 55%;*/
}

.mobile .TableIndicatorContainer .prv-wrapper .TableIndicator__content:empty+.prv-tblname {
    top: 10%;
    font-size: 14px;
}

.am-back-button-wrapper {
    z-index: 99;
}

/* Vertical Slider */
.vslidecontainer {
  width: 235px;
  height: 40px;
  top: 95px;
  transform: rotate(270deg);
  /*background: url('../Images/vslider/slider-bg.png') no-repeat;*/
  background: #1e1d1d;
  /*background-position: center;*/
  /*background-size: 100% 100%;*/
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px solid #837b60;
  border-radius: 30px;
}
.mobile .vslidecontainer {
    width: 230px;
    height: 36px;
    top: -18px;
    /*top: 44%;*/
}
.vslider-wrap {
  width: 235px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px;
}

.mobile .vslider-wrap {
  width: 230px;
}

input[type=range].vslider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 20px;
  /*border-radius: 5px;*/
  background: transparent;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  opacity: 1;
  -webkit-transition: .2s;
  transition: opacity .2s;
  position: relative;
}

input[type=range].vslider.raiseRangeSlide::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: url(../Images/vslider/slider-track2.png) no-repeat;
    background-size: 100% 100%;
    z-index: -1;
    box-shadow: inset 2px 2px 8px black, inset -2px -2px 8px black;
}

input[type=range].vslider:hover {
  opacity: 1;
}

/***** Chrome, Safari, Opera, and Edge Chromium *****/
input[type=range].vslider::-webkit-slider-runnable-track {
  background: url('../Images/vslider/slider-track2.png') no-repeat;
  background-size: cover;
  /*border-bottom: 1px solid rgb(122, 122, 122);*/
  /*height: 20px;*/
}

/******** Firefox ********/
input[type=range].vslider::-moz-range-track {
  background: url('../Images/vslider/slider-track2.png') no-repeat;
  background-size: cover;
  /*border-bottom: 1px solid rgb(122, 122, 122);*/
  /*height: 20px;*/
}

/* Center : margin-top = (track height in pixels / 2) - (thumb height in pixels /2) */
input[type=range].vslider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 40px;
  height: 40px;
  border: 0;
  background: url('../Images/vslider/knob-circle.png');
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  cursor: pointer;
  margin-top: -12px;
  margin-left: 0;
  transform: rotate(90deg);

}

input[type=range].vslider::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 40px;
  height: 40px;
  border: 0;
  background: url('../Images/vslider/knob-circle.png');
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  cursor: pointer;
  margin-top: -5px;
}
.vslidecontainer .vsbtn-wrap {
    width:10%;
    position: relative;
}
.vslidecontainer .range-wrap {
    width: 80%;
}
.vslidecontainer .vsbtn {
    width: 35px;
    height: 35px;
    z-index: 2;
    position: absolute;
    cursor: pointer;
    filter: brightness(0.8);
}

.vslidecontainer .vsbtn.disabled {
  filter: brightness(0.5);
  pointer-events: none;
}

.vslidecontainer .vsbtn:hover {
  filter: brightness(1);
}
.vslidecontainer .add-vsbtn {
    background: url('../Images/vslider/betpad-add.png') no-repeat;
    background-size: cover;
    background-position: center;
    right: -10px;
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
}
.vslidecontainer .minus-vsbtn {
    background: url('../Images/vslider/betpad-minus.png') no-repeat;
    background-size: cover;
    background-position: center;
    left: -10px;
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
}

/* Jackpot Message */
.jackpotMessage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background: #000000b5;
}
.jackpotMessage > * {
    pointer-events: all;
}
.jackpotMessage .jp-wrapper {
    position: absolute;
    width: 500px;
    height: 374px;
    background: url('../Images/jackpot_bg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    inset: 0;
    margin: 5% auto;
}
.jackpotMessage .jp-wrapper .jp-info {
    position: relative;
    text-align: center;
}

.jackpotMessage .jp-amount {
    position: relative;
    background: #000;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Avigea-Italic";
    font-size: 4.5rem;
    margin-top: 145px;
    letter-spacing: 5px;
}
.jackpotMessage .jp-amount::before {
    position: absolute;
    transform: translate(-2px, -2px);
    content: attr(data-shadow);
    background: linear-gradient(360deg, 
    #cfbc7c 0%, 
    #9e8d52 9%, 
    #cfbc7c 20%, 
    #9e8d52 30%, 
    #cfbc7c 42%, 
    #fff5d0 73%, 
    #cfbc7c 88%, 
    #cfbc7c 100%);
    -webkit-background-clip: text; /* For Safari and Chrome */
    background-clip: text;
    color: transparent;
    padding-right: 8px;
}

.jackpotMessage .jp-pname {
    color: #fff;
    margin-top: 25px;
}

.jackpotMessage .jp-hname {
    position: relative;
    font-family: 'Cafe24ClassicType-Regular';
    background: #000;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 13px;
    font-size: 2rem;
    font-weight: 600;
    font-style: italic;
    z-index: 2;
}
.jackpotMessage .jp-hname::before {
    content: attr(data-shadow); /* Use the same text as shadow */
    position: absolute;
    transform: translate(-2px, -2px);
    background: linear-gradient(to bottom, #cfbc7c, #fff5d0);
    -webkit-background-clip: text; /* For Safari and Chrome */
    background-clip: text;
    color: transparent;
    padding-right: 5px;
}

.menuItem.menu_top_close {
    position: absolute;
    z-index: 5;
    top: 110%;
    right: 5px;
    display: flex;
    flex-direction: column;
}
.menuItem.menu_top_close .menu_top_close-icon {
    margin-left: auto;
}

.mobile .menuItem.menu_top_close .menu_top_close-icon {
    height: 50px;
    width: 50px;
    padding: 3px;
}

.menuItem.menu_top_close .menu_top_close-icon.exit-on {
    background: url('../Images/exit-on.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 85%;
}
.menuItem.menu_top_close .menu_top_close-icon.exit-off {
    background: url('../Images/exit-off.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 85%;
}
.menuItem.menu_top_close .exitGame-txt {
    text-align: right;
    padding-right: 5px;
    white-space: nowrap;
}

.sound-btn-wrap {
    position: absolute;
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0.5em;
    cursor: pointer;
    z-index: 10;
    background: url('../Images/menubtn/circle-btn-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
}
.sound-btn-wrap.chat-on {
    left: 4.5em;
}
.sound-btn-wrap.chat-off {
    left: 0.5em;
}

.mobile .sound-btn-wrap {
    width: 45px;
    height: 45px;
    left: 15px;
    bottom: 5px;
}
.mobile .sound-btn-wrap .sound-btn-on,
.mobile .sound-btn-wrap .sound-btn-off {
    width: 80%;
    height: 80%;
}
.sound-btn-wrap .sound-btn-on {
    background: url('../Images/volume-on.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 75%;
    width: 50px;
    height: 50px;
}
.sound-btn-wrap .sound-btn-off {
    background: url('../Images/volume-off.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 75%;
    width: 50px;
    height: 50px;
}

.sparkling {
    position: relative;
    animation-duration: 2s;
    animation-name: anim-popoutin;
    animation-iteration-count: 1;
    animation-direction: alternate;
}
.star.s1 {
    width: 0.8em;
    height: 0.8em;
    position: absolute;
    left: 0;
    top: 55px;
    animation: comeInOut 1000ms infinite;
}
.star.s2 {
    width: 1.3em;
    height: 1.3em;
    position: absolute;
    right: -10px;
    top: 57px;
    animation: comeInOut 1300ms infinite;
}
.star.s3 {
    width: 0.5em;
    height: 0.5em;
    position: absolute;
    right: -20px;
    top: 30px;
    animation: comeInOut 2000ms infinite;
}
.star.s4 {
    width: 0.5em;
    height: 0.5em;
    position: absolute;
    left: -10px;
    bottom: 0px;
    animation: comeInOut 2200ms infinite;
}
.star.s5 {
    width: 0.3em;
    height: 0.3em;
    position: absolute;
    left: 50px;
    bottom: 50px;
    animation: comeInOut 1500ms infinite;
    z-index: 1111;
}
.star.s6 {
    width: 0.3em;
    height: 0.3em;
    position: absolute;
    right: 10px;
    top: 50px;
    animation: comeInOut 1500ms infinite;
    z-index: 1111;
}
.star.s7 {
    width: 0.3em;
    height: 0.3em;
    position: absolute;
    right: 25px;
    top: 30px;
    animation: comeInOut 2300ms infinite;
    z-index: 1111;
}
.star .si {
    width: 100%;
    height: 100%;
    position: relative;
    background: url(../Images/star.png);
    background-size: 100%;
    animation: spin 4000ms infinite;
}

.buyin-btn {
    /*background: var(--talk-yellow);*/
    background: linear-gradient(45deg, #d78f23, #553f02);
    color: #e1e1e1;
    padding: 2px 20px;
    border-radius: 20px;
    font-size: 0.9em;
    text-shadow: 1px 1px 2px black;
}

.progressTimer .countdown-tr,
.progressTimer .countdown-bl {
    position: absolute;
    border-radius: 6px;
    box-sizing: border-box;
    height: 0px;
    width: 0px;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    animation-duration: calc((var(--duration) + 1) * 1s);
}

.progressTimer .countdown-tr {
    right: 0px;
    bottom: 0px;
    border-top: 4px solid gold;
    border-right: 4px solid gold;
    animation: countdown-animation calc((var(--duration) + 1) * 1s) linear 1;
}

.progressTimer .countdown-bl {
    left: 0px;
    top: 0px;
    border-left: 4px solid gold;
    border-bottom: 4px solid gold;
    animation: countdown-animation-inner calc((var(--duration) + 1) * 1s) linear 1;
}

.panel.r-player .r-player-bar.gauge-on:has(.progressTimer) {
    box-shadow: 2px 2px 8px #e7e733, -2px -2px 8px #e7e733;
    border-radius: 6px;
}

.playerProfileInfo.cashier-box .lvl-list {
    gap: 1.5rem;
}

.mobileView .playerProfileInfo.cashier-box .lvl-list {
    gap: 0.5rem;
}

.mobileView .playerProfileInfo.cashier-box .lvl-list .FrameName {
    font-size: 14px;
    white-space: nowrap;
    margin-top: 5px;
}
.mobileView .playerProfileInfo.cashier-box .lvl-list .activeFrameName {
    font-size: 16px;
    margin-top: 10px;
}

.r-player-cards .card-holder.close-card-holder .r-card-wrapper.wrapper-close-card > .r-card.card-1.hole-card {
    transform: rotate(-10deg);
}
.r-player-cards .card-holder.close-card-holder .r-card-wrapper.wrapper-close-card > .r-card.card-2.hole-card {
    transform: rotate(10deg);
}

.Dialog__content .avtarBtnSection .selectBuyInBtn {
    font-size: 20px;
    border-radius: 20px;
}

/* Showdown */
.showdown{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-size: 2rem;
	width:100%;
	position:relative;
	z-index:111;
	background: #000000bf;
	animation-duration: 2s;
  	animation-name: fadeIn;
  	animation-iteration-count: 1;
	opacity: 0;
	overflow: hidden;
}
.sd_text{
	color:#fff;
	font-weight:bold;
	position:relative;
	width:1000px;
	z-index:1111
}
.sd_text div{
	list-style:none;
	float:left;
	position:relative;
	width:50%
}

.sd_text div img{width:100%;}

.sd_text .shw_txt{
	top:-100px;
	left:-200%;
  	animation-duration: 1.5s;
  	animation-name: slidein1;
  	animation-iteration-count: 1;
  	animation-direction: alternate;
}

.sd_text .dwn_txt{
	left:200%;
  	animation-duration: 1.5s;
  	animation-name: slidein2;
  	animation-iteration-count:1;
  	animation-direction: alternate;
}
@keyframes fadeIn {
	0% {opacity: 0;transform: scale(0)}
	1% {opacity: 1;transform: scale(1)}
	50% {opacity: 1;transform: scale(1)}
	99% {opacity: 1;transform: scale(1)}
	100% {opacity: 0;transform: scale(0)}
}
@keyframes slidein1 {
	0% { left:-100%; }
	5% {left:150px;}
	20% {left: 30px;}
	89%{left:30px;}
	100% {-left:200%;}
}
@keyframes slidein2 {
	0% { left:100%; }
	5% {left:-150px;}
	20% {left: -30px;}
	89%{left:-30px;}
	100% {left:200%;}
}

.lighteffect1{
	width:100%;
	position:absolute;
	left:0px;
	z-index:1;
  	animation-duration: 0.2s;
  	animation-name: effect1;
  	animation-iteration-count:1;
  	animation-direction: alternate;	
	text-align:center;	
    transform: scale(0);
	opacity:0
}

.lighteffect4{
	width:100%;
	position:absolute;
	left:0px;
	z-index:1;
  	animation-duration: 0.5s;
  	animation-name: effect4;
  	animation-iteration-count:1;
  	animation-direction: alternate;	
	text-align:center;	
    transform: scale(0);
	opacity:0
}
.lighteffect1 img{width:100%;}
.lighteffect4 img{width:50%;}


@keyframes effect1 {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    /* animate nothing to add pause at the end of animation */
    transform: scale(1);
    opacity: 0.5;
  }
}


@keyframes effect4 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50%{
	 transform:scale(1);
	 opacity:1
	 
  }
  100% {
    /* animate nothing to add pause at the end of animation */
    transform: scale(2);
    opacity: 0;
  }
}


@media only screen and (max-width: 600px) {
.sd_text{
	width:90%;
}

.sd_text .show{
	top:-50px;
}
}