@font-face {
    font-family: 'digital-clock-font';
    /* src: url('../Font/digital-7/digital-7.ttf or .otf'); */
    /* src: url('../Font/GameBg.png'); */
}

:root {
    --talk-yellow: #fdd835;
    --talk-yellow-hover: #ffe000;
    --td-bodybg: #303339;
    --td-bodybg-hover: #696C70;
    --panel-bg: #16171C;
    --modal-color: #c3c3c3;
}

.poker-table-box {
    position: absolute;
}

.game-page {
    padding: 0rem;
    /* background-color: #19191D; */
    /* background-image: url('../Images/GameBg.png');
    background-size: cover;
    background-repeat: no-repeat; */
    height: 100%;
    width: 100%;
    position: fixed;
}

.collectChipsImage {
    position: absolute;
    cursor: pointer;
    z-index: 1;
    display: flex;
    align-items: center;
    height: auto !important;
    width: 5vw;
}


.collectChipsImage img {
    width: 2.2vw !important;
}

.collectChipsImage p {
    margin: 0;
    margin-left: 0.1vw;
    color: #fff;
    font-weight: 500;
    font-size: 0.8vw;
}

.mobileView .collectChipsImage {
    position: absolute;
    cursor: pointer;
    z-index: 1;
    display: flex;
    align-items: center;
    height: auto !important;
    width: 5vh;
}


.mobileView .collectChipsImage img {
    width: 2.8vh !important;
}

.mobileView .collectChipsImage p {
    margin: 0;
    margin-left: 0.1vh;
    color: #fff;
    font-weight: 500;
    font-size: 1.2vh;
}

.chipsImage {
    position: absolute;
    z-index: 1;
    text-align: center;
    align-items: center;
    height: auto !important;
}


.chipsImage img {
    margin: 0;
    width: 1.5vw !important;
}

.chipsImage p {
    margin: 0;
    color: #ffffff9f;
    font-weight: 500;
    font-size: 0.8vw;
}

.mobileView .chipsImage {
    position: absolute;
    cursor: pointer;
    z-index: 1;
    display: block;
    align-items: center;
    height: auto !important;
}

.mobileView .chipsImage img {
    width: 1.5vh !important;
}

.mobileView .chipsImage p {
    margin: 0;
    margin-left: 0.2vw;
    color: #fff;
    font-weight: 500;
    font-size: 1.2vh;
}


.fill-seat-wrapper {
    position: absolute;
    cursor: pointer;
    z-index: 1;
    display: flex;
    align-items: center;
}

.fill-seat-wrapper .playerAvatarImg {
    z-index: 111;
}


.empty-seat-wrapper {
    position: absolute;
    cursor: pointer;
    z-index: 1;
    /* border-radius: 50%;
    box-shadow:  2px 8px rgba(0, 0, 0, 0.5), inset 0 0 4px 1px rgba(255, 255, 255, 0.15) */
}

.gameTable {
    height: 100%;
    width: auto;
}

.gameTable img {
    height: 100%;
    width: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.p-sit-btn {
    position: absolute;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 20vh;
    width: 12vw;
}

.p-sit-btn {
    height: 9vh;
    width: 12vw;
    position: absolute;
}

.main-btn-box {
    height: 100%;
    width: 100%;
    vertical-align: middle;
}

.main-btn-box .sit-info {
    height: 100%;
    width: 100%;
    background-image: url('../Images/ButtonBg.png');
    background-size: 100% 100%;
    box-shadow: 4px 4px 50px 10px rgba(0, 0, 0, 0.25);
    vertical-align: middle;
}

.main-btn-box.right .sit-info {
    border-radius: 40px !important;
    border-top-left-radius: 50px !important;
    border-bottom-left-radius: 50px !important;
}

.main-btn-box.left .sit-info {
    border-radius: 20px !important;
    border-top-right-radius: 50px !important;
    border-bottom-right-radius: 50px !important;
}

.main-btn-box.right .sit-info {
    padding-left: 11vh;
}

.main-btn-box.right .sit-info p {
    text-align: left;
    color: #fff;
    font-size: 3vh;
    height: 9vh;
    margin: 0px;
    display: table-cell;
    vertical-align: middle;
}

.main-btn-box.left .sit-info {
    padding-left: 3vh;
}

.main-btn-box.left .sit-info p {
    text-align: right;
    color: #fff;
    font-size: 3vh;
    height: 9vh;
    display: table-cell;
    vertical-align: middle;
}

.main-btn-box .profilePic {
    position: absolute;
    border-radius: 50%;
    height: 100%;
    width: 9vh;
    background-image: url('../Images/UserPlaceHolder.png');
    background-size: 100% 100%;
    vertical-align: middle;
}

.p-sit-btn .right .profilePic {
    left: 0;
    top: 0;
}

.p-sit-btn .left .profilePic {
    right: 0;
    top: 0;
}


.p-sit-btn-fill p {
    color: #fff;
    align-items: center;
    text-align: center;
}

.p-sit-1 {
    margin: 0;
    top: 18vh;
    left: 50vw;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.p-sit-2 {
    margin: 0;
    top: 22vh;
    right: 15vw;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.p-sit-3 {
    margin: 0;
    top: 50vh;
    right: 8vw;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.p-sit-4 {
    margin: 0;
    bottom: 12vh;
    right: 15vw;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}


.p-sit-5 {
    bottom: 10vh;
    left: 51vw;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.p-sit-6 {
    margin: 0;
    bottom: 12vh;
    left: 27vw;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.p-sit-7 {
    margin: 0;
    top: 50vh;
    left: 20vw;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.p-sit-8 {
    margin: 0;
    top: 22vh;
    left: 27vw;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}


.buyInModal {
    overflow: auto;
    color: #fff;
    text-align: center;
}

.buyInModal thead th {
    position: sticky;
    top: 0;
}

.buyInModal::-webkit-scrollbar {
    display: none;
}

.buyInModal {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.buyInModal table td,
.buyInModal table th {
    text-align: start !important;
}

.chipsDetails p,
.chipsDetails span {
    /*color: var(--talk-yellow);*/
    color: #c3c3c3;
    margin: 0;
    font-size: 20px;
}

.popmobile .chipsDetails p,
.popmobile .chipsDetails span {
    font-size: 16px;
}

.popmobile .Dialog__title .Dialog__title_content {
    font-size: 22px;
}

.popmobile .chipsBuyIn .chipsBuyIn_input {
    font-size: 25px !important;
}
.gameModel {
    position: relative;
    height: 100%;
}

.gameModel .modalBody {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
}

.giftModal {
    position: relative;
    height: 100%;
}

.giftModal .modalBody {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.gameModel h2 {
    font-size: 2vw !important;
}

.mobileView h2 {
    font-size: 3vh !important;
}

.gameModel h4 {
    font-size: 1.5vw !important;
}

.mobileView h4 {
    font-size: 2.5vh !important;
}

.gameModel .mobileView h2 {
    font-size: 2vh !important;
}

.gameModel .mobileView h4 {
    font-size: 1.5vh !important;
}

.chipsDetails .minBuyIn,
.chipsDetails .maxBuyIn {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
}

.chipsDetails .minBuyIn {
    text-align: right;
}

.chipsDetails .maxBuyIn {
    text-align: left;
}

.chipsBuyIn input {
    color: #fff;
    font-size: 30px !important;
    font-weight: 700;
    background: transparent;
    border: 1px solid #776907;
    width: 100%;
    text-align: center;
    outline: none;
    /*box-shadow: inset 0 -1px 1px gray;*/
    border-radius: 5px;
}

.chipsBuyIn {
    background-color: #000;
    border-radius: 5px;
    padding: auto;
    height: 3em;
    line-height: 3em;
    text-align: center;
    margin-top: 0;
    position: relative;

    /* background-color: #4c486f;
    border-radius: 5px;
    padding: auto;
    height: 3.5vw;
    line-height: 3.5vw;
    text-align: center;
    margin-top: 0.5vw; */
}

.chipsBuyIn:has(input:focus)::before,
.chipsBuyIn:has(input:hover)::before{
    color: black;
    content: "\25B2";
    /* margin-bottom: 5px; */
    top: 1px;
    position: absolute;
    right: 0;
    width: 1.5vw;
    height: 50%;
    font-size: 1.3vw;
    pointer-events: none;
    background: var(--talk-yellow);
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid black;
}

.chipsBuyIn:has(input:focus)::after,
.chipsBuyIn:has(input:hover)::after{
    color: black;
    content: "\25BC";
    bottom: -1px;
    position: absolute;
    right: 0;
    width: 1.5vw;
    height: 50%;
    font-size: 1.3vw;
    pointer-events: none;
    background: var(--talk-yellow);
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 768px) {
    .popmobile .chipsBuyIn:has(input:focus)::before,
    .popmobile .chipsBuyIn:has(input:hover)::before,
    .popmobile .chipsBuyIn:has(input:focus)::after,
    .popmobile .chipsBuyIn:has(input:hover)::after {
        width: 4.5vw;
        font-size: 4.3vw;
    }
}

.mobileView .chipsBuyIn {
    border-radius: 5px;
    height: 4vh;
    line-height: 4vh;
    margin-top: 0.5vh;
}

.mobileView .chipsBuyIn input {
    font-size: 1.5vh !important;
    font-weight: 700;
}


.sliderInput {
    /*background-color: #3a3532;*/
    /*border: 2px solid #ffd050;*/
    padding: 0.3vw;
    /*border-radius: 50px;*/
    /*margin: 1.5rem 10%;*/
    margin: 1rem 5%;
    border-top: 1px solid #f2a733;
    border-radius: 0;
    padding-top: 20px;
}

.sliderInput input {
    font-size: 0.5em;
}
.sliderInput .buyinSlider {
    -webkit-appearance: none;
    appearance: none;
    /*background-color: #453b3b;*/
    background: linear-gradient(to right,#f2a733 var(--sliderpct),#453b3b var(--sliderpct));
    margin: 10px 0;
    box-shadow: inset 1px 1px 4px #000, inset -1px -1px 3px #a5a2a2;
}

.selectBuyInBtn {
    /* border: 2px solid #fff !important; */
    border: none !important;
    padding: 0.5rem !important;
    padding-left: 1.5em !important;
    padding-right: 1.5em !important;
    border-radius: 0.5em;
    /* box-shadow: 0 0px 8px 0 rgba(255, 255, 255, 0.5), 0 0px 8px 0 rgba(255, 255, 255, 0.5); */
    /* background-image: url('../Images/LobbyButton.png');
    background-size: 100% 100%; */
    background-color: var(--talk-yellow);
    color: #000 !important;
    font-size: 2.5vh !important;
    text-align: start !important;
    font-weight: 600 !important;
}

.poker-root .Popup__wrapper .OutOfMoneyContainer__content .avtarBtnSection {
    margin-top: 2rem;
}
.poker-root .logoutPopup .Popup__wrapper .OutOfMoneyContainer__content .avtarBtnSection {
    margin-top: 0;
}

.mobileView .selectBuyInBtn {
    border: none !important;
    padding: 4% 8% !important;
    border-radius: 0.5vh !important;
    /* box-shadow: 0 0px 8px 0 rgba(255, 255, 255, 0.5), 0 0px 8px 0 rgba(255, 255, 255, 0.5); */
    /*background-image: url('../Images/LobbyButton.png');*/
    /*background-size: 100% 100%;*/
    background-color: var(--talk-yellow);
    color: #000 !important;
    font-size: 2.5vh !important;
    line-height: 1.5vh !important;
    text-align: start !important;
    font-weight: 600 !important;
}


.playerDetails hr {
    margin: 0;
    width: 100%;
}

.backCardImgLeft {
    bottom: 40%;
    right: 90%;
}

.backCardImgRight {
    bottom: 40%;
    left: 90%;
}

.animation-card-image {
    position: absolute;
    z-index: 1;
    transition: all 0.05s;
    perspective: 1000;
    -ms-transform: perspective(1000px);
    -moz-transform: perspective(1000px);
    -ms-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;

}

/* 

.card-image-animation {
    animation: slide-up 1.5s;
} */

.card-image {
    position: absolute;
    transform: translate3d(50%, 100%);
    z-index: 1;
}

.mobileView .card-image {
    position: absolute;
    transform: translate3d(50%, 100%);
    z-index: 1;
}

.frontFirstCard {
    bottom: 25%;
    right: 70%;
    transform: rotate(-45deg);
}

.frontSecondCard {
    bottom: 55%;
    right: 40%;
    transform: rotate(-15deg);
}

.frontThirdCard {
    bottom: 55%;
    right: -5%;
    transform: rotate(15deg);
}

.frontFourCard {
    bottom: 25%;
    right: -40%;
    transform: rotate(45deg);
}

.backFirstCard {
    bottom: 30%;
    right: 60%;
    transform: rotate(-30deg);
}

.backSecondCard {
    bottom: 50%;
    right: 40%;
    transform: rotate(-10deg);
}

.backThirdCard {
    bottom: 50%;
    right: 10%;
    transform: rotate(10deg);
}

.backFourCard {
    bottom: 30%;
    right: -10%;
    transform: rotate(30deg);
}


.mobileView .frontFirstCard {
    bottom: 35%;
    right: 70%;
    transform: rotate(-45deg);
}

.mobileView .frontSecondCard {
    bottom: 58%;
    right: 44%;
    transform: rotate(-15deg);
}

.mobileView .frontThirdCard {
    bottom: 58%;
    right: 10%;
    transform: rotate(15deg);
}

.mobileView .frontFourCard {
    bottom: 35%;
    right: -18%;
    transform: rotate(45deg);
}

.mobileView .backFirstCard {
    bottom: 30%;
    right: 60%;
    transform: rotate(-30deg);
}

.mobileView .backSecondCard {
    bottom: 50%;
    right: 40%;
    transform: rotate(-10deg);
}

.mobileView .backThirdCard {
    bottom: 50%;
    right: 10%;
    transform: rotate(10deg);
}

.mobileView .backFourCard {
    bottom: 30%;
    right: -10%;
    transform: rotate(30deg);
}


.mobileView .playerDetails {
    color: #fff;
    background-image: url('../Images/Icons/PlayerInfoBg.png');
    background-size: 100% 100%;
    padding: 0% !important;
    position: absolute;
    z-index: 1;
    bottom: -50%;
    width: 150%;
    right: -25%;
    text-align: center;
    z-index: 1111111;
}


.mobileView .playerDetails p {
    margin: 0;
    white-space: nowrap;
    font-size: 1.3vh;
}

.playerDetails hr {
    margin: 0;
    width: 100%;
}

.playerDetails .dealerIconRight {
    position: absolute;
    top: -30%;
    right: -10%;
    width: 2vw;
    height: 2vw;
}

.playerDetails .dealerIconLeft {
    position: absolute;
    top: -30%;
    left: -10%;
    width: 2vw;
    height: 2vw;
}

.mobileView .playerDetails .dealerIconRight {
    position: absolute;
    top: -15%;
    right: -15%;
    width: 2.5vh;
    height: 2.5vh;
}

.mobileView .playerDetails .dealerIconLeft {
    position: absolute;
    top: -15%;
    width: 2.5vh;
    height: 2.5vh;
}

.card-box {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    overflow: hidden;
}

.mid-wrap {
    display: flex;
    pointer-events: none;
}
.mid-wrap > * {
    pointer-events: all;
}

.raisebet-wrap .mid-wrap {
    position: absolute;
    bottom: 0;
    width: 180px;
    height: 330px;
    padding: 4px 0;
    background: linear-gradient(270deg, #000000, #33333300);
    z-index: -1;
    right: 0;
}
.mobile .raisebet-wrap .mid-wrap {
    position: absolute;
    bottom: 90%;
    width: 136px;
    right: 0;
    height: 285px;
    background: linear-gradient(270deg, #000000, #33333300);
    z-index: 5;
}
@media (min-width: 365px) {
    .mobile .raisebet-wrap .mid-wrap {
        padding-right: 4px;
    }
}
.raisebet-wrap .mid-wrap .mwr-left {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    flex-direction: column;
    width: 50%;
    margin-bottom: 90px;
}
.mobile .raisebet-wrap .mid-wrap .mwr-left {
    align-items: center;
    margin-bottom: 30px;
}
.raisebet-wrap .mid-wrap .mwr-right {
    display: flex;
    justify-content: center;
    width: 50%;
    position: relative;
    /*padding-right: 6px;*/
}

.footerSliderInput {
    width: 66.66%;
    height: 100%;
    text-align: center;
    margin: 2px 0;
    /*background: linear-gradient(0deg, #161616, #6b6b6b);*/
    border-radius: 5px;
    /*border: 2px solid #6d6d6d;*/
    padding: 0 6px;
    flex: 1;
}

.mobile .footerSliderInput {
    /*height: 25px;*/
    background: transparent;
    border-radius: 5px;
    border: none;
}
.footerSliderBox {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.mobile .footerSliderInput {
    margin-bottom: 2px;
}

.mobile .footerSliderBox {
    gap: 0.2rem;    
    height: 100%;
}

.raiseInput {
    /*padding-left: 1rem;*/
    /*padding-right: 1rem;*/
    /* background-color: #4c486f; */
    /*background-color: #222227;*/
    /*padding: 0.5vh;*/
    border-radius: 10px;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 5px;
    background: #141313;
}

.mobile .footerSliderBox .raiseInput {
    height: 3em;
    background: transparent !important;
    width: 30%;
}

.raiseInput span {
    color: #fff;
}

.mobile .raiseInput {
    display: block;
    height: 100%;
}

.mobile .raiseInput span {
    margin-left: 3px;
}

.mobile .raiseInput input[type="number"] {
    border: none !important;
    text-align: center;
}

.raiseBtn {
    background: linear-gradient(360deg, black, transparent);
    border: none;
    border-radius: 50%;
    color: #fff !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    outline: none;
    text-align: center;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid gray;
    padding: 10px;
    cursor: pointer;
}

.mobile .raiseBtn {
    height: 12px;
    width: 12px;
    border: 1px solid #8b8b8f;
}

.mobile .rangeVal span {
  font-size: 16px;
}

.poker-root .mobile .table-footer-container {
    height: 13vh;
}
.poker-root .mobile .table-footer-container:has(.s-wrapper) {
    height: 70px;
}

.raiseChips input {
    color: #fff;
    font-size: 1.2vw !important;
    font-weight: bold;
    background: transparent;
    border: none;
    width: 100%;
    text-align: center;
    outline: none;
    background-image: url('../Images/BtnBg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.raiseChips {
    border-radius: 1vh;
    text-align: center;
    height: 2.5vw;
    line-height: 2.5vw;
    text-align: center;
}


.mobile .raiseChips input {
    color: #fff;
    font-size: 1.5vh !important;
    font-weight: bold;
    background: transparent;
    border: none;
    width: 100%;
    text-align: center;
    outline: none;
    background-image: url('../Images/BtnBg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}


.mobile .raiseChips {
    border-radius: 1vh;
    text-align: center;
    height: 2.5vh;
    line-height: 2.5vh;
    text-align: center;
}

.footerAction {
    /* width: 75%; */
    /*width: 14vw;*/
    width: 100%;
    text-align: center;
}

.footerAction:has(>.footerActionCheckBoxRight.game-checkbox) {
    width: 100%;
}

.footerActionBtn {
    display: flex;
    justify-content: flex-end;
    gap: 2px;
}

.mobile .footerActionBtn {
    justify-content: center;
}
.mobile .footerActionBtn.btActions {
    justify-content: end;
}

.footerActionCheckBoxLeft {
    position: absolute;
    bottom: 2px;
    width: 30%;
}

.footerActionCheckBoxRight {
    /* bottom: 10px;
    left: 30%; */
    width: 100%;
}

.footerActionCheckBoxRight .d-flex {
    justify-content: flex-end;
}

/* .mobile .footerActionCheckBoxRight .d-flex {
    justify-content: end;
}*/


.mobileView .footerActionCheckBoxLeft {
    position: absolute;
    bottom: 2px;
    width: 35%;
}

/* 
.mobileView .footerActionCheckBoxRight {
    position: absolute;
    bottom: 10px;
    left: 35%;
    width: 60%;
} */

.actionBtns {
    float: left;
}

.actionBar {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 40%;
    background-color: #ffffff7e;
    padding: 10px;
    padding-top: 15px;
    margin: 5px;
    border: 1px solid #fff;
    border-radius: 5px;
}

.footerBtn {
    padding: 2px 0;
    outline: none;
    /*margin: 0rem 0.2rem;*/
    /*transition: 0.5s;*/
    /*background-size: auto 200%;*/
    color: white;
    /*background: #454545;*/
    /*background: url(../Images/betbtn-bg.png);*/
    /*background-repeat: no-repeat;*/
    /*background-size: 100% 100%;*/
    /*border: 2px solid #6d6d6d;*/
    width: 100%;
    height: 40px;
    border-radius: 5px;
    /*line-height: normal;*/
    font-size: 20px;
    text-shadow: 1px 1px 2px #000;
    font-weight: 500;
    border: none;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.footerBtn .small-val{
    font-size: 12px;
    text-shadow: 1px 1px 2px #000;
    padding-top: 4px;
}
.mid-wrap .footerBtn {
    /*width: 32.5%;*/
    margin: 0 0 4px;
    height: 45px; 
}
.mobile .mid-wrap .mwr-left .footerBtn {
    margin: 2px 0;
}
/*
.mobile .footerBtn {
    font-size: 1em;
    border: 1px solid #8b8b8f;
    border-radius: 7px;
     
}*/
.footerBtn .br{
    display:block;
    /*padding-bottom: 4px;*/
}

/* .mobile .footerBtn {
    font-size: 1.2em;
} */

.raiseBtn:hover,
.raiseBtn:focus {
    color: var(--talk-yellow);
}


.footerBtn:not(:disabled):hover,
.footerBtn:not(:disabled):hover .small-val,
.footerBtn:not(:disabled):focus,
.chk-btn:hover label.form-control span {
    color: #ffd358;
    text-shadow: 1px 1px 2px #000000;
}

.mobile .footerBtn:not(:disabled):hover,
.mobile .footerBtn:not(:disabled):hover .small-val,
.mobile .footerBtn:not(:disabled):focus,
.mobile .chk-btn:hover label.form-control span {
    color: #fff;
    text-shadow: none;
}

.btActions .btAction.footerBtn:hover {
    border: 2px solid #ffd358 !important;   
}
.mobile .btActions .btAction.footerBtn:hover {
    border: none !important;   
}
.mwr-left .footerBtn:not(:disabled):hover,
.mwr-left .footerBtn:not(:disabled):focus {
    /*background: #a5698f !important;*/
    border: 2px solid #ffd358 !important;
}
.mobile .mwr-left .footerBtn:not(:disabled):hover,
.mobile .mwr-left .footerBtn:not(:disabled):focus {
    border: none !important;
}

.footerBtn:disabled{
    filter: brightness(0.7);
}

.mid-wrap .mwr-left button.footerBtn {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    /*border: 2px solid #c7c7c7;*/
    /*box-shadow: inset 1px 1px 5px #1e1e1e;*/
}
.mobile .mid-wrap .mwr-left button.footerBtn {
    /*width: calc(25vw - 4px);*/
    /*max-width: 75px;*/
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.mid-wrap .mwr-left button.footerBtn.btAllIn,
.mid-wrap .mwr-left button.footerBtn.btnDefault {
    /*background: #5b5b5b;*/
    background: url('../Images/menubtn/circle-btn-bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    box-shadow: 2px 2px 5px orange,-2px -2px 7px orange;
}

.mid-wrap .mwr-left button.footerBtn[disabled] {
    box-shadow: none;
    filter: brightness(0.5);
}
.footerBtn.btAction.btAllIn {
    /*background: #df0c43;*/
    background: url('../Images/old_actionbtn/allin-bg.png?v=001');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.mid-wrap .mwr-left button.footerBtn.btRaiseBet {
    /*background: #292929;*/
    background: url('../Images/old_actionbtn/raise-bg.png?v=001');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.mobile .footerBtn {
    font-size: 18px;
    /*padding: 0.5vh 1.2vh;*/
    width: 23%;
    height: 40px;
}

.btActions .btAction {
    /*width: calc(100% / 3);*/
    width: 102px;
    height: 50px;
    border-radius: 20px;
    margin-right: 4px;
}

.mobile .footerActionBtn.btActions .btAction {
    /*width: calc(25% - 4px);*/
    width: calc(33.33% - 4px);
    /*max-width: 88px;*/
    border-radius: 20px;
}

.poker-alpha.desktop .footerAction .footerActionCheckBoxRight.game-checkbox .d-flex {
    justify-content: flex-end;
}

.btActions .btAction.btAllIn {
    /*
    background: #df0c43;
    border: 2px solid #c7c7c7;
    box-shadow: inset 1px 1px 5px #1e1e1e;
    */
    background: url('../Images/old_actionbtn/allin-bg.png?v=001');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.btActions .btAction.btFold {
    /*
    background: #5b5b5b;
    border: 2px solid #c7c7c7;
    box-shadow: inset 1px 1px 5px #1e1e1e;
    */
    background: url('../Images/old_actionbtn/fold-bg.png?v=001');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.btActions .btAction.btCheck {
    /*
    background: linear-gradient(0deg, #bc00d2, #0247b9);
    border: 2px solid #c7c7c7;
    box-shadow: inset 1px 1px 5px #1e1e1e;
    */
    background: url('../Images/old_actionbtn/check-bg.png?v=001');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    line-height: 20px;
}
.btActions .btAction.btCheck.call {
    /*
    background: linear-gradient(0deg, #bc00d2, #0247b9);
    border: 2px solid #c7c7c7;
    box-shadow: inset 1px 1px 5px #1e1e1e;
    */
    background: url('../Images/old_actionbtn/call-bg.png?v=001');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    line-height: 20px;
}
.btActions .btAction.btRaiseBet {
    /*
    background: linear-gradient(0deg, #bc00d2, #e1015b);
    border: 2px solid #c7c7c7;
    box-shadow: inset 1px 1px 5px #1e1e1e;
    */
    background: url('../Images/old_actionbtn/raise-bg.png?v=001');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100% !important;
}
.btaction.raisebet-wrap {
    position: relative;
}

/* .mobileView .footerBtn {
    font-size: 1.7vw;
    padding: 0.5vw 1.2vw;
    outline: none;
    border: none;
    margin: 0rem 0.2rem;
    transition: 0.5s;
    background-size: auto 200%;
    color: white;
    background: transparent;
} */

.footerBtn:hover {
    /*background-position: bottom center;*/
    /* change the direction of the change here */
}

/* .btnSuccess {
    background-image: url('../Images/BtnBg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.btnDefault {
    background-image: url('../Images/BtnBg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.btnDanger {
    background-image: url('../Images/BtnBg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
} */




@keyframes slide-up {
    0% {
        transform: translate3d(0, 50%, 0);
    }

    50% {
        transform: translate3d(0, 0, 0);
    }

    100% {
        transform: translate3d(50%);
    }
}



.tablePot {
    position: absolute;
    text-align: center;
    color: #fff;
    font-weight: 500;
    padding: 0.2rem;
    border-radius: 15px;
    width: 7vw;
    font-size: 0.8vw;
}

.tablePot::before {
    content: "";
    background-color: #000;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    z-index: 1;
    border-radius: 15px;
}

/* 
.tablePot span {
    z-index: 1;
    opacity: 1;
    font-size: 0.8vw;
    vertical-align: middle;
} */


.mobileView .tablePot {
    top: 25%;
    width: 25%;
    font-weight: 500;
    font-size: 1.2vh;
}


.hiddenCard img {
    width: 100%;
    height: 100%;
    /* height: auto !important; */
}

.hiddenCard {
    position: absolute;
    z-index: 1;
    transition: transform 2s ease-in-out !important;
}

.hiddenCardShuffle {
    z-index: 11111;
}

.mobileView .mainhiddenCard {
    width: 8% !important;
}

.hiddenCard1 {
    transform: translateX(0%);
    -webkit-transform: translateX(0%);
}

.hiddenCard2 {
    transform: translateX(0%);
    -webkit-transform: translateX(-100%);
}

.hiddenCard3 {
    transform: translateX(0%);
    -webkit-transform: translateX(-200%);
}

.hiddenCard4 {
    transform: translateX(0%);
    -webkit-transform: translateX(-300%);
}

.hiddenCard5 {
    transform: translateX(0%);
    -webkit-transform: translateX(-400%);
}

.slide-in {
    animation: slide-in 0.8s forwards;
    -webkit-animation: slide-in 0.8s forwards;
}

@keyframes slide-in {
    100% {
        transform: translateX(0%);
    }
}

@-webkit-keyframes slide-in {
    100% {
        -webkit-transform: translateX(0%);
    }
}

.flip-card-inner {
    transition: transform 0.6s;
    transform-style: preserve-3d;
    height: 100%;
    width: 100%;
}


.flip-card-back {
    transform: rotateY(180deg);

}

.hiddenCard.flipped .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
    position: absolute;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    height: 100%;
    width: 100%;
}



@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}




.winnerType {
    position: absolute;
    top: 26%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    color: #fff;
    font-size: 1.1vw;
    padding: 0.2rem 0.2rem;
    background-color: #000;
    width: 25%;
}

.mobileView .winnerType {
    top: 35%;
    font-size: 1.2vh;
    width: 35%;
}

.winnerImage {
    animation: blink 1s;
    animation-iteration-count: infinite;
    position: absolute;
    bottom: -15%;
    right: -20%;
    width: 140% !important;
    height: auto !important;
    z-index: 1111111;
}

input[type=range] {
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
    /*border-radius: 20px;*/
    background-color: #5e5e5f;
}

input[type=range]:focus {
    outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 16px;
    cursor: pointer;
    animate: 0.2s;
    /*border-radius: 50px;*/
    -webkit-appearance: none;
}
input[type=range].vslider.raiseRangeSlide::-webkit-slider-runnable-track {
    height: 16px;
    /*background: linear-gradient(to right, #ffba00, #ffba00) 0% 0% / var(--raisepct) 100% no-repeat;*/
    background: linear-gradient(to right, #ffba00 var(--raisepct), transparent var(--raisepct));
    border-radius: 5px;
}

input[type=range]::-webkit-slider-thumb {
    /* box-shadow: 0px -0px 6px 6px #717592; */
    -webkit-appearance: none;
    appearance: none;
    height: 2em;
    width: 2em;
    border-radius: 23px;
    background-color: #ffd050;
    /* background-image: url('../Images/BtnBg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat; */
    cursor: pointer;
    margin-top: -0.5em;
}
input[type=range].buyinSlider {
    border-radius: 15px;
}
input[type=range].buyinSlider::-webkit-slider-thumb {
    -webkit-appearance: none;
	transition: all ease 100ms;
	width: 40px;
	height: 65px;
	/*height: 40px;*/
	/*margin-top: -12px;*/
	margin-top: -34px;
	margin-left: 1px;
	/*background-color: #000;*/
	background: url(../Images/addchip_s_warrow.png);
	background-size: contain;
	background-repeat: no-repeat;
	/*border: 2px solid var(--talk-yellow);*/
    border-radius: 50%;
}
/*
.sliderInput input[type=range].buyinSlider::-webkit-slider-thumb,
.sliderInput input[type=range].buyinSlider::-moz-range-thumb {
	-webkit-appearance: none;
	transition: all ease 100ms;
	width: 40px;
	height: 40px;
	margin-top: -10px;
	background: url(../Images/addchip_s.png);
	background-size: contain;
	background-repeat: no-repeat;
    border-radius: 50%;
}
*/

.mobile input[type=range] {
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
    /*border-radius: 20px;*/
}

.mobile input[type=range]:focus {
    outline: none;
}

.mobile input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 16px;
    cursor: pointer;
    animate: 0.2s;
    -webkit-appearance: none;
    border-radius: 20px;
    color: #d4ac09;
}

.mobile input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 12px;
    width: 12px;
    border-radius: 23px;
    cursor: pointer;
    margin-top: -2px;
    background: #ffd050;
}


.gameBtn {
    position: absolute;
    top: 1%;
    right: 0%;
    display: flex;
}

.iAmBackBtn {
    position: absolute;
    bottom: 1%;
    right: 1%;
    background: transparent;
    outline: none;
    display: flex;
    background-size: auto 200%;
    color: white;
    background: #222227;
    border: 2px solid #26262C;
    border-radius: 10px;
    padding: 1vw;
    padding-right: 1.5vw;
    color: #fff;
    white-space: nowrap;
    font-size: 1.5vw;
}

.mobileView .iAmBackBtn {
    padding: 1vw;
    font-size: 1.5vh;
}

.addChipBtn {
    background: transparent;
    outline: none;
    color: #fff;
    white-space: nowrap;
    background-color: #000;
    border: 1px solid #fff;
    border-radius: 10px;
    margin-right: 1rem;
}

.addChipBtn img {
    height: 3vw !important;
}

.mobileView .addChipBtn {
    margin-right: 0.5rem;
}

.mobileView .addChipBtn img {
    height: 2.5vh !important;
}

.gameLeaveBtn {
    background: transparent;
    border: none;
    outline: none;
}

.gameLeaveBtn img {
    border-radius: 50%;
    width: 7vh;
    height: 7vh;
}

.mobileView .gameLeaveBtn img {
    border-radius: 50%;
    width: 4vh;
    height: 4vh;
}

.overlay {
    position: absolute;
    bottom: 0;
    background: rgb(255, 255, 255);
    background: rgba(0, 0, 0, 0.5);
    /* Black see-through */
    color: #f1f1f1;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transition: .5s ease;
    opacity: 1;
    color: white;
    font-size: 20px;
    text-align: center;
    z-index: 11111;
}




/* ================================== */



.game-checkbox .form-control {
    background-color: transparent;
    border: none;
    font-size: 0.7em;
    line-height: 1.1;
    display: grid;
    grid-template-columns: 1em auto;
    gap: 0;
    color: #fff;
}



.game-checkbox input[type="checkbox"] {
    /*-webkit-appearance: none;*/
    /*appearance: none;*/
    background-color: var(--form-background);
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid currentColor;
    border-radius: 0.15em;
    /*transform: translateY(-0.075em);*/
    display: grid;
    place-content: center;
}

.game-checkbox input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    background-color: #ffffff;
}

.game-checkbox input[type="checkbox"]:checked::before {
    transform: scale(1);
}

.mobileView .game-checkbox .form-control {
    background-color: transparent;
    border: none;
    font-size: 1.2vh;
    line-height: 1.1;
    display: grid;
    grid-template-columns: 1em auto;
    gap: 1vw;
    color: #fff;
    width: auto !important;
    padding: 0.5rem;
}

.ReactModal__Overlay--after-open {
    background-color: rgba(0, 0, 0, 0.75) !important;
}

.playerActionName {
    animation-iteration-count: infinite;
    animation-duration: 1s;
    animation-direction: alternate;
    font-weight: 700;
    color: #fff;
    font-size: 1.5vw;
}




.range-slider-wrapper .range-slider {
    position: relative;
    z-index: 1;
}

.range-slider-wrapper .vertical {
    transform: rotate(90deg);
    transform-origin: left top;
}

.labels {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 2px;
    justify-content: space-between;

}

.labels div {
    height: 28px;
    line-height: normal;
    display: flex;
    align-items: center;

}

.labels div span {
    display: block;
    width: 20px;
    height: 2px;
    background: black;
    margin-right: 10px;
}

input[type="range"].range-slider {
    width: 100%;
    background-color: transparent;
    -webkit-appearance: none;
}

input[type="range"].range-slider:focus {
    outline: none;
}

input[type="range"].range-slider::-webkit-slider-runnable-track {
    background: #481e4c;
    border: 1px solid #000000;
    border-radius: 10px;
    width: 100%;
    height: 13px;
    cursor: pointer;
}

input[type="range"].range-slider::-webkit-slider-thumb {
    margin-top: -7.5px;
    width: 26px;
    height: 26px;
    background: #db005e;
    border: 0px solid rgba(0, 0, 0, 0);
    border: 0;
    border-radius: 15px;
    cursor: pointer;
    -webkit-appearance: none;
}

input[type="range"].range-slider:focus::-webkit-slider-runnable-track {
    background: #4b1f50;
}

input[type="range"].range-slider::-moz-range-track {
    background: #481e4c;
    border: 1px solid #000000;
    border-radius: 10px;
    width: 100%;
    height: 13px;
    cursor: pointer;
}

input[type="range"].range-slider::-moz-range-thumb {
    width: 26px;
    height: 26px;
    background: #db005e;
    border: 0px solid rgba(0, 0, 0, 0);
    border: 0;
    border-radius: 15px;
    cursor: pointer;
}

input[type="range"].range-slider::-ms-track {
    background: transparent;
    border-color: transparent;
    border-width: 6.5px 0;
    color: transparent;
    width: 100%;
    height: 13px;
    cursor: pointer;
}

input[type="range"].range-slider::-ms-fill-lower {
    background: #451d48;
    border: 1px solid #000000;
    border-radius: 20px;
}

input[type="range"].range-slider::-ms-fill-upper {
    background: #481e4c;
    border: 1px solid #000000;
    border-radius: 20px;
}

input[type="range"].range-slider::-ms-thumb {
    width: 26px;
    height: 26px;
    background: #db005e;
    border: 0px solid rgba(0, 0, 0, 0);
    border: 0;
    border-radius: 15px;
    cursor: pointer;
    margin-top: 0px;
    /*Needed to keep the Edge thumb centred*/
}

input[type="range"].range-slider:focus::-ms-fill-lower {
    background: #481e4c;
}

input[type="range"].range-slider:focus::-ms-fill-upper {
    background: #4b1f50;
}

/*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
how to remove the virtical space around the range input in IE*/
@supports (-ms-ime-align: auto) {

    /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
    input[type="range"].range-slider {
        margin: 0;
        /*Edge starts the margin from the thumb, not the track as other browsers do*/
    }
}

.gameBtn .userTime {
    color: #fff;
    font-size: 1.2vw;
    margin-right: 15px;
}

.mobileView .gameBtn .userTime {
    color: #fff;
    font-size: 1.5vh;
    margin-right: 15px;
}



























/* New Dev */


.gameTableHeader {
    padding: 1vw;
    padding-bottom: 0.5vw;
    display: flow-root;
}

.gameTableHeader .backIcon {
    /* width: 2.5vw;
    height: 2.5vw; */
    vertical-align: middle;
    align-items: center;
    padding: 0px;
    margin: 0px;
}


.gameTableHeader .leftPanel {
    display: flex;
    vertical-align: middle;
    align-items: center;
    float: left;
    display: flex;
    gap: 1vw;
    vertical-align: middle;
    align-items: center;
}

.gameTableHeader .rightPanel {
    display: flex;
    vertical-align: middle;
    align-items: center;
    float: right;
    display: flex;
    gap: 1vw;
    vertical-align: top;
    align-items: center;
}

.mobileView .gameTableHeader .rightPanel {
    display: flex;
    vertical-align: middle;
    align-items: center;
    float: right;
    display: flex;
    gap: 1vh;
    vertical-align: top;
    align-items: center;
}

.gameTableHeader .backIcon button,
.gameTableHeader .backIcon img {
    width: 100%;
    height: 100%;
}


.mobileView .gameTableHeader {
    padding: 0.5vh;
    padding-bottom: 0.5vw;
    display: flow-root;
}

.mobileView .gameTableHeader .backIcon {
    width: 3vh;
    height: 3vh;
    vertical-align: middle;
    align-items: center;
    padding: 0px;
    margin: 0px;
    margin-top: 7px;
}

.plainBtn {
    background-color: transparent;
    outline: none;
    border: none;
    padding: 0px;
    margin: 0px;
}

.playerLeftHours {
    background-image: url('../Images/Icons/TimeBG.png');
    background-size: 100% 100%;
    color: #fff;
    display: flex;
    padding: 5px 10px;
    gap: 1vw;
    align-items: center;
    margin-right: 1em;
}

.playerLeftHours .align-item-center {
    align-items: center;
    gap: 5px;
}

.playerLeftHours .timeIcon {
    width: 18px;
    height: 18px;
}




.mobileView .playerLeftHours {
    background-image: url('../Images/Icons/TimeBG.png');
    background-size: 100% 100%;
    color: #fff;
    display: flex;
    padding: 2.5px 5px;
    gap: 1vw;
    align-items: center;
}

.mobileView .playerLeftHours .align-item-center {
    align-items: center;
    gap: 5px;
}

.mobileView .playerLeftHours .timeIcon {
    width: 20px;
    height: 20px;
}

.mobileView .playerLeftHours .buyHourBtn {
    width: 30px;
    height: 30px;
}







.tableDetails {
    /* background-image: linear-gradient(to right, rgba(255, 0, 0, 0), rgb(130, 37, 244)); */
    background-color: #24242c;

    position: absolute;
    right: 0;
    margin-top: 0.5vw;
    text-align: right;
    padding: 0.2vw 1vw;
    padding-left: 1.5vw;
    border-bottom-left-radius: 50px;
    border-top-left-radius: 50px;
    border: 1px solid #242234;
}

.tableDetails .color-1 {
    color: #fff;
}

.tableDetails .color-2 {
    color: #ffffff9d;
}

.tableDetails p {
    margin-bottom: 0px;
    font-size: 0.8vw;
}

.mobileView .tableDetails {
    margin-top: 0.5vh;
    margin-bottom: 0px;
    font-size: 1.5vh;
}

.mobileView .tableDetails p {
    margin-bottom: 0px;
    font-size: 1.5vh;
}

[data-title]:hover:after {
    opacity: 1;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
}

[data-title]:after {
    content: attr(data-title);
    background-image: url('../Images/Icons/TimeBG.png');
    background-size: 100% 100%;
    color: #74747C;
    position: absolute;
    padding: 5px 10px;
    bottom: -1.6em;
    left: 0em;
    white-space: nowrap;
    opacity: 0;
    z-index: 99999;
    visibility: hidden;
}

[data-title] {
    position: relative;
}

.muteTitle {
    font-size: 0.7vw;
    color: #74747C;
}


.playerAction {
    color: #8025F3;
    position: absolute;
    z-index: 1;
    bottom: -20%;
    width: 80%;
    right: 10%;
    bottom: -95%;
    text-align: center;
    z-index: 11111111;
    border-radius: 10px;
    font-size: 0.9vw;
    font-weight: 700;
}

.playerAction::before {
    content: "";
    background-color: #fff;
    border-radius: 5px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    z-index: -1;
}


.mobileView .playerAction {
    font-size: 1.2vh;
    bottom: -100%;
}


.playerDetails {
    color: #fff;
    padding: 5px 0px 2px 0px;
    position: absolute;
    z-index: 1;
    bottom: -53%;
    width: 160%;
    right: -30%;
    border-radius: 10px;
    text-align: center;
}

.playerDetails::before {
    content: "";
    background-image: url('../Images/Icons/PlayerInfoBg.png');
    background-size: 100% 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    z-index: -1;
}

.playerDetails p {
    position: relative;
    z-index: 1;
    opacity: 1;
    font-size: 0.8vw;
    margin-top: 0.150vw;
    margin-bottom: 0vw;
}

.playerDetails .playeChips {
    color: #74747C;
}

.playerDetails .dealerIconRight {
    position: absolute;
    top: -30%;
    right: -10%;
    width: 2vw;
    height: 2vw;
}

.playerDetails .dealerIconLeft {
    position: absolute;
    top: -30%;
    left: -10%;
    width: 2vw;
    height: 2vw;
}

.displayForcly {
    z-index: 111111;
}



/* .countdownContainer {
    border: 1px solid black;
    border-radius: 3px;
    width: 100px;
    height: 30px;
    display: block;
    background: linear-gradient(to left, black 50%, white 50%);
    background-size: 200% 100%;
    z-index: 11111;
    background-position: right bottom;
    transition: all 30s ease-out;
} */

.countdownContainer {
    width: 100%;
    height: 100%;
    border-radius: 0.5em;
    color: #fff;
    padding: 5px 0px 2px 0px;
    text-align: center;
    display: block;
    border-radius: 0.5em;
    color: #fff;
    padding: 5px 0px 2px 0px;
    text-align: center;
    display: block;
    /* border-radius: 0.5em;
    color: #fff;
    padding: 5px 0px 2px 0px;
    position: absolute;
    bottom: 0%;
    width: 100%;
    height: 100%;
    right: 0%;
    text-align: center;
    display: block;
    background-position: right bottom; */
    /* transition: 'background-position ' */
}

.countdownContainer.timer {
    background: linear-gradient(to right, #7825F3 50%, transparent 0%);
    background-size: 200% 100%;
    background-position: right bottom;
}

.countdownContainer::before {
    content: "";
    /* background-image: url('../Images/Icons/PlayerInfoBg.png'); */
    background-size: 100% 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    z-index: -1;
}

.countdownContainer p {
    position: relative;
    z-index: 1;
    opacity: 1;
    font-size: 0.8vw;
    margin-top: 0.150vw;
    margin-bottom: 0vw;
}

.countdownContainer .playeChips {
    color: #74747C;
}

.countdownContainer .dealerIconRight {
    position: absolute;
    top: -30%;
    right: -10%;
    width: 2vw;
    height: 2vw;
}

.playerDetails .dealerIconLeft {
    position: absolute;
    top: -30%;
    left: -10%;
    width: 2vw;
    height: 2vw;
}



/* .countdownContainer {
    display: "flex";
    justify-content: "center";
    align-items: "center";
    position: "relative";
    margin: "auto";
}

.countdownContainer svg {
    position: "absolute";
    top: 0;
    left: 0;
    width: "100%";
    height: "100%";
    transform: "rotateY(-180deg) rotateZ(-90deg)";
    overflow: "visible";
} */


/* transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
 */
/* width: 100%;
    height: 100%;
    position: absolute;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform-style: preserve-3d;
    will-change: transform; */

.winner-profile {
    background-color: transparent;
    border-radius: 50px;
    border: 1px solid #7825F3;
    box-shadow: 0 0 20px rgb(120, 37, 243);
    animation: winner-animation 0.5s ease-in-out infinite;
}

/* Profile image */
.profile-image {
    border-radius: 50%;
    object-fit: cover;
}

@keyframes winner-animation {
    0% {
        /* transform: scale(1); */
        box-shadow: 0 0 20px rgba(120, 37, 243, 0.5);
    }

    50% {
        /* transform: scale(1.08); */
        box-shadow: 0 0 30px #fff;
    }

    100% {
        /* transform: scale(1); */
        box-shadow: 0 0 20px rgba(120, 37, 243, 0.5);
    }
}

.timeLeftFont {
    /* font-family: "digital-clock-font"; */
}

.headerBtn {
    border-radius: 50%;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.8);
}

.lobbyTimeIcon {
    height: 100%;
}

.chatBtnBox {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 1vw;
    padding-bottom: 0.5vw;
    z-index: 1111111;
}



.chatBar {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1111111;
    top: 0;
    left: 0;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 20px;
    background-color: #000000d0;
}

.chatBar a {
    display: block;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    transition: 0.3s;
}

.chatBar a:hover {
    color: #F1F1F1;
}

.chatBar .sideBarClosebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 35px;
    margin-left: 50px;
}

.chat-heading {
    color: #fff;
    font-size: 1em;
    text-align: center;
}

.chatBar hr {
    border: 1px solid #8025F3;
}


.tableInfoSideBar {
    height: 100%;
    /*width: 0;*/
    width: 20.5%;
    position: fixed;
    z-index: 1111111;
    top: 0;
    right: 0;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 20px;
    background-color: #151515;
    transform: translateX(100%);
}
.mobile.tableInfoSideBar {
    width: 100%;
}

.tableInfoSideBar .sideBarClosebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 35px;
    margin-left: 50px;
}

.tableInfoSideBar a {
    display: block;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    transition: 0.3s;
}

.tableInfoSideBar a:hover {
    color: #F1F1F1;
}

.tableInfoSideBar hr {
    border: 1px solid #8025F3;
}

.tableInfoSideBar .tableInfoTitle {
    color: #ffffff;
    font-weight: 500;
    opacity: 0.7;
}

.tableInfoSideBar{
    font-weight: 500;
}

.msger * {
    overflow-anchor: none;
}

.msger {
    overflow-anchor: auto;
    height: 1px;
}


:root {
    --body-bg: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
    --msger-bg: #fff;
    --border: 2px solid #ddd;
    --left-msg-bg: #242424;
    --right-msg-bg: #8025F3;
}

.msger {
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    width: 100%;
    height: 85%;
}

.msger-chat {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
}

.msger-chat::-webkit-scrollbar {
    width: 6px;
}

.msger-chat::-webkit-scrollbar-track {
    background: #ddd;
}

.msger-chat::-webkit-scrollbar-thumb {
    background: #686868;
}

.msg {
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
}

.msg-text {
    color: #fff;
    font-size: 0.8em;
}

.msg:last-of-type {
    margin: 0;
}

.msg-img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    background: #ddd;
    border-radius: 50%;
}

.msg-bubble {
    max-width: 100%;
    padding: 5px 10px;
    border-radius: 10px;
    background: var(--left-msg-bg);
}

.msg-info-name {
    color: #fff;
    font-size: 0.7em;
    font-weight: bold;
    text-align: left;
}

.right-msg .msg-info-name {
    text-align: right;
}

.msg-info-time {
    font-size: 0.85em;
}

.left-msg .msg-bubble {
    border-bottom-left-radius: 0;
}

.right-msg {
    flex-direction: row-reverse;
}

.right-msg .msg-bubble {
    background: var(--right-msg-bg);
    color: #fff;
    border-bottom-right-radius: 0;
}

.right-msg .msg-img {
    margin: 0 0 0 10px;
}

.msger-inputarea {
    background: #242424;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.msger-input-box {
    display: flex;
    padding: 10px;
    background: #242424;
    width: 100%;
}

.msger-input {
    padding: 5px;
    border: none;
    border-radius: 3px;
    font-size: 1em;
}

.msger-input {
    flex: 1;
    background: transparent;
    border: none;
    outline: none;
    color: #fff;
}

.msger-send-btn {
    cursor: pointer;
}

.loadingLogo {
    height: 10em;
}

.mobileView .loadingLogo {
    height: 7em;
}

.extOnGame {
    position: absolute;
    /*bottom: -9%;*/
    bottom: 3%;
    width: 100%;
    text-align: center;
    z-index: 100;
    /*left: 60px;*/
    left: -9%;
}

/*.win.extOnGame {*/
/*    bottom: -81px !important;*/
/*}*/

.mobile .extOnGame {
    bottom: -11%;
    /*left: 30%;*/
    left: 15%;
    z-index: 1;
}

.extOnGame > span {
    background: #ffd050;
    color: black;
    padding: 4px 16px;
    border-radius: 20px;
    font-weight: 600;
    font-size: 1em;
    white-space: nowrap;
}

.handHistory .playerProfileInfo .table.p-lb-table.table-borderless tbody > tr > td:nth-child(3) {
   font-weight: 600 !important;
   color: #ffd050 !important;
}

/*
.btActions button.footerBtn {
    background: url(../Images/betbtn-bg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    text-shadow: 1px 1px 2px #000;
    height: 45px;
}
*/

.raiseBtn-wrap {
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0.2rem;
}

.mobile .raiseBtn-wrap {
    width: 10%;
}
.rangeWrap {
    width: 100%;
    /*margin: 0 5px;*/
    height: 0.5rem;
}

.mobile .rangeWrap {
    background: #000;;
}
.rangeVal {
    width: 56%;
}
.mobile .rangeVal {
    width: 30%;
}
.mwr-right .rangeVal {
    padding: 0 10px;
    width: 140px;
    box-shadow: inset 1px 1px 5px #1e1e1e;
    /*background: linear-gradient(180deg, #000, #313030);*/
    background: linear-gradient(180deg,#4c4848,#292929);
    border-radius: 14px;
    font-size: 16px;
    /*box-shadow: inset 0 2px 6px #0a0a0a, inset -2px -2px 8px #000;*/
    /*border: 1px solid #595959;*/
    margin-top: 8px;
    position: absolute;
    top: auto;
    bottom: 18%;
    right: 10px;
}
.mobile .mwr-right .rangeVal {
    width: 120px;
    top: unset;
    bottom: 2px;
    left: 0;
    transform: translateX(-50%);
}

/*
.raiseRangeSlide {
    width: 100%;
    margin: 0 !important;
    height: 0.5rem;
    background: transparent !important;
}

input[type=range].raiseRangeSlide::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 10px;
    width: 20px;
    border-radius: 4px;
    background-color: #ffd050;
    cursor: pointer;
    background: linear-gradient(180deg, rgba(247,229,181,1) 0%, rgba(223,185,87,1) 27%, rgba(207,188,124,1) 44%, rgba(207,188,124,1) 46%, rgba(245,231,182,1) 53%, rgba(239,224,175,1) 55%, rgba(204,185,127,1) 59%, rgba(117,91,39,1) 85%);
    border: 1px solid gray;
}
*/

.menu_top_close-icon {
    content: "";
    /*background-image: url(../Images/close-icon.svg);
    background-repeat: no-repeat;
    background-position: center;*/
    width: 70px;
    height: 70px;
    cursor: pointer;
}

.mobile .menu_top_close-icon {
    width: 45px;
    height: 45px;
}

.cashier-box .table .histCard-wrap {
    justify-content: center;
}

.game-page .desktop .TableIndicator__content .player-status {
    font-size: 1.1rem;
}


.menu-btn {
    /*width: 80px;*/
    width: calc((100% / 5) - 4px);
    /*height: 80px;*/
    height: auto;
    background: url('../Images/menubtn/square-btn-bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 9px 0;
}
.side_menu_btns .menu-btn.exit-btn {
    position: relative;
}
.side_menu_btns .menu-btn.exit-btn .exitGame-txt {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0000008f;
    font-size: 14px;
}
.side_menu_btns .menu-btn.exit-btn .exitGame-txt > span {
    text-align: center;
}

.menu-btn > img.menubtn-img {
    width: 90%;
    max-width: 80px;
    height: auto;
}
.mobile .menu-btn > img.menubtn-img {
    width: 67%;
}
.menu-btn > img.menubtn-img.inv-col {
    filter: invert(1);
}

.side_table_info > div {
    width: calc(50% - 2px);
    display: flex;
    flex-direction: column;
}

.side_table_info {
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
}

.side_table_info .tableInfoTitle {
    color: #ffce51;
    text-align: center;
    background: #413a34;
    padding: 4px 0;
    font-size: 14px;
}

.tableInfoVal {
    padding: 5px 0;
    text-align: center;
    background: #67625e;
    min-height: 34px;
}

.side_table_name {
    text-align: center;
    margin: 5px 0;
}
.side_header {
	display: flex;
    align-items: center;
    justify-content: space-between;
}
.side_logo {width: 60%; height: auto;}
.side_menu_btns {
	display: flex;
    gap: 4px;
    justify-content: center;
    margin: 20px 0;
    flex-wrap: wrap;
}
.side_table_history {
    margin-top: 10px;
}
.side_table_history .table_hist_title {
    background: #ffce51;
    color: #000;
    padding: 4px 0;
    width: 100%;
    text-align: center;
    font-weight: 600;
}
.side_table_history .shistory_table {
    width: 100%;
}
.side_table_history .shistory_table thead tr th {
    font-size: 12px;
    text-align: center;
}
#mySidenav.tableInfoSideBar .sidebar_close {
    background: url('../Images/menubtn/circle-btn-bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin-right: 10px;
}
#mySidenav.tableInfoSideBar .sidebar_close > img {
    width: 60%;
    height: auto;
}

.rounded-btn {
    border-radius: 20px !important;
}

.confirm-btn {
    background: linear-gradient(180deg, #f5b506 0%, #ffb54b 50%, #c18c36 93%) !important;   
}
.cancel-btn {
    background: linear-gradient(180deg, #fd9347 0%, #af601b 50%, #79401f 93%) !important;   
}

.dialog-title {
    font-weight: 800;
    font-size: 25px;
    color: #434343;
    text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4), 0px 8px 13px rgba(0, 0, 0, 0.1), 0px 18px 23px rgba(0, 0, 0, 0.1);
}

.glowing-border {
    border: 1px solid #ffd0506e !important;
    box-shadow: 2px 2px 15px #ffd05075, -2px -2px 15px #ffd05075;
}

