.mobileView .infotableFixHead>p.dateTime {
    color: #bbb;
    margin-bottom: 0;
    text-align: center;
}

.mobileView .tournamentGame .details-sidebar,
.mobileView .tournamentGame .sectionBox .tableFixHead .btn.p-hd-fill-inner-btn {
    background-color: #282a30;
    font-size: 0.8rem !important;
}

.mobileView .tournamentGame .details-sidebar {
    padding: 0;
}
