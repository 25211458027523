:root {
    --talk-yellow: #ffd050;
    --r-player-bar-mobile-w: 8.286em;
    --r-player-bar-mobile-h: 3.496em;
    --r-player-bar-tablet-w: 7em;
    --r-player-bar-tablet-h: 3em;
}





.poker-root .r-scene-container .scene-container-content {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

.poker-root .mobile .game-container .game-container-elements {
    position: relative;
    width: 100%;
    height: 100%;
}

.poker-root .r-scene-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.poker-root .poker-alpha.mobile .r-scene-container .scene-container-content .scene-background-table {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.poker-root .poker-alpha.mobile.portrait .r-scene-container .scene-container-content .scene-background-table .table-shadow {
    /* background-image: url('../Images/Table/Mobile/table-shadow.png'); */
    background-size: 82% 92%;
}

.poker-root .poker-alpha.portrait .r-scene-container .scene-container-content .scene-background-table .table-shadow {
    background-size: 40em;
    background-position: 48% 55%;
}

.poker-root .poker-alpha.mobile .r-scene-container .scene-container-content .scene-background-table .skin-section {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
}

/* .poker-root .poker-alpha.portrait .r-scene-container .scene-container-content .scene-background-table .background-table-border.theme-default {
    background-image: url('../Images/Table/Mobile/table-border.png');
} */

.poker-root .poker-alpha.portrait .r-scene-container .scene-container-content .scene-background-table .background-table-border.theme-default {
    background-position: 48% 47%;
    background-size: 35em;
}

.poker-root .poker-alpha.mobile .r-scene-container .scene-container-content .scene-background-table .skin-section {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
}

/* .poker-root .poker-alpha.mobile.portrait .r-scene-container .scene-container-content .scene-background-table .background-table-felt.felt-default {
    background-image: url('../Images/Table/Mobile/table-screen-green.png');
} */
.poker-root .poker-alpha.mobile.portrait .r-scene-container .scene-container-content .scene-background-table .background-table-felt {
    background-size: 100%;
}

.mobileView .extOnGame {
    bottom: -68px;
    left: 114px;
}

.poker-root .poker-alpha.mobile.portrait .r-scene-container .scene-container-content .scene-background-table .background-table-felt {
    background-size: 100%;
}

.poker-root .poker-alpha.mobile.portrait .r-scene-container .scene-container-content .scene-background-table .background-table-felt.felt-default {
    background-image: url('../Images/Table/Mobile/table-m-default.png');
}

.poker-root .poker-alpha.mobile.portrait .r-scene-container .scene-container-content .scene-background-table .background-table-felt.felt-gold {
    background-image: url('../Images/Table/Mobile/table-screen-darkred.png');
}

.poker-root .poker-alpha.mobile.portrait .r-scene-container .scene-container-content .scene-background-table .background-table-felt.felt-blue {
    background-image: url('../Images/Table/Mobile/table-m-blue.png');
}

.poker-root .poker-alpha.mobile.portrait .r-scene-container .scene-container-content .scene-background-table .background-table-felt.felt-red {
    background-image: url('../Images/Table/Mobile/table-m-red.png');
}

.poker-root .poker-alpha.mobile.portrait .r-scene-container .scene-container-content .scene-background-table .background-table-felt.felt-gray {
    background-image: url('../Images/Table/Mobile/table-m-gray.png');
}

.poker-root .poker-alpha.mobile.portrait .r-scene-container .scene-container-content .scene-background-table .background-table-felt.felt-purpal {
    background-image: url('../Images/Table/Mobile/table-m-violet.png');
}

.poker-root .poker-alpha.portrait .r-scene-container .scene-container-content .scene-background-table .background-table-felt {
    background-position: 48% 44%;
    background-size: 34em;
}

.poker-root .poker-alpha.mobile .r-scene-container .scene-container-content .scene-background-table .skin-section {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
}

.poker-root .mobile .table-container .screen-wrapper .screen-content {
    font-size: 100%;
}

/* .poker-root .poker-alpha.mobile .r-seat {
    border: 1px solid red;
} */

.poker-root .poker-alpha.mobile .r-seat.seat-template-1 {
    left: 66%;
    top: 0;
}

.poker-root .poker-alpha.mobile .r-seat.seat-template-2 {
    left: 76.5%;
    top: 27%;
}

.poker-root .poker-alpha.mobile .r-seat.seat-template-3 {
    left: 78.5%;
    top: 54.5% !important;
}

.poker-root .poker-alpha.mobile .r-seat.seat-template-4 {
    left: 77.5%;
    top: 81.5%;
}

.poker-root .poker-alpha.mobile .r-seat.seat-template-1:has(>.panel.free-seat), 
.poker-root .poker-alpha.mobile .r-seat.seat-template-9:has(>.panel.free-seat) {
    top: 6%;
}

.poker-root .poker-alpha.mobile .r-seat.seat-template-5:has(>.panel.button.free-seat) {
    left: 37.5% !important;
    top: 100%;
}

.poker-root .poker-alpha.mobile .r-seat.seat-template-5:has(>.panel.r-player) {
    left: 56.5% !important;
    top: 100% !important;
}

.poker-root .poker-alpha.mobile .r-seat.seat-template-2:has(>.panel.free-seat), .poker-root .poker-alpha.mobile .r-seat.seat-template-8:has(>.panel.free-seat) {
    top: 31%;
}

.poker-root .poker-alpha.mobile .r-seats {
   height: 93%;
}

@media (max-height: 780px) and (max-width: 370px) {
    .poker-root .mobile.poker-alpha .r-seat.seat-template-5:has(>.panel.r-player) {
        top: 98.5% !important;
    }
    
    .poker-root .poker-alpha.mobile .r-table-chips-layer .r-player-bet.seat-template-5 {
        left: 47%;
        top: 87%;
    }
    
    .player_rank:not(:empty) {
            bottom: 97% !important;
            right: 60px !important;
    }

}

.poker-root .poker-alpha.mobile .r-seat.seat-template-6 {
    left: 1%;
    top: 81.5%;
}

/*.poker-root .poker-alpha.mobile .r-seat.seat-template-1 .panel.r-player .r-player-bar {*/
/*    left: -65% !important;*/
/*    top: 25%;*/
/*}*/
/*.poker-root .poker-alpha.mobile .r-seat.seat-template-3 .panel.r-player .r-player-bar {*/
/*    left: -62% !important;*/
/*    top: 25%;*/
/*}*/
.poker-root .poker-alpha.mobile .r-seat.left-direction .panel.r-player .r-player-bar {
    left: 0 !important;
    top: 65%;
    z-index: 3;
}
.poker-root .poker-alpha.mobile .poker-game .r-seat.seat-template-1 .r-player-bar {
    /*left: 10% !important;*/
}
.poker-root .poker-alpha.mobile .poker-game .r-seat.seat-template-2 .r-player-bar,
.poker-root .poker-alpha.mobile .poker-game .r-seat.seat-template-3 .r-player-bar {
    left: 0 !important;
}
.poker-root .poker-alpha.mobile .poker-game .r-seat.seat-template-4 .r-player-bar {
    /*left: -15% !important;*/
}

.poker-root .poker-alpha.mobile .r-seat.right-direction .panel.r-player .r-player-bar {
    /*left: 6%;*/
    top: 65%;
    z-index: 3;
    left: 0;
}

.poker-root .poker-alpha.mobile .r-seat.seat-template-6 .panel.r-player .r-player-bar {
    /*left: 14%;*/
}

.poker-root .poker-alpha.mobile .r-player-bar .bar-text.top-line,
.poker-root .poker-alpha.mobile .r-player-bar .bar-text.bottom-line {
    /*padding: 0.2rem 0.5rem !important;*/
    padding: 0 !important;
    text-align: center !important;
    /*padding-right: 0.5rem !important;*/
    /*padding-left: 0.5rem !important;*/
    /*border: 2px solid gray;*/
    border: none;
    font-size: 1em;
    background-color: transparent;
}
.poker-root .poker-alpha.mobile .r-player-bar .bar-text.bottom-line {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border-top: 0 !important;
}

.poker-root .poker-alpha.mobile .r-seat.seat-template-7 {
    left: 0;
    top: 54.5%;
}

.poker-root .poker-alpha.mobile .r-seat.seat-template-3:has(>.panel.free-seat),
.poker-root .poker-alpha.mobile .r-seat.seat-template-7:has(>.panel.free-seat) {
    top: 55.5%;
}

.poker-root .poker-alpha.mobile .r-seat.seat-template-8 {
    left: 2.5%;
    top: 27%;
}

.poker-root .poker-alpha.mobile .r-seat.seat-template-9 {
    left: 12.25%;
    top: 0;
}

.poker-root .poker-alpha.mobile .r-seat.seat-template-1 .free-seat {
    left: auto;
    top: 2em;
    right: 1em;
}

.poker-root .poker-alpha.mobile .r-seat.seat-template-2 .free-seat {
    left: auto;
    top: 1em;
    right: 2em;
}

.poker-root .poker-alpha.mobile .r-seat.seat-template-3 .free-seat {
    left: auto;
    top: 1em;
    right: 0.5em;
}

.poker-root .poker-alpha.mobile .r-seat.seat-template-4 .free-seat {
    left: auto;
    top: 2em;
    right: 1em;
}

.poker-root .poker-alpha.mobile .r-seat.seat-template-5 .free-seat {
    left: auto;
    top: 4em;
    right: 1em;
}

.poker-root .poker-alpha.mobile .r-seat.seat-template-6 .free-seat {
    left: auto;
    top: 2em;
    right: 2em;
}

.poker-root .poker-alpha.mobile .r-seat.seat-template-7 .free-seat {
    left: auto;
    top: 1em;
    right: 2em;
}

.poker-root .poker-alpha.mobile .r-seat.seat-template-8 .free-seat {
    left: auto;
    top: 1em;
    right: 1em;
}

.poker-root .poker-alpha.mobile .r-seat.seat-template-9 .free-seat {
    left: auto;
    top: 2em;
    right: 1em;
}

.poker-root .poker-alpha.mobile .r-dealer-layer .dealer-target, .poker-root .poker-alpha.mobile .r-dealer-layer .dealer-button {
    width: 1.66em;
    height: 1.5em;
}

.poker-root .poker-alpha.mobile .poker-game .r-dealer-layer .dealer-target.seat-template-1 {
    top: 20%;
    right: 30%;
    /*
    top: 9%;
    right: 32%;
    */
}

.poker-root .poker-alpha.mobile .poker-game .r-dealer-layer .dealer-target.seat-template-2 {
    top: 35%;
    right: 25%;
    /*
    top: 39%;
    right: 21%;
    */
}

.poker-root .poker-alpha.mobile .poker-game .r-dealer-layer .dealer-target.seat-template-3 {
    left: 76%;
    top: 58%;
    /*
    left: 76%;
    top: 65%;
    */
}

.poker-root .poker-alpha.mobile .poker-game .r-dealer-layer .dealer-target.seat-template-4 {
    left: 74%;
    top: 77%;
    /*
    left: 76%;
    top: 90%;
    */
}

.poker-root .poker-alpha.mobile .poker-game .r-dealer-layer .dealer-target.seat-template-5 {
    left: 40%;
    top: 82%;
    /*
    left: 63%;
    top: 102%;
    */
}

.poker-root .poker-alpha.mobile .poker-game .r-dealer-layer .dealer-target.seat-template-6 {
    left: 21%;
    top: 77%;
    /*
    left: 20%;
    top: 85%;
    */
}

.poker-root .poker-alpha.mobile .poker-game .r-dealer-layer .dealer-target.seat-template-7 {
    left: 21%;
    top: 58%;
    /*
    left: 18%;
    top: 60%;
    */
}

.poker-root .poker-alpha.mobile .poker-game .r-dealer-layer .dealer-target.seat-template-8 {
    left: 25%;
    top: 35%;
    /*
    left: 21%;
    top: 34%;
    */
}

.poker-root .poker-alpha.mobile .poker-game .r-dealer-layer .dealer-target.seat-template-9 {
    left: 30%;
    top: 20%;
    /*
    left: 31%;
    top: 9%;
    */
}
.poker-root .poker-alpha.mobile .r-table-chips-layer .r-player-bet.right-direction {
    left: 20%;
}
.poker-root .poker-alpha.mobile .r-table-chips-layer .r-player-bet.seat-template-1 {
    top: 15%;
    left: 54%;
}

.poker-root .poker-alpha.mobile .r-table-chips-layer .r-player-bet.seat-template-2 {
    left: 61%;
    top: 39%;
}

.poker-root .poker-alpha.mobile .r-table-chips-layer .r-player-bet.seat-template-3 {
    left: 64%;
    top: 62%;
}

.poker-root .poker-alpha.mobile .r-table-chips-layer .r-player-bet.seat-template-4 {
    left: 64%;
    top: 80%;
}

.poker-root .poker-alpha.mobile .r-table-chips-layer .r-player-bet.seat-template-5 {
    left: 38%;
    top: 85%;
}

.poker-root .poker-alpha.mobile .r-table-chips-layer .r-player-bet.seat-template-6 {
    left: 18.75%;
    top: 80%;
}

.poker-root .poker-alpha.mobile .r-table-chips-layer .r-player-bet.seat-template-7 {
    left: 20%;
    top: 62%;
}

.poker-root .poker-alpha.mobile .r-table-chips-layer .r-player-bet.seat-template-8 {
    left: 23.75%;
    top: 39%;
}

.poker-root .poker-alpha.mobile .r-table-chips-layer .r-player-bet.seat-template-9 {
    left: 31%;
    top: 15%;
}

.poker-root .poker-alpha.mobile .r-table-cards .card-holder {
    /*width: 21.25em;*/
    width: 22.5em;
    /*margin-top: 32.5vh;*/
    margin-top: 30.5vh;
    transform: perspective(180px) rotateX(16deg) scaleY(1.05);
    transform-origin: top center;
}

.poker-root .poker-alpha.mobile .r-table-cards .card-holder .r-card.flipped .flip-card-back::before{
    content: "";
    width: 100%;
    height: 100%;
    box-shadow: inset -2px 0 3px #0000006e;
    display: block;
    position: absolute;
    z-index: 1;
    border-radius: 0.3em;
}
/*
.poker-root .poker-alpha.mobile .poker-game .r-scene-game-wrapper .bank-container {
    top: 38%;
    left: auto;
    right: 0%;
    width: 100%;
    height: 1.4375em;
}

.poker-root .poker-alpha.mobile .poker-game .r-scene-game-wrapper .bank-container .bank-container-content {
    font-size: 1.25em;
    min-width: 3.8em;
}
*/
.poker-root .mobile .bank-container {
    top: 0;
    width: 30%;
    border-radius: 12vw;
    border: 2px solid #ddcc71;
    padding: 4px 15px;
}
.poker-root .mobile .bank-container .bank-container-content {
    font-size: 3.5vw
}

.poker-root .mobile .background-table .table-name {
    top: 66%;
    font-size: 120%;
    width: 50%;
    left: 25%;
}
.poker-root .mobile .background-table .table-blind .blindtitle {
    font-size: 10px;
}
.poker-root .mobile .background-table .table-blind .blindval {
    width: 30%;
}

.poker-root .poker-alpha.mobile .poker-game .r-scene-game-wrapper .pots {
    top: 58%;
    width: 100%;
    left: 0%;
}

.poker-root .poker-alpha.mobile .ReactPokerPotsContainer .ReactPokerPotsContainer__chip, .poker-root .poker-alpha.tablet .ReactPokerPotsContainer .ReactPokerPotsContainer__chip {
    flex-direction: column;
}

.poker-root .poker-alpha.mobile .r-chip.direction-right .text, .poker-root .poker-alpha.tablet .r-chip.direction-right .text, .poker-root .poker-alpha.mobile .r-chip.direction-left .text, .poker-root .poker-alpha.tablet .r-chip.direction-left .text {
    bottom: 0.2em;
}

.poker-root .poker-alpha.mobile .ReactPokerPotsContainer .ReactPokerPotsContainer__chip .text, 
.poker-root .poker-alpha.tablet .ReactPokerPotsContainer .ReactPokerPotsContainer__chip .text {
    margin-top: 1em;
    background: #3b3b3bdb;
    border-radius: 1em;
    width: 55vw;
    height: 1.75em !important;
    justify-content: space-between;
    padding: 0.2em 1.2em;
    font-size: 1.4em;
}

.poker-root .poker-alpha.mobile .ReactPokerPotsContainer .ReactPokerPotsContainer__chip .text::before, 
.poker-root .poker-alpha.tablet .ReactPokerPotsContainer .ReactPokerPotsContainer__chip .text::before {
   position: absolute;
   content: "";
   width: 100%;
   height: 100%;
   left: 0;
   background: url('../Images/chip_total.png');
   background-repeat: no-repeat;
   background-size: contain;
   background-position-x: left;
}

.poker-root .poker-alpha.mobile .ReactPokerPotsContainer .ReactPokerPotsContainer__chip .text .val-lbl,
.poker-root .poker-alpha.mobile .ReactPokerPotsContainer .ReactPokerPotsContainer__chip .text .value {
    font-size: 0.75em;
}


.poker-root .poker-alpha.mobile .r-chip.chip-columns .text .value {
    font-size: 1.2em;
}


.poker-root .poker-alpha.mobile .r-seat.default .r-player-cards .card-holder.close-card-holder .r-card {
    font-size: 100%;
    width: 25px;
    height: 35px;
}

.poker-root .poker-alpha.mobile .r-seat.default .r-player-cards .card-holder.open-card-holder .r-card {
    font-size: 100%;
    width: 4.5em;
    height: 6.3em;
}

.poker-root .poker-alpha.mobile .r-seat.winner .r-player-avatar:before {
    content: '';
    position: absolute;
    left: -35%;
    top: -35%;
    width: 25vw;
    height: 25vw;
    background: url('../Images/winner-cc0b62f4816026a5f9cc.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.poker-root .mobile .winner .r-player-avatar .avatar {
   filter: brightness(1) !important;
}

.poker-root .poker-alpha.mobile .poker-game .r-scene-container .r-seat.default {
    width: 8em;
    height: 9.25em;
}


.poker-root .mobile .poker-game .r-player-bar {
    width: var(--r-player-bar-mobile-w);
    height: var(--r-player-bar-mobile-h) !important;
    border-radius: 6px;
}

/*.poker-root .poker-alpha.mobile .poker-game .r-seat .r-player-bar {*/
/*    top: 22%;*/
/*    left: 75%;*/
/*}*/

/*.poker-root .poker-alpha.mobile .poker-game .r-seat.seat-template-2 .r-player-bar {*/
/*    left: -65% !important;*/
/*}*/



.poker-root .mobile .r-seat.default .r-player-avatar {
    padding: 2px;
    border-radius: 50%;
    left: 50% !important;
    transform: translateX(-50%);
    bottom: 2.2em;
    width: 5.8em;
    height: 5.8em;
    top: 7px;
    z-index: 3;
    position: relative;
}

.poker-root .mobile .r-seat.seat-template-5 .r-player-avatar {
    left: -10% !important;
    transform: translateX(-50%);
}
.poker-root .mobile .r-seat.seat-template-6 {
    left: 1% !important;
}
.poker-root .poker-alpha .r-player-avatar .avatar {
    border: 0;
}

.poker-root .r-player-avatar .avatar {
    width: 100%;
    height: 100%;
    /*border-radius: 50%;*/
    position: relative;
}

.poker-root .mobile .avatar {
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    margin: 0px;
}

.poker-root .poker-alpha .bubble-messages-seat-container {
    width: 15.323em;
    z-index: 3;
}

.poker-root .mobile .r-scene-game-wrapper {
    top: 5em;
    bottom: 13em;
    position: absolute;
    left: 0;
    width: 100%;
    height: auto;
}

.poker-root .r-scene-container .p-scene-game-wrapper .p-scene-game-table .p-scene-game-table-cell {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.poker-root .r-scene-container .r-scene-game-wrapper .p-scene-game-table {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

.poker-root .poker-alpha.mobile .poker-game .r-seat.default {
    width: 8em;
    height: 9.25em;
}

.poker-root .poker-alpha.mobile .r-seat.default .free-seat {
    width: 5em;
    height: 5em;
}


.poker-root .mobile .r-scene-game-wrapper .table-footer-container {
    height: 5.5em;
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
}


.poker-root .mobile .table-footer-container .permanent-auto-actions-container {
    /*height: 12.5em;*/
    height: 13vh;
    bottom: 0;
    z-index: 5;
    width: 100%;
    position: absolute;
}

.poker-root .poker-alpha.mobile .chat-open-button-wrapper {
    pointer-events: all;
    position: absolute;
    left: 1.5em;
    top: auto;
    bottom: 7.5em;
}

.poker-root .mobile .table-footer-container .chat-open-button-wrapper .chat-open-button {
    width: 3.5em;
    height: 3.5em;
    border-radius: 0.5em;
    z-index:5;
    position:relative;
}

.poker-root .mobile .table-footer-container .SimpleButton .SimpleButton__content {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.poker-root .mobile .table-footer-container .chat-open-button-wrapper .chat-open-button .Icon {
    width: 3em;
    height: 3em;
    flex-shrink: 0;
    flex-grow: 0;
    margin-left: 0.2em;
    margin-right: 0.2em;
}

.poker-root .poker-alpha.mobile .SimpleButton_c_blue {
    background: linear-gradient(180deg, #2F2F2F 46.19%, #202020 82.43%);
    box-shadow: 0 0.25em 0 #1D1D1D, 0 0.25em 0.5em rgba(0, 0, 0, 0.2);
    border: 0.125em solid rgba(255, 255, 255, 0.05);
}

.poker-root .mobile .table-footer-container .chat-open-button-wrapper .Icon_v_default .Icon__content {
    /*background-image: url(https://room.jack-poker.com/assets/table-chat-phone-3152022e237bfbd8cf6e.svg);*/
}

.poker-root .mobile .table-footer-container .table-footer-container-content {
    position: relative;
    height: 100%;
    width: 100%;
}

.poker-root .mobile .table-actions-container {
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    z-index: 4;
    top: auto;
    bottom: 0em;
}

.poker-root .mobile .table-actions-container .footerAction {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 5px;
}

.poker-root .poker-alpha.mobile .TableHeaderContainer {
    /*height: 5em;*/
}

.poker-root .mobile .TableHeaderContainer .TableHeaderContainer__button.SimpleButton.SimpleButton_use_icon:not(.SimpleButton_use_text) {
    width: 55px;
    height: 55px;
}

.poker-root .poker-alpha.mobile .TableHeaderContainer .TableHeaderContainer__button.SimpleButton.SimpleButton_use_icon:not(.SimpleButton_use_text) .SimpleButton__icon {
    width: 3em;
    height: 3em;
}

.poker-root .poker-alpha.mobile .r-seat .player-action {
    color: #fff;
    pointer-events: none;
    height: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: unset;
    bottom: -7px;
    left: 50%;
    font-size: 5vw;
    padding: 0;
    z-index: 13;
    min-width: 55px;
    min-height: 23px;
}
/*
.poker-root .poker-alpha.mobile .r-seat.seat-template-9 .player-action {
    left: 140%;
    top: 50%;
}

.poker-root .poker-alpha.mobile .r-seat.seat-template-1 .player-action {
    left: -50%;
    top: 50%;
}*/

.poker-root .poker-alpha.mobile .r-seat .player-action.Fold-action-group.wait,
.poker-root .poker-alpha.mobile .r-seat .player-action.Fold-action-group.away {
    font-size: 5vw;
    top: unset;
    bottom: -7px;
}
.poker-root .poker-alpha.mobile .r-seat.seat-template-1 .player-action.Fold-action-group.wait,
.poker-root .poker-alpha.mobile .r-seat.seat-template-1 .player-action.Fold-action-group.away,
.poker-root .poker-alpha.mobile .r-seat.seat-template-9 .player-action.Fold-action-group.wait,
.poker-root .poker-alpha.mobile .r-seat.seat-template-9 .player-action.Fold-action-group.away {
    left: 50%;
    font-size: 5vw; 
}

/*
.poker-root .poker-alpha.mobile .r-seat.left-direction .player-action {
    justify-content: flex-end !important;
}

.poker-root .poker-alpha.mobile .r-seat.right-direction .player-action {
    justify-content: flex-start !important;
    top: 42%
}
.poker-root .poker-alpha.mobile .r-seat.left-direction.seat-template-1 .player-action,
.poker-root .poker-alpha.mobile .r-seat.left-direction.seat-template-2 .player-action,
.poker-root .poker-alpha.mobile .r-seat.left-direction.seat-template-3 .player-action {
    left: -155%;
    top: 44%;
}

.poker-root .poker-alpha.mobile .r-seat.left-direction.seat-template-4 .player-action {
    left: -175%;
    top: 44%;
}
*/

.poker-root .poker-alpha.mobile .r-seat .player-action .text {
    height: 100%;
    /*display: flex;*/
    justify-content: center;
    align-items: center;
    font-size: 0.6em;
    min-width: 3.38em;
    /*padding: 0.7em;*/
    box-sizing: border-box;
    
    opacity: 0;
    visibility: hidden;
    
    border-radius: 0.5em;
    padding-right: 2px;
    padding-bottom: 1px;
    text-shadow: 1px 1px 3px #000;
    /*box-shadow: 0 0.0625em 0.125em rgba(0, 0, 0, 0.25), inset 0 0.0625em 0 rgba(255, 255, 255, 0.5);*/
}
/*
.poker-root .poker-alpha.mobile .r-seat .player-action.Fold-action-group.wait .text,
.poker-root .poker-alpha.mobile .r-seat .player-action.Fold-action-group.away .text {
    font-size: 0.6em !important;
}*/


.poker-root .mobile .am-back-button-wrapper {
    /*background: #1e1e1e;*/
    right: 0.5em;
    width: 6.75em;
    height: 3.75em;
    position: absolute;
    top: auto;
    pointer-events: all;
    bottom: 0.5em;
    border-radius: 8px;
}

.poker-root .mobile .am-back-button-wrapper .chat-open-button.SimpleButton .SimpleButton__icon, .poker-root .tablet .am-back-button-wrapper .chat-open-button.SimpleButton .SimpleButton__icon {
    width: 100%;
    height: 100%;
    background: transparent;
}

.poker-root .poker-alpha.mobile .Icon .Icon__content button {
    background: transparent;
    border: none;
    color: #fff;
}

.poker-root .mobile .am-back-button-wrapper:hover, .poker-root .tablet .am-back-button-wrapper:hover {
    /*background: #404040;*/
}
/*
.poker-root .mobile .am-back-button-wrapper .chat-open-button {
    width: 6.75em;
    height: 3.75em;
    background: transparent !important;
    box-shadow: none !important;
}
*/
.poker-root .poker-alpha.mobile .r-seat.default.right-direction .r-player-cards .card-holder.close-card-holder .wrapper-close-card {
    right: auto;
    left: 30%;
}

.poker-root .poker-alpha.mobile .r-seat.default.left-direction .r-player-cards .card-holder.close-card-holder .wrapper-close-card {
    right: auto;
    left: -30%;
}

.poker-root .poker-alpha.mobile .r-seat.seat-template-1.right-direction .r-player-cards .card-holder.close-card-holder .wrapper-close-card {
    right: auto;
    left: 70%;
}

.poker-root .poker-alpha.mobile .r-seat.default .r-player-cards .card-holder.close-card-holder .wrapper-close-card .r-card {
    /*top: 0% !important;*/
}
.poker-root .poker-alpha.mobile .r-seat.default.left-direction .r-player-cards.max-cards-5 .card-holder:not(.opened-cards-after-show-down) .r-card.close-card.card-2, 
.poker-root .poker-alpha.mobile .r-seat.default.right-direction .r-player-cards.max-cards-5 .card-holder:not(.opened-cards-after-show-down) .r-card.close-card.card-2 {
    top: -45%;
}
.poker-root .poker-alpha.mobile .r-seat.default.left-direction .r-player-cards.max-cards-5 .card-holder:not(.opened-cards-after-show-down) .r-card.close-card.card-1, 
.poker-root .poker-alpha.mobile .r-seat.default.right-direction .r-player-cards.max-cards-5 .card-holder:not(.opened-cards-after-show-down) .r-card.close-card.card-1 {
     top: -40%;
}

.poker-root .poker-alpha.mobile .r-seat.default .r-player-cards .card-holder.close-card-holder {
    z-index: 2;
    transform: translateX(-50%);
}
.poker-root .poker-alpha.mobile .r-seat.right-direction .r-player-cards .card-holder.close-card-holder {
    bottom: -54%;
    left: 38% !important;
}
.poker-root .poker-alpha.mobile .r-seat.left-direction .r-player-cards .card-holder.close-card-holder {
    bottom: -54%;
    left: 122% !important;
}

.poker-root .poker-alpha.mobile .r-seat.seat-template-5 .r-player-cards .card-holder.close-card-holder {
    bottom: -54%;
    left: 170% !important;
}
/*
.poker-root .poker-alpha.mobile .r-seat.seat-template-1 .r-player-cards .card-holder.close-card-holder {
    left: 125% !important;
}

.poker-root .poker-alpha.mobile .r-seat.seat-template-2 .r-player-cards .card-holder.close-card-holder,
.poker-root .poker-alpha.mobile .r-seat.seat-template-3 .r-player-cards .card-holder.close-card-holder {
    left:120% !important;
}

.poker-root .poker-alpha.mobile .r-seat.seat-template-4 .r-player-cards .card-holder.close-card-holder {
    left: 100% !important;
}
.poker-root .poker-alpha.mobile .r-seat.seat-template-5 .r-player-cards .card-holder.close-card-holder {
  
    left: 4% !important;
}
*/

.poker-root .poker-alpha.mobile .r-seat.default .r-player-cards .card-holder.open-card-holder {
    /*bottom: 1.2em;*/
    bottom: -35%;
    z-index: 3;
    left: 50% !important;
    transform: translateX(-56%);
}
/*.poker-root .poker-alpha.mobile .r-seat.seat-template-1 .r-player-cards .card-holder.open-card-holder {*/
/*    left: -65%;*/
/*}*/
/*.poker-root .poker-alpha.mobile .r-seat.seat-template-2 .r-player-cards .card-holder.open-card-holder,*/
/*.poker-root .poker-alpha.mobile .r-seat.seat-template-3 .r-player-cards .card-holder.open-card-holder {*/
/*    left: -14% !important;*/
/*}*/
.poker-root .poker-alpha.mobile .r-seat.seat-template-4 .r-player-cards .card-holder.open-card-holder {
    left: 50% !important;
}
.poker-root .poker-alpha.mobile .r-seat.seat-template-5 .r-player-cards .card-holder.open-card-holder {
    bottom: -30%;
    left: 130% !important;
}
.poker-root .mobile .r-player-cards .card-holder.close-card-holder .r-card-wrapper {
    width: 0.6em;
    height: 100%;
    position: relative;
    display: inline-block;
}

.poker-root .mobile .r-player-cards .card-holder.open-card-holder .r-card-wrapper {
    width: 2em;
    height: 100%;
    position: relative;
    display: inline-block;
}

.poker-root .mobile .table-actions-container .table-actions-container-content .player-actions {
    left: 0;
    width: 100%;
    top: 0;
    height: 100%;
    display: block;
    justify-content: center;
    position: absolute;
    z-index: 1;
    padding-right: 2%;
    padding-left: 2%;
    bottom: 2em;
}

.poker-root .mobile .table-loading {
    font-size: 1.2rem !important;
    top: 0;
    color: #ffda23 !important;
}

.poker-root .mobile .table-router-container .scroll-panel .header-btns>.hbtn {
    padding: 2px 7px;
    min-width: 4rem;
}

.poker-root .mobile .table-router-container .scroll-panel .header-btns>.hbtn >.hbtn-icon{
    height: 1rem;
    margin-right: 6px;
    width: 1rem;
}

.poker-root .mobile .table-router-container .scroll-panel .scroll-wrapper {
    min-width: 9rem;
}
.poker-root .table-router-container .scroll-panel .scroll-wrapper .add-table-button {
    /*width: 100%;*/
}

.mobileView.plyerProfile .tableCreate.cashier-box .cahsierForm input[type=file], 
.mobileView.plyerProfile .tableCreate.cashier-box .cahsierForm input[type=password], 
.mobileView.plyerProfile .tableCreate.cashier-box .cahsierForm input[type=text],
.mobileView.plyerProfile .tableCreate.cashier-box .cahsierForm select,
.mobileView.plyerProfile .tableCreate.cashier-box .cahsierForm .form-group label {
    font-size: 0.8rem !important;
    margin-top: 5px !important;
}

@media (aspect-ratio: 360/800) {
    .poker-root .mobile.poker-alpha .r-seat.seat-template-5:has(> .panel.r-player) {
        top: 97% !important;
    }
}

@media (max-width: 768px) {
    .hbtn.exitgame-btn {
        display: none !important;
    }
}

.poker-root .poker-alpha.mobile .r-chip {
    height: unset;
    position: absolute;
    width: unset;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
}

@media only screen and (max-width: 321px) {
    .jackpotMessage .jp-wrapper {
        width: 270px;
        height: 202px;
        margin: 15% auto;
    }
    .jackpotMessage .jp-amount {
        font-size: 2.5rem;
        margin-top: 78px;
    }
    .jackpotMessage .jp-pname {
        margin-top: 9px;
        font-size: 12px;
    }
    .jackpotMessage .jp-hname {
        margin-top: 8px;
        font-size: 18px;
    }
}

@media only screen and (min-width: 322px) and (max-width: 431px) {
    .jackpotMessage .jp-wrapper {
        width: 330px;
        height: 247px;
        margin: 18% auto;
    }
    .jackpotMessage .jp-amount {
        font-size: 2.4rem;
        margin-top: 100px;
    }
    .jackpotMessage .jp-pname {
        margin-top: 22px;
        font-size: 14px;
    }
    .jackpotMessage .jp-hname {
        margin-top: 10px;
        font-size: 20px;
    }
}


/* Tablet */

@media (min-width: 760px) {
    .poker-root .poker-alpha.mobile.portrait .r-scene-container .scene-container-content .scene-background-table .background-table-felt {
        background-size: 80% 90% !important;
        background-position-x: 51%;
    }
    .poker-root .poker-alpha.mobile .p-game-area .background-table .background-table-logo {
        background-size: 40% !important;
    }
    
    .poker-root .poker-alpha.mobile .p-game-area .background-table .table-name {
        font-size: 100%;
        left: 25%;
        top: 61%;
        width: 50%;
    }
    
    .poker-root .poker-alpha.mobile .r-seat.default .free-seat {
        width: 4em !important;
        height: 4em !important;
    }
    .poker-root .poker-alpha.mobile .r-seat.seat-template-1 {
        left: 64%;
        top: -3% !important;
    }
    .poker-root .poker-alpha.mobile .r-seat.seat-template-1:has(.panel.free-seat) {
        left: 59%;
    }
    .poker-root .poker-alpha.mobile .r-seat.seat-template-2 {
        left: 69.5%;
        top: 28% !important;
    }
    .poker-root .poker-alpha.mobile .r-seat.seat-template-2:has(.panel.free-seat) {
        left: 67%;
    }
    .poker-root .poker-alpha.mobile .r-seat.seat-template-3 {
        left: 73.5%;
        top: 58.5% !important;
    }
    .poker-root .poker-alpha.mobile .r-seat.seat-template-3:has(.panel.free-seat) {
        left: 67.5%;
    }
    .poker-root .poker-alpha.mobile .r-seat.seat-template-4 {
        left: 71.5% !important;
        top: 89.5% !important;
    }
    .poker-root .poker-alpha.mobile .r-seat.seat-template-5 {
        left: 36.5% !important;
        top: 113% !important;
    }
    .poker-root .poker-alpha.mobile .r-seat.seat-template-6 {
        left: 7% !important;
        top: 89.5% !important;
    }
    .poker-root .poker-alpha.mobile .r-seat.seat-template-6:has(.panel.free-seat) {
        left: 6% !important;
    }
    .poker-root .poker-alpha.mobile .r-seat.seat-template-7 {
        left: 5.5%;
        top: 58.5% !important;
    }
    .poker-root .poker-alpha.mobile .r-seat.seat-template-7:has(.panel.free-seat) {
        left: 7.5%;
    }
    .poker-root .poker-alpha.mobile .r-seat.seat-template-8 {
        left: 9.5% !important;
        top: 28% !important;
    }
    .poker-root .poker-alpha.mobile .r-seat.seat-template-9 {
        left: 15.25% !important;
        top: -3% !important;
    }
    .poker-root .poker-alpha.mobile .r-table-cards .card-holder {
        /*margin-top: 15.5em !important;*/
        margin-top: 180px !important;
        width: 17.25em !important;
    }
    .mobile .extOnGame {
        bottom: -159px;
        left: 22px;
    }
    .mobile .win.extOnGame {
        bottom: -222px !important;
    }
    .mobileView .tableListBox p {
        font-size: 1.3rem;
    }
    .mobileView .cashier-heading h2,
    .mobileView .cashier-modal .modal-sidebar .modal-sidebar-button,
    .mobileView .p-hd-fill-btn {
        font-size: 1.2rem !important;
    }
    .mobileView .table-theme-setting-header h6 {
        font-size: 1.2em;
        padding: 2px 9px;
    }
    .mobileView .cashier-modal .p-lb-table tbody td, .mobileView .cashier-modal .p-lb-table th {
        font-size: 1.2em !important;
    }
    .poker-root .poker-alpha.mobile .r-seat.left-direction .panel.r-player .r-player-bar,
    .poker-root .poker-alpha.mobile .r-seat.right-direction .panel.r-player .r-player-bar {
        top:55% !important;
    }
    .poker-root .poker-alpha.mobile .TableHeaderContainer {
        height: 4em;
    }
    .poker-root .poker-alpha.mobile .table-router-container .scroll-panel .scroll-wrapper .add-table-button {
        width: 50px;
        height: 50px;
    }
    .poker-root .poker-alpha.mobile .r-seat.seat-template-5 .r-player-cards .card-holder.open-card-holder {
        bottom: 7%;
    }
    .mobile .player_rank:not(:empty) {
        bottom: 6% !important;
    }
    .poker-root .poker-alpha.mobile .ReactPokerPotsContainer .ReactPokerPotsContainer__chip .text {
        font-size: 1.1em;
    }
    
    .poker-root .poker-alpha.mobile .r-table-chips-layer .r-player-bet.seat-template-1 {
        left: 50%;
        top: 9%;
    }
    .poker-root .poker-alpha.mobile .r-table-chips-layer .r-player-bet.seat-template-2 {
        left: 55%;
        top: 38%;
    }
    .poker-root .poker-alpha.mobile .r-table-chips-layer .r-player-bet.seat-template-3 {
        left: 59%;
        top: 58%;
    }
    .poker-root .poker-alpha.mobile .r-table-chips-layer .r-player-bet.seat-template-4 {
        left: 58%;
        top: 80%;
    }
    .poker-root .poker-alpha.mobile .r-table-chips-layer .r-player-bet.seat-template-5 {
        left: 41%;
        top: 89%;
    }
    .poker-root .poker-alpha.mobile .r-table-chips-layer .r-player-bet.seat-template-6 {
        left: 24.75%;
        top: 80%;
    }
    
    .poker-root .poker-alpha.mobile .r-table-chips-layer .r-player-bet.seat-template-7 {
        left: 24%;
        top: 58%;
    }
    .poker-root .poker-alpha.mobile .r-table-chips-layer .r-player-bet.seat-template-8 {
        left: 27.75%;
        top: 38%;
    }
    .poker-root .poker-alpha.mobile .r-table-chips-layer .r-player-bet.seat-template-9 {
        left: 34%;
        top: 9%;
    }
    .mobileView .p-tb-join-btn {
        font-size: 1.5em !important;
    }
    .mobileView .lobbyFooter .footerText,
    .mobileView .lobbyFooter .info-item .txt-data {
        font-size: 1.3em !important;
    }
    .mobileView .mbuserProfile .userInfo .userDetails .userDetails-lvl {
        font-size: 1.3em;
    }
    .mobileView .userBalance {
        font-size: 1.7em;
    }
    .mobileView .rightside-btns .h-btn {
        font-size: 1.5em;
    }
    .poker-root .mobile .r-seat.default .r-player-avatar {
        padding: 5px;
    }
    .poker-root .poker-alpha.mobile .r-card {
        height: 4.5em;
        width: 3.5em;
    }
    .mobile .footerAction .footerActionCheckBoxRight.game-checkbox .chk-btn,
    .mobile .footerActionBtn.btActions .btAction {
        border-radius: 50px;
        max-width: 160px;
        width: calc(25% - 4px);
        height: 60px;
        font-size: 25px;
    }
    .mobile .footerActionCheckBoxRight .d-flex {
        gap: 2px;
    }
    .mobile .footerAction .footerActionCheckBoxRight.game-checkbox .chk-btn .form-control span {
        font-size: 25px;
    }
    .mobile .prv-wrapper .prv-tblname {
        font-size: 20px;
    }
    .poker-root .poker-alpha.mobile .TableIndicator.TableIndicator_v_transparent .TableIndicator__content {
        height: 1.85em;
    }
    .mobile .playcards-prv > div {
        display: flex;
        align-items: center;
    }
    .mobile .playcards-prv .card-face {
        width: 2em;
        height: 1em;
    }
    .mobileView .userDetails .userDetails-lvl .userName {
        font-size: 1.2em;
    }
    .mobileView .mtable-wrap .mtable-name {
        font-size: 20px;
    }
    .mobile .TableIndicatorContainer .prv-wrapper .TableIndicator__content:empty {
        background: none;
    }
    .mobile .TableIndicatorContainer .prv-wrapper .TableIndicator__content:empty+.prv-tblname {
        top: 5%;
    }
    .mobile .menu_top_close-icon {
        height: 65px;
        width: 65px;
    }
    .poker-root .mobile .TableHeaderContainer .TableHeaderContainer__button.SimpleButton.SimpleButton_use_icon:not(.SimpleButton_use_text) {
        width: 70px;
        height: 70px;
    }
    .poker-root .poker-alpha.mobile .r-seat .player-action {
        bottom: -14px;
    }
    .poker-root .mobile .r-seat.default .r-player-avatar {
        top: unset;
        bottom: 7px;
    }
    .poker-root .poker-alpha.mobile .poker-game .r-scene-game-wrapper .pots {
        top: 48%;
    }
    .poker-root .poker-alpha.mobile .table-router-container .scroll-panel .scroll-wrapper .prv-wrapper .tbl-total-user {
        font-size: 18px;
        height: 30px;
        right: 4px;
        top: 3px;
        width: 30px;
    }
    .poker-root .mobile .poker-game .r-player-bar {
        width: var(--r-player-bar-tablet-w);
        height: var(--r-player-bar-tablet-h) !important;
        border-radius: 6px;
    }
    .poker-root .poker-alpha.mobile .r-seat.left-direction .panel.r-player .r-player-bar,
    .poker-root .poker-alpha.mobile .r-seat.right-direction .panel.r-player .r-player-bar {
        left: 10px !important;
    }
    .poker-root .poker-alpha.mobile .r-seat.seat-template-5:has(>.panel.r-player) {
        left: 56.5% !important;
        top: 110% !important;
    }
    .poker-root .mobile.poker-alpha .r-seat.seat-template-5 .panel.r-player .image.r-player-bar {
        left: -53% !important;
    }
    .poker-root .poker-alpha.mobile .r-seat.right-direction .r-player-cards .card-holder.close-card-holder {
        bottom: -24%;
        left: 42% !important;
    }
    .poker-root .poker-alpha.mobile .r-seat.left-direction .r-player-cards .card-holder.close-card-holder {
        bottom: -25%;
        left: 125% !important;
    }
    .poker-root .poker-alpha.mobile .r-seat.default .r-player-cards .card-holder.open-card-holder {
        bottom: 0;
    }
    .poker-root .mobile .game-container.poker-game .r-seat .seat-combination-names {
        top: 12%;
    }
    .poker-root .mobile .r-seat__won_message {
        left: 32px;
        top: 58px;
    }
    .mobileView .lobby-page .mobileTableList.tableFixHead {
        height: 55vh;
    }
    
    .mobile .progressTimer .countdown-tr {
        right: 0px;
        bottom: 0px;
        border-top: 4px solid gold !important;
        border-right: 4px solid gold !important;
        animation: countdown-animation-t calc(var(--duration) * 1s) linear 1 !important;
    }
    
    .mobile .progressTimer .countdown-bl {
        left: 0px;
        top: 0px;
        border-left: 4px solid gold !important;
        border-bottom: 4px solid gold !important;
        animation: countdown-animation-inner-t calc(var(--duration) * 1s) linear 1 !important;
    }
    
    @keyframes countdown-wrapper-animation-t {
        0% {
            opacity: 1;
        }
    
        100% {
            opacity: 0;
        }
    }
    
    @keyframes countdown-animation-t {
        0% {
            width: var(--r-player-bar-tablet-w);
            height: var(--r-player-bar-tablet-h);
            opacity: 1;
        }
    
        20% {
            width: 0px;
            height: var(--r-player-bar-tablet-h);
            opacity: 1;
        }
    
        40%, 50% {
            width: 0px;
            height: 0px;
            opacity: 1;
        }
        56% {
            opacity: 0;
            visibility: hidden;
            border-top: 0;
        }
    
        98%, 100% {
            width: 0px;
            height: 0px;
            opacity: 0;
            border-top: 0;
        }
    }
    
    @keyframes countdown-animation-inner-t {
    
        0%,39% {
            width: var(--r-player-bar-tablet-w);
            height: var(--r-player-bar-tablet-h);
            opacity: 1;
        }
    
        40% {
            width: var(--r-player-bar-tablet-w);
            height: var(--r-player-bar-tablet-h);
            opacity: 1;
        }
    
        60% {
            width: 0px;
            height: var(--r-player-bar-tablet-h);
            opacity: 1;
        }
        99% {
            width: 0px;
            height: 0px;
            opacity: 1;
        }
        100% {
            opacity: 0;
            visibility: hidden;
        }
    }

}

.mobileView .top-header {
    padding: 6px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mobileView .top-header .main-title {
    font-weight: 600;
    color: #fff;
    font-style: italic;
}

.mobileView .mbuserProfile .logonew {
    background: url('../Images/logo_new.png');
    background-color: #16171c;
    width: 100px;
    height: 119px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 0.5rem;
}
@media (max-width: 321px) {
    .mobileView .mbuserProfile .logonew {
        width: 90px;
        height: 85px;
    }
    .mobileView .mbuserProfile .userInfo .userDetails .userDetails-lvl {
        margin: 0;
    }
    .mobileView .jackpot_block {
        height: 5em;
    }
    .mobileView .playerProfileWithFrameLobby {
        height: 85px;
    }
    .mobileView .vipLevelLobby1 img, .mobileView .vipLevelLobby2 img, .mobileView .vipLevelLobby3 img, .mobileView .vipLevelLobby4 img, .mobileView .vipLevelLobby5 img {
        height: 85px;
        width: auto;
    }
    
}

.mobileView .btnsFooter {
    position: absolute;
    bottom: 2.3em;
    background: #000;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 0;
}

.mobileView .btnsFooter .cashier-btn.create-tbl {
    position: relative;
    border-radius: 20px;
    color: #fff;
    width: 140px;
    height: 34px;
    padding: 4px 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 0.8rem;
    font-weight: 600;
    white-space: nowrap;
    background: url(../Images/table-addbtn.png) no-repeat;
    background-size: 100%;
    cursor: pointer;
}

/*
.mobileView .btnsFooter .cashier-btn.create-tbl::before {
    align-items: center;
    background-color: #ffd050;
    background: linear-gradient(90deg, #fff5d0, #cfbc7c) border-box;
    border-radius: 20px;
    position: absolute;
    border: 2px solid transparent;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    content: "";
    width: 100%;
    height: 100%;
}
*/

.mobileView .cashGame {
    border-top: 3px solid;
    border-image: linear-gradient(90deg, #fff5d0, #cfbc7c) 3;
}


.mobile .progressTimer .countdown-tr,
.mobile .progressTimer .countdown-bl {
    position: absolute;
    border-radius: 6px;
    box-sizing: border-box;
    height: 0px;
    width: 0px;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    animation-duration: calc(var(--duration) * 1s);
}

.mobile .progressTimer .countdown-tr {
    right: 0px;
    bottom: 0px;
    border-top: 2px solid gold;
    border-right: 2px solid gold;
    animation: countdown-animation-m calc(var(--duration) * 1s) linear 1;
}

.mobile .progressTimer .countdown-bl {
    left: 0px;
    top: 0px;
    border-left: 2px solid gold;
    border-bottom: 2px solid gold;
    animation: countdown-animation-inner-m calc(var(--duration) * 1s) linear 1;
}

@keyframes countdown-wrapper-animation-m {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes countdown-animation-m {
    0% {
        width: var(--r-player-bar-mobile-w);
        height: var(--r-player-bar-mobile-h);
        opacity: 1;
    }

    20% {
        width: 0px;
        height: var(--r-player-bar-mobile-h);
        opacity: 1;
    }

    40%, 50% {
        width: 0px;
        height: 0px;
        opacity: 1;
    }
    56% {
        opacity: 0;
        visibility: hidden;
        border-top: 0;
    }

    98%, 100% {
        width: 0px;
        height: 0px;
        opacity: 0;
        border-top: 0;
    }
}

@keyframes countdown-animation-inner-m {

    0%,39% {
        width: var(--r-player-bar-mobile-w);
        height: var(--r-player-bar-mobile-h);
        opacity: 1;
    }

    40% {
        width: var(--r-player-bar-mobile-w);
        height: var(--r-player-bar-mobile-h);
        opacity: 1;
    }

    60% {
        width: 0px;
        height: var(--r-player-bar-mobile-h);
        opacity: 1;
    }
    99% {
        width: 0px;
        height: 0px;
        opacity: 1;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}

.mobileView .handHistory .gameHistoryData .playerProfileInfo .login_header > h6 {
    white-space: nowrap;
    font-size: 12px;
}