:root {
    --talk-yellow: #ffd050;
}

.ReactModal__Content.ReactModal__Content--after-open:has(>.mobileView) {
    background: transparent;
    min-height: 160px;
}

.mobileView.plyerProfile {
    width: 95%;
    margin: auto;
}

.mobileView.plyerProfile .cashier-modal.cashier {
    border: 3px solid var(--talk-yellow);
    border-radius: 10px;
}
.mobileView.plyerProfile .cashier-modal.cashier > div.d-flex {
    flex-direction: column;
}

.mobileView.plyerProfile .cashier-modal.cashier .modal-sidebar {
    width: 100%;
}

.mobileView.plyerProfile .cashier-modal.cashier .modal-sidebar .modal-mobile-header {
    background-color: var(--talk-yellow);
}

.mobileView.plyerProfile .cashier-modal.cashier .modal-sidebar .modal-mobile-header .cashier-heading {
    display: flex;
    justify-content: space-between;
}

.mobileView.plyerProfile .cashier-modal.cashier .modal-sidebar .modal-mobile-header .cashier-heading > img.close-modal-btn {
    filter: grayscale(1) invert(1);
    width: 14px;
    height: 14px;
}

.mobileView.plyerProfile .cashier-modal.cashier .modal-sidebar .cashier-modal-menu {
    display: flex;
    width: 100%;
}

.mobileView .cashier-modal .modal-sidebar .modal-sidebar-button {
    color: #fff !important;
    text-align: center !important;
    word-break: keep-all;
    flex: 1;
}

.mobileView .cashier-modal .modal-sidebar .cashier-modal-menu:has(>.modal-sidebar-button:nth-child(5)) .modal-sidebar-button {
    flex: 0 1 25%;
    box-sizing: border-box;
}

.mobileView .cashier-modal .modal-sidebar .modal-sidebar-button.active {
    background-color: var(--talk-yellow) !important;
    color: #000 !important;
}

.mobileView .cashier-modal .modal-sidebar .modal-sidebar-button:first-child:last-child {
    display: none;
}
