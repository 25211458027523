:root {
    --talk-yellow: #ffd050;
    --bodybg: #09090a;
}

.table-theme-block {
    height: 100%;
    width: 100%;
    overflow: scroll;
    overflow-x: hidden;
    padding: 1em;
    padding-top: 0px;
    position: relative;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.mobileView .table-theme-block {
    height: 100%;
    width: 100%;
    overflow: scroll;
    overflow-x: hidden;
    padding: 0.5em;
    padding-top: 0px;
    position: relative;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.mobileView .cashier-modal-body .tableTheme .table-theme-block {
   display: flex;
   padding: 0;
}

.table-theme-block::-webkit-scrollbar {
    display: none;
}

.table-theme-design {
    height: 100%;
    width: 100%;
    position: relative;
    height: 12em;
    width: 100%;
}

/* .table-theme-game-area {
    position: absolute;
    top: 2em;
    height: 14em;
    width: 100%;
} */

.mobileView .table-theme-design {
    position: relative;
    /*height: 28em;*/
    height: 40vh;
    /*width: 100%;*/
    width: 35%;
}

.mobileView .table-theme-game-area {
    position: absolute;
    top: 2em;
    height: 22em;
    width: 100%;
}

.table-theme-setting {
    /*margin-top: 1em;*/
}

.mobileView .table-theme-setting {
    width: 100%;
    margin-top: 0;
    margin-left: 2%;
}

.table-theme-design.desktop .table-shadow {
    background-image: url('../../Images/Table/Desktop/table-shadow.png');
}

.table-theme-design.desktop .table-border {
    /* background-image: url('../../Images/Table/Desktop/table-border.png'); */
    background-image: none;
}

.table-theme-design.desktop .table-default {
    /* background-image: url('../../Images/Table/Desktop/table-screen-green.png'); */
    background-image: url('../../Images/Table/Desktop/table-screen-default.png');
}

.table-theme-design.desktop .table-gray {
    background-image: url('../../Images/Table/Desktop/table-screen-gray.png');
}

.table-theme-design.desktop .table-purpal {
    /* background-image: url('../../Images/Table/Desktop/table-screen-purpal.png'); */
    background-image: url('../../Images/Table/Desktop/table-screen-violet.png');
}

.table-theme-design.desktop .table-blue {
    background-image: url('../../Images/Table/Desktop/table-screen-blue.png');
}

.table-theme-design.desktop .table-gold {
    /* background-image: url('../../Images/Table/Desktop/table-screen-gold.png'); */
    background-image: url('../../Images/Table/Desktop/table-screen-darkred.png');
}

.table-theme-design.desktop .table-red {
    /* background-image: url('../../Images/Table/Desktop/table-screen-red.png'); */
    background-image: url('../../Images/Table/Desktop/table-screen-red.png');
}


.table-theme-design.mobile .table-shadow {
    background-image: url('../../Images/Table/Mobile/table-shadow.png');
}

.table-theme-design.mobile .table-border {
    /* background-image: url('../../Images/Table/Mobile/table-border.png'); */
    background-image: none;
}

.table-theme-design.mobile .table-default {
    /* background-image: url('../../Images/Table/Mobile/table-screen-green.png'); */
    background-image: url('../../Images/Table/Mobile/table-m-default.png');
}

.table-theme-design.mobile .table-gray {
    background-image: url('../../Images/Table/Mobile/table-m-gray.png');
}

.table-theme-design.mobile .table-purpal {
    /* background-image: url('../../Images/Table/Mobile/table-screen-purpal.png'); */
    background-image: url('../../Images/Table/Mobile/table-m-violet.png');
}

.table-theme-design.mobile .table-blue {
    background-image: url('../../Images/Table/Mobile/table-m-blue.png');
}

.table-theme-design.mobile .table-gold {
    /* background-image: url('../../Images/Table/Mobile/table-screen-gold.png'); */
    background-image: url('../../Images/Table/Mobile/table-screen-darkred.png');
}

.table-theme-design.mobile .table-red {
    /* background-image: url('../../Images/Table/Mobile/table-screen-red.png'); */
    background-image: url('../../Images/Table/Mobile/table-m-red.png');
}


.table-theme-design.table-bg-default {
    /* background-color: #19191D; */
    background: url('../../Images/Table/Desktop/bg-pc-default.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}
.table-theme-design.table-bg-default.mobile {
    /* background-color: #19191D; */
    background: url('../../Images/Table/Mobile/bg-m-default.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.table-theme-design.table-bg-green {
    background: #1E3629 radial-gradient(75.89% 100% at 50% 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%)
}

.table-theme-design.table-bg-purpal {
    /* background: #36253E radial-gradient(75.89% 100% at 50% 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%) */
    background: url('../../Images/Table/Desktop/bg-pc-violet.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}
.table-theme-design.table-bg-purpal.mobile {
    /* background: #36253E radial-gradient(75.89% 100% at 50% 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%) */
    background: url('../../Images/Table/Mobile/bg-m-violet.jpg');
    background-size: contain;
    background-repeat: no-repeat;
}

.table-theme-design.table-bg-gray {
    /* background: #27304A radial-gradient(75.89% 100% at 50% 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%); */
    background: url('../../Images/Table/Desktop/bg-pc-grey.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}
.table-theme-design.table-bg-gray.mobile {
    /* background: #27304A radial-gradient(75.89% 100% at 50% 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%); */
    background: url('../../Images/Table/Mobile/bg-m-grey.jpg');
    background-size: contain;
    background-repeat: no-repeat;
}
.table-theme-design.table-bg-blue {
    /* background: #27304A radial-gradient(75.89% 100% at 50% 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%); */
    background: url('../../Images/Table/Desktop/bg-pc-blue.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}
.table-theme-design.table-bg-blue.mobile {
    /* background: #27304A radial-gradient(75.89% 100% at 50% 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%); */
    background: url('../../Images/Table/Mobile/bg-m-blue.jpg');
    background-size: contain;
    background-repeat: no-repeat;
}

.table-theme-design.table-bg-gold {
    /* background: #3C2E1A radial-gradient(75.89% 100% at 50% 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%) */
    background: url('../../Images/Table/Desktop/bg-pc-brown.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}
.table-theme-design.table-bg-gold.mobile {
    /* background: #3C2E1A radial-gradient(75.89% 100% at 50% 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%) */
    background: url('../../Images/Table/Mobile/bg-m-brown.jpg');
    background-size: contain;
    background-repeat: no-repeat;
}

.table-theme-design.table-bg-red {
    /* background: #3C2027 radial-gradient(75.89% 100% at 50% 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%) */
    background: url('../../Images/Table/Desktop/bg-pc-red.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}
.table-theme-design.table-bg-red.mobile {
    /* background: #3C2027 radial-gradient(75.89% 100% at 50% 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%) */
    background: url('../../Images/Table/Mobile/bg-m-red.jpg');
    background-size: contain;
    background-repeat: no-repeat;
}

.table-theme-design .skin-section {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
}
.mobileView .table-theme-design .skin-section {
    top: -11%;
}

.mobileView .table-theme-design .skin-section {
    background-position: center 25% !important;
    height: 90%;
}

.table-setting-btn .btn-box {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 0.125em solid rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
}

.table-setting-btn {
    width: 3em;
    height: 3em;
    border-radius: 50%;
    border: none;
    box-sizing: border-box;
    background: transparent;
}

.table-setting-btn.active {
    border: 0.125em solid rgba(255, 255, 255, 0.1);
    border-color: var(--talk-yellow);
    box-shadow: 0 0.1em #fdd83580;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}



.table-setting-action-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5em;
    margin-top: 1em;
    background-color: #37344d;
    border-radius: 0.5em;
}

.mobileView .table-setting-action-footer {
    flex-wrap: wrap;
}

.default-btn {
    background-color: #1E2D26;
}

.red-btn {
    /* background-color: #3C2027; */
    background-color: #97133d;
}

.red-btn.red-bg {
    background-color: #642832;
}

.gray-btn {
    background-color: #68627a;
}

.purpal-btn {
    background-color: #6b7ee6;
}
.purpal-btn.violet-bg {
    background-color: #45324d;
}

.gold-btn {
    background-color: #750906;
}

.gold-btn.brown-bg {
    background-color: #46361f;
}

.blue-btn {
    background-color: #003e7f;
}

.blue-btn.gray-bg {
    background-color: #454746;
}

.green-btn {
    /* background-color: #1e2d25; */
    background-color: #1c99a1;
}

.table-theme-setting-header {
    border-bottom: 1px solid #323045;
}

.table-theme-setting-header h6 {
    margin-bottom: 0px;
    color: #c7c7c7;
    cursor: pointer;
    padding: 10px;
    padding-bottom: 10px;
    font-size: 0.8em;
}

.mobileView .table-theme-setting-header {
    gap: 0.3rem !important;
}

.mobileView .table-theme-setting-header h6 {
    font-size: 0.7em;
    padding: 4px;
}

.table-theme-setting-header h6.active {
    color: var(--talk-yellow);
    border-bottom: 3px solid var(--talk-yellow);
}

.scroll {
    display: block;
    height: 5em;
    /*background-color: #37344d;*/
    background-color: transparent;
    border-radius: 0.5em;
    overflow: auto;
    margin-top: 1em;
    padding: 0em 2em;
}
.scroll::-webkit-scrollbar {
    background: #181818;
}
.scroll::-webkit-scrollbar-thumb {
    background: #393939;
    border-radius: 3px;
}

.mobileView .tableTheme .table-theme-setting .scroll {
    padding: 0 1em;
    padding-bottom: 15px;
    margin-top: 5px;
    height: 20em;
}

.table-setting-container {
    position: relative;
    height: 100%;
    width: 100%;
}

.table-setting-scroll {
    white-space: nowrap;
    display: flex;
    gap: 2rem;
    position: absolute;
    height: 100%;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: nowrap;
    min-width: 100%;
}

.mobileView .tableTheme .table-theme-setting .scroll .table-setting-scroll {
    align-items: center;
    display: flex;
    gap: 0;
    justify-content: space-evenly;
    min-width: 100%;
    position: absolute;
    white-space: nowrap;
    flex-wrap: wrap;
    margin-top: 10px;
    padding-right: 0;
}
.mobileView .tableTheme .table-theme-setting .scroll .table-setting-scroll.deck-front {
    flex-direction: column;
}

.box {
    height: 3.875em;
    padding: 0.20em;
    background-color: transparent;
    border: none;
    outline: none;
}

.mobileView .box {
    height: 3.5em;    
}

.box.active {
    outline: 0.125em solid #ffd050;
    outline-color: #ffd050;
    box-shadow: 0 0.1em #fdd83580
}
.box img {
    height: 100%;
    /* margin-left: -10px; */
}

.affiliate-code-text p{
    font-size: 0.8em;
}

.mobileView.plyerProfile > .cashier-modal {
    border: 3px solid var(--talk-yellow);
    border-radius: 10px;
    background-color: var(--bodybg);
}
.mobileView.plyerProfile > .cashier-modal > div.d-flex {
    flex-direction: column;
}

.mobileView.plyerProfile > .cashier-modal .modal-mobile-header > .cashier-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mobileView.plyerProfile > .cashier-modal .modal-mobile-header > .cashier-heading > img.close-modal-btn {
    filter: grayscale(1) invert(1);
    width: 14px;
    height: 14px;
    cursor: pointer;
    margin-right: 5px;
}

.mobileView.plyerProfile > .cashier-modal .cashier-modal-menu {
    display: flex;
    flex-wrap: wrap;
    background-color: #282a30;
}

.mobileView .cashier-heading h2 {
    font-size: 0.8rem !important;
}

.mobileView .cashier-modal .modal-header .modal-sidebar-button {
    background-color: #282a30;
}