.table-theme-block {
    height: 100%;
    width: 100%;
    overflow: scroll;
    overflow-x: hidden;
    padding: 1em;
    padding-top: 0px;
    position: relative;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.mobileView .table-theme-block {
    height: 100%;
    width: 100%;
    overflow: scroll;
    overflow-x: hidden;
    padding: 0.5em;
    padding-top: 0px;
    position: relative;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.table-theme-block::-webkit-scrollbar {
    display: none;
}

.table-theme-design {
    position: relative;
    height: 14em;
    width: 100%;
}

.table-theme-game-area {
    position: absolute;
    /*top: 0;*/
    inset: 0;
    margin: auto;
    height: 12em;
    width: 100%;
}

.mobileView .table-theme-design {
    height: 100%;
    width: 100%;
    position: relative;
    height: 28em;
    width: 100%;
}

.mobileView .table-theme-game-area {
    position: absolute;
    top: 2em;
    height: 22em;
    width: 100%;
}

.table-theme-setting {
    /*margin-top: 1em;*/
}

.table-theme-design.desktop .table-shadow {
    /*background-image: url('../../Images/Table/Desktop/table-shadow.png');*/
}

.table-theme-design.desktop .table-border {
    /*background-image: url('../../Images/Table/Desktop/table-border.png');*/
}

.table-theme-design.desktop .table-default {
    background-image: url('../../Images/Table/Desktop/table-screen-default.png');
}

.table-theme-design.desktop .table-gray {
    background-image: url('../../Images/Table/Desktop/table-screen-gray.png');
}

.table-theme-design.desktop .table-purpal {
    background-image: url('../../Images/Table/Desktop/table-screen-purpal.png');
}

.table-theme-design.desktop .table-blue {
    background-image: url('../../Images/Table/Desktop/table-screen-blue.png');
}

.table-theme-design.desktop .table-gold {
    background-image: url('../../Images/Table/Desktop/table-screen-gold.png');
}

.table-theme-design.desktop .table-red {
    background-image: url('../../Images/Table/Desktop/table-screen-red.png');
}


.table-theme-design.mobile .table-shadow {
    /*background-image: url('../../Images/Table/Mobile/table-shadow.png');*/
}

.table-theme-design.mobile .table-border {
    /*background-image: url('../../Images/Table/Mobile/table-border.png');*/
}

.table-theme-design.mobile .table-default {
    background-image: url('../../Images/Table/Mobile/table-m-default.png');
}

.table-theme-design.mobile .table-gray {
    background-image: url('../../Images/Table/Mobile/table-screen-gray.png');
}

.table-theme-design.mobile .table-purpal {
    background-image: url('../../Images/Table/Mobile/table-screen-purpal.png');
}

.table-theme-design.mobile .table-blue {
    background-image: url('../../Images/Table/Mobile/table-screen-blue.png');
}

.table-theme-design.mobile .table-gold {
    background-image: url('../../Images/Table/Mobile/table-screen-gold.png');
}

.table-theme-design.mobile .table-red {
    background-image: url('../../Images/Table/Mobile/table-screen-red.png');
}


.table-theme-design.table-bg-default {
    background-color: #19191D;
}

.table-theme-design.table-bg-green {
    background: #1E3629 radial-gradient(75.89% 100% at 50% 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%)
}

.table-theme-design.table-bg-purpal {
    background: #36253E radial-gradient(75.89% 100% at 50% 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%)
}

.table-theme-design.table-bg-blue {
    background: #27304A radial-gradient(75.89% 100% at 50% 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%)
}

.table-theme-design.table-bg-gold {
    background: #3C2E1A radial-gradient(75.89% 100% at 50% 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%)
}

.table-theme-design.table-bg-red {
    background: #3C2027 radial-gradient(75.89% 100% at 50% 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%)
}

.table-theme-design .skin-section {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
}

.table-setting-btn .btn-box {
    width: 2em;
    height: 2em;
    border-radius: 50%;
    border: 0.125em solid rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
}

.table-setting-btn {
    width: 3em;
    height: 3em;
    border-radius: 50%;
    border: none;
    box-sizing: border-box;
    background: transparent;
}

.table-setting-btn.active {
    border: 0.125em solid rgba(255, 255, 255, 0.1);
    border-color: #3AF463;
    box-shadow: 0 0.1em #4bde3e80
}



.table-setting-action-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5em;
    margin-top: 1em;
    background-color: #37344d;
    border-radius: 0.5em;
}

.default-btn {
    background-color: #1E2D26;
}

.red-btn {
    background-color: #3C2027;
}

.gray-btn.gray-bg {
    background-color: #424349;
}

.purpal-btn {
    background-color: #45324d;
}

.gold-btn {
    background-color: #3c2e1a;
}

.blue-btn.blue-bg {
    background-color: #303a57;
}
.blue-btn.gray-bg {
    background-color: #454746;
}

.green-btn {
    background-color: #1e2d25;
}

.table-theme-setting-header {
    border-bottom: 1px solid #323045;
}

.table-theme-setting-header h6 {
    margin-bottom: 0px;
    color: #9d9d9d;
    cursor: pointer;
    padding: 10px;
    padding-bottom: 10px;
    font-size: 0.8em;
}

.table-theme-setting-header h6.active {
    color: #fff;
    border-bottom: 3px solid #ffd050;
}

.scroll {
    display: block;
    height: 6em;
    /*background-color: #181818;*/
    background-color: transparent;
    border-radius: 0.5em;
    overflow: auto;
    margin-top: 1em;
    padding: 0em 2em;
}

.table-setting-container {
    position: relative;
    height: 100%;
    width: 100%;
}

.table-setting-scroll {
    white-space: nowrap;
    display: flex;
    gap: 2rem;
    position: absolute;
    height: 100%;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: nowrap;
    min-width: 100%;
    padding-right: 20px;
}

.box {
    height: 3.875em;
    padding: 0.20em;
    background-color: transparent;
    border: none;
    outline: none;
}
.box.active {
    outline: 0.125em solid #ffd050 !important;
    outline-color: #ffd050;
    box-shadow: 0 0.1em #ffd05080;
}
.box img {
    height: 100%;
    /* margin-left: -10px; */
}

.deck-front .box img:nth-child(n+2) {
    box-shadow: -5px 2px 8px #636363;
}

.affiliate-code-text p{
    font-size: 0.8em;
}

.ReactModal__Content.ReactModal__Content--after-open:has(>.mobileView .logout-modal) {
    background: #000 !important;
    border-radius: 1rem !important;
    border: 1px solid var(--talk-yellow) !important;
}

.logout-modal .logout-title {
    color: red;
}

.logout-modal .logout-btn {
    color: #000 !important;
    background: var(--talk-yellow) !important;
    font-size: 1.2rem !important;
}

.logout-modal .avtarBtnSection {
    margin-top: 2rem;
}

.mobileView.plyerProfile .cashier-modal.viplevel,
.mobileView.plyerProfile .cashier-modal.myacc {
    border: 3px solid var(--talk-yellow);
    border-radius: 10px;
}

.mobileView.plyerProfile .cashier-modal.myacc .cashier-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mobileView.plyerProfile .cashier-modal.myacc .cashier-heading > img {
    filter: grayscale(1) invert(1);
    width: 14px;
    height: 14px;
}